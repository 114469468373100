var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","height":"100%"}},[(_vm.isLoading)?_c('div',{staticClass:"myloading"},[_c('van-loading',{attrs:{"type":"spinner"}})],1):_c('div',{staticClass:"box"},[_c('div',{staticClass:"title"},[_c('van-nav-bar',{attrs:{"title":_vm.objData.munuName +
          (_vm.objData.addeditdetail == 1
            ? '新增'
            : _vm.objData.addeditdetail == 2
            ? '修改'
            : '详情'),"left-text":"返回","left-arrow":""},on:{"click-left":_vm.onClickLeft},scopedSlots:_vm._u([(_vm.objData && _vm.objData.addeditdetail != 3)?{key:"right",fn:function(){return [_c('van-button',{attrs:{"round":"","type":"info","size":"small"},on:{"click":_vm.saveclick}},[_vm._v("保存")])]},proxy:true}:null],null,true)})],1),_c('div',{staticClass:"title"},[_c('van-divider',{style:({
          color: '#1989fa',
          borderColor: '#1989fa',
        })},[_vm._v(_vm._s(_vm.包装线))])],1),_c('div',{staticClass:"content"},[_c('div',{ref:"scrollContainer",staticClass:"scrolldiv"},[_c('van-form',{ref:"vanform",attrs:{"label-width":"6em"}},[_c('van-field',{attrs:{"name":"工序名称","disabled":_vm.objData &&
              (_vm.objData.addeditdetail == 3 || _vm.objData.addeditdetail == 2),"label":"工序名称","readonly":"","clickable":"","placeholder":_vm.objData && _vm.objData.addeditdetail == 3 ? '' : '点击选择工序名称',"rules":[
              {
                required: true,
                message: '', //message: '请选择' + item.名称
              },
            ]},on:{"click":function($event){return _vm.onClick('工序名称', '字典ID')}},model:{value:(_vm.form.工序名称),callback:function ($$v) {_vm.$set(_vm.form, "工序名称", $$v)},expression:"form.工序名称"}}),_c('van-field',{attrs:{"name":"工资系数","disabled":_vm.objData && _vm.objData.addeditdetail == 3,"placeholder":_vm.objData && _vm.objData.addeditdetail == 3 ? '' : '请输入工资系数',"type":"number","rules":[
              {
                required: true,
                message: '', //message: '请输入' + item.名称
              },
            ],"label":"工资系数"},model:{value:(_vm.form.工资系数),callback:function ($$v) {_vm.$set(_vm.form, "工资系数", $$v)},expression:"form.工资系数"}}),_c('van-field',{attrs:{"name":"额定人数","disabled":_vm.objData && _vm.objData.addeditdetail == 3,"placeholder":_vm.objData && _vm.objData.addeditdetail == 3 ? '' : '请输入额定人数',"type":"digit","rules":[
              {
                required: true,
                message: '', //message: '请输入' + item.名称
              },
            ],"label":"额定人数"},model:{value:(_vm.form.额定人数),callback:function ($$v) {_vm.$set(_vm.form, "额定人数", $$v)},expression:"form.额定人数"}}),_c('van-field',{attrs:{"name":"延迟上班","disabled":_vm.objData && _vm.objData.addeditdetail == 3,"placeholder":_vm.objData && _vm.objData.addeditdetail == 3 ? '' : '请输入延迟上班',"type":"number","rules":[
              {
                required: true,
                message: '', //message: '请输入' + item.名称
              },
            ],"label":"延迟上班"},model:{value:(_vm.form.延迟上班),callback:function ($$v) {_vm.$set(_vm.form, "延迟上班", $$v)},expression:"form.延迟上班"}}),_c('van-field',{attrs:{"readonly":"","name":"员工","disabled":_vm.objData && _vm.objData.addeditdetail == 3,"clickable":"","label":"员工","placeholder":"员工","rows":"1","autosize":"","type":"textarea","rules":[
              {
                required: true,
                message: '', //message: '请选择' + item.名称
              },
            ]},on:{"click":function($event){return _vm.inputclick('员工')},"clear":function($event){return _vm.clear('员工')}},model:{value:(_vm.form.员工),callback:function ($$v) {_vm.$set(_vm.form, "员工", $$v)},expression:"form.员工"}}),_c('van-field',{attrs:{"name":"备注","disabled":_vm.objData && _vm.objData.addeditdetail == 3,"clickable":"","rows":"1","autosize":"","type":"textarea","label":"备注","placeholder":"备注"},model:{value:(_vm.form.备注),callback:function ($$v) {_vm.$set(_vm.form, "备注", $$v)},expression:"form.备注"}}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.dateShow),callback:function ($$v) {_vm.dateShow=$$v},expression:"dateShow"}},[_c('van-datetime-picker',{attrs:{"type":"date","title":"请选择"},on:{"confirm":(value) => {
                  _vm.onConfirmTime(value, 'dateShow', 'yyyy-mm-dd');
                },"cancel":function($event){return _vm.onCancelDate('dateShow')}},model:{value:(_vm.sunForm[_vm.formvalueTextValue]),callback:function ($$v) {_vm.$set(_vm.sunForm, _vm.formvalueTextValue, $$v)},expression:"sunForm[formvalueTextValue]"}})],1),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.datetimeShow),callback:function ($$v) {_vm.datetimeShow=$$v},expression:"datetimeShow"}},[_c('van-datetime-picker',{attrs:{"type":"datetime","title":"请选择"},on:{"confirm":(value) => {
                  _vm.onConfirmTime(value, 'datetimeShow');
                },"cancel":function($event){return _vm.onCancelDate('datetimeShow')}},model:{value:(_vm.sunForm[_vm.formvalueTextValue]),callback:function ($$v) {_vm.$set(_vm.sunForm, _vm.formvalueTextValue, $$v)},expression:"sunForm[formvalueTextValue]"}})],1),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.timeShow),callback:function ($$v) {_vm.timeShow=$$v},expression:"timeShow"}},[_c('van-datetime-picker',{attrs:{"type":"time","title":"请选择"},on:{"confirm":(value) => {
                  _vm.onConfirmTime(value, 'timeShow', 'hh:ss');
                },"cancel":function($event){return _vm.onCancelDate('timeShow')}},model:{value:(_vm.sunForm[_vm.formvalueTextValue]),callback:function ($$v) {_vm.$set(_vm.sunForm, _vm.formvalueTextValue, $$v)},expression:"sunForm[formvalueTextValue]"}})],1),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[_c('van-search',{attrs:{"placeholder":"请输入"},on:{"input":_vm.depInput},model:{value:(_vm.depNameValue),callback:function ($$v) {_vm.depNameValue=$$v},expression:"depNameValue"}}),_c('van-picker',{attrs:{"value-key":"text","default-index":_vm.defaultIndex,"show-toolbar":"","columns":_vm.columns},on:{"confirm":(value) => {
                  _vm.onConfirm(value);
                },"cancel":function($event){return _vm.onCancel('showPicker')}}})],1),_c('MorePicker',{ref:"selectMulPicker",attrs:{"placeholder":"请选择","columns":_vm.columns,"label-width":"100","option":{ label: 'text', value: 'value' }},on:{"confirm":_vm.confirmMulSelect},model:{value:(_vm.selectType),callback:function ($$v) {_vm.selectType=$$v},expression:"selectType"}})],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }