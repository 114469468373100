<template>
  <div style="width: 100%; height: 100%">
    <div v-if="isLoading" class="myloading">
      <van-loading type="spinner" />
    </div>
    <div v-else class="box">
      <!-- <div class="title">工作</div> -->
      <div class="content1">
        <van-pull-refresh
          style="height: 100%"
          v-model="refreshing"
          success-text="刷新成功"
          @refresh="onRefresh"
        >
          <div class="spec-box">
            <div class="img-swiper" v-if="imgs.length != 0">
              <van-swipe :autoplay="3000">
                <van-swipe-item v-for="(image, index) in imgs" :key="index">
                  <img v-lazy="image" />
                </van-swipe-item>
              </van-swipe>
            </div>
            <van-row class="row-spec">
              <template v-if="menu.length != 0">
                <van-col span="6" style="height: 100%; overflow: auto">
                  <van-sidebar
                    style="width: 100%"
                    v-model="activeKey"
                    @change="onChange"
                  >
                    <template v-for="item in menu">
                      <van-sidebar-item :title="item.菜单名称" :key="item.ID" />
                    </template>
                  </van-sidebar>
                </van-col>
                <van-col span="18" style="height: 100%">
                  <div
                    ref="scrollContainer"
                    style="height: 100%; overflow: auto"
                  >
                    <div
                      class="item-box"
                      v-if="children && children.length != 0"
                    >
                      <template v-for="ii in children">
                        <van-col span="8" :key="ii.ID">
                          <div class="item-box-warrper" @click="boxclick(ii)">
                            <div class="box-img">
                              <!-- require('@/assets/yes.png') -->
                              <img
                                :src="
                                  ii.移动图标 == ''
                                    ? 服务地址 + '/ico/25.png'
                                    : 服务地址 + '/ico/' + ii.移动图标
                                "
                                alt=""
                              />
                              <!-- <img src="https://i.gtimg.cn/club/item/face/img/2/16022_100.gif" alt=""> -->
                            </div>
                            <div class="box-title">
                              {{ ii.菜单名称 }}
                            </div>
                          </div>
                        </van-col>
                      </template>
                    </div>
                    <template v-else>
                      <div class="noData">
                        <van-image :src="require('@/assets/data_empty.png')" />
                      </div>
                    </template>
                  </div>
                </van-col>
              </template>
              <template v-else>
                <div class="noData">
                  <van-image :src="require('@/assets/data_empty.png')" />
                </div>
              </template>
            </van-row>
          </div>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>

<script>
import { baseURL } from "@/config/net.js";
import { Notify } from "vant";

import { APPsettings_list, Tname_list } from "@/api/list";
import { login, userInfo } from "@/api/login";
export default {
  data() {
    return {
      menu: [],
      imgs: [],
      服务地址: "",
      refreshing: false,
      isLoading: true,
      activeKey: 0,
      children: [],
    };
  },
  mounted() {
    this.服务地址 = baseURL;
    this.isLoading = true;
    this.refreshing = false;
    this.getList();
  },
  methods: {
    onChange(index) {
      this.$refs.scrollContainer.scrollTop = 0;
      this.children = this.menu[index].children
        ? this.menu[index].children
        : [];
    },
    onRefresh() {
      this.refreshing = true;
      this.getList();
    },
    getList() {
      userInfo()
        .then((result) => {
          localStorage.setItem("userinfo", JSON.stringify(result.data));
          localStorage.setItem("head", result.data.head);
          localStorage.setItem("username", result.data.username);
          localStorage.setItem("id", result.data.id);
          localStorage.setItem(
            "permissions",
            JSON.stringify(result.data.permissions)
          );
          localStorage.setItem("roles", JSON.stringify(result.data.roles));
          this.menu =
            result.data.permissions && result.data.permissions.length != 0
              ? result.data.permissions
              : [];
          this.activeKey = 0;
          this.children =
            result.data.permissions && result.data.permissions.length != 0
              ? result.data.permissions[0].children
                ? result.data.permissions[0].children
                : []
              : [];
          this.refreshing = false;
          this.isLoading = false;
        })
        .catch(() => {
          this.refreshing = false;
          this.isLoading = false;
        });
    },
    boxclick(ii) {
      // 权限"492-3-1-1-1-1-1-1"
      if (ii.接口名 != "") {
        // window.location.href = this.服务地址 + ii.接口名 + '_list';
        var 权限 = ii.权限 == "" ? [] : ii.权限.split("-");
        var addlist = [
          "/system/Digitalpackaging/PackagingDailyPersonnel",
          "/system/Digitalpackaging/PackagingDailyConsumables",
        ];
        var obj = {
          ID: ii.ID,
          接口名: ii.接口名,
          // 权限: ii.权限,
          移动图标: ii.移动图标,
          菜单名称: ii.菜单名称,
          关联数据: ii.关联数据,
          新增: addlist.includes(ii.路径)
            ? false
            : 权限.length == 0
            ? false
            : 权限[2] == 1
            ? true
            : false,
          修改: 权限.length == 0 ? false : 权限[3] == 1 ? true : false,
          删除: 权限.length == 0 ? false : 权限[4] == 1 ? true : false,
        };
        // var str = `ID=${ii.ID}&接口名=${ii.接口名}
        // &移动图标=${ii.移动图标}&菜单名称=${ii.菜单名称}&关联数据=${
        //   ii.关联数据
        // }&新增=
        // ${权限.length == 0 ? false : 权限[2] == 1 ? true : false}&修改=${
        //   权限.length == 0 ? false : 权限[3] == 1 ? true : false
        // }
        // &删除=${权限.length == 0 ? false : 权限[4] == 1 ? true : false}`;
        // var string = this.$encodeQueryParams(obj);

        // console.log(string);
        // console.log(this.$decodeQueryParams(string));
        // this.$router.push('/list?'+string);
        var list = [
          "/Digitalpackaging/ProductionSchedulingAdjustment",
          "/system/Digitalpackaging/PackagingDaily",
          "/system/Digitalpackaging/PackagingDailyPersonnel",
          "/system/Digitalpackaging/PackagingDailyConsumables",
          "/system/Digitalpackaging/Packagingprocessdefinition",
        ];

        if (list.includes(ii.路径)) {
          this.$router.push({
            path: "/app" + ii.路径,
            query: { ...obj },
          });
        } else {
          this.$router.push({ path: "/app/list", query: { ...obj } });
        }

        // this.$router.push('/list?'+this.$Base64.encode(str));

        // APPsettings_list({
        //   查询条件: `模板ID='${ii.ID}'`,
        // }).then((res) => {
        //   if (res.data.list.length != 0) {
        //     this.$router.push({ path: "/app/list", query: { ...obj } });
        //   } else {
        //     Notify({ type: "danger", message: "未配置相关数据" });
        //   }
        // });
      } else {
        Notify({ type: "danger", message: "数据配置不完整" });
      }
    },
  },
};
</script>

<style  lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  .title {
    width: 100%;
    height: 2rem;
    flex-shrink: 0;
    background-color: #fa6401;
    color: #fff;
    font-size: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content1 {
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
  }
  .content1 > div {
    width: 100%;
    /* padding: 0.9375rem 0; */
  }
}

.img-swiper {
  background-color: #fff;
  width: 100%;
  height: 12.5rem;
  img {
    /* width: 100%; */
    height: 100%;
  }
}

.el-carousel,
.el-carousel__container {
  height: 100%;
}

.el-carousel__item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-title-warrper {
  width: 100%;
  background-color: #fff;
  margin-bottom: 0.625rem;
}

.item-title {
  width: 100%;
  padding: 0.625rem;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 0.875rem;
}

.item-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.item-box-warrper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.625rem;
  border: 1px solid #eee;
  cursor: pointer;
}

.box-img {
  width: 100%;
  display: flex;
  justify-content: center;
}

.box-img img {
  width: 4rem;
  height: 4rem;
  border-radius: 0.625rem;
}

.box-title {
  font-size: 0.75rem;
  padding-top: 0.625rem;
}

.spec-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .img-swiper {
    flex-shrink: 0;
  }
  .row-spec {
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
  }
}
</style>