<template>
  <div style="width: 100%; height: 100%">
    <template v-if="isAdd == ''">
      <div v-if="isLoading" class="myloading">
        <van-loading type="spinner" />
      </div>
      <div v-else class="box">
        <div class="title">
          <van-nav-bar
            :title="menuObj.菜单名称"
            left-text="返回"
            :right-text="JSON.parse(menuObj.新增) ? '新增' : ''"
            left-arrow
            @click-left="onClickLeft"
          >
            <template #right v-if="JSON.parse(menuObj.新增)">
              <van-button @click="addClick" round type="info" size="small"
                >新增</van-button
              >
            </template>
          </van-nav-bar>
        </div>
        <div class="title">
          <van-cell is-link @click="showSearchPopup">
            搜索 {{ searchStr }}
          </van-cell>
        </div>

        <div class="content">
          <van-pull-refresh
            v-model="refreshing"
            success-text="刷新成功"
            @refresh="onRefresh"
          >
            <!-- <van-list
            :offset="100"
            v-model="loadingAdd"
            :finished="finished"
            loading-text="加载中..."
            finished-text="没有更多了"
            @load="onLoad"
            :immediate-check="false"
          > -->
            <div class="scrolldiv" ref="scrollContainer">
              <template v-if="tableList.length != 0">
                <div
                  class="item"
                  v-for="(item, index) in tableList"
                  :key="item.ID"
                >
                  <div class="item-warrper">
                    <div class="text">单据编号：{{ item.单据编号 }}</div>

                    <div class="text">生产日期：{{ item.生产日期 }}</div>
                    <div class="text">包装线名称：{{ item.生产线名称 }}</div>
                    <div class="text">开始时间：{{ item.开始时间 }}</div>
                    <div class="text">结束时间：{{ item.结束时间 }}</div>
                    <div class="text">生产时长：{{ item.生产时长 }}</div>
                    <!-- <div class="text">产量：{{ item.产量 }}</div>
                    <div class="text">生产人数：{{ item.生产人数 }}</div> -->
                    <div class="text">备注：{{ item.备注 }}</div>

                    <div
                      v-if="item.审批状态"
                      class="absolute"
                      :style="{
                        'background-color':
                          item.审批状态 == '已通过'
                            ? '#44d7b6'
                            : item.审批状态 == '未开始'
                            ? '#e4e7ed'
                            : item.审批状态 == '进行中'
                            ? '#e6a23c'
                            : item.审批状态 == '已驳回'
                            ? '#f56c6c'
                            : '',
                      }"
                    >
                      {{ item.审批状态 }}
                    </div>
                    <div class="text display border">
                      <div>录入用户：{{ item.录入用户 }}</div>
                      <div>录入时间：{{ item.录入时间 }}</div>
                    </div>
                    <!-- <div class="btn-edit">
                    <van-button
                      v-if="index != 0"
                      @click="upClick(item, index)"
                      round
                      type="info"
                      size="small"
                      >上移</van-button
                    >
                    <van-button
                      v-if="index != tableList.length - 1"
                      @click="downClick(item, index)"
                      round
                      type="default"
                      size="small"
                      style="margin-left: 0.625rem"
                      >下移</van-button
                    >
                  </div> -->
                    <div class="btn-edit">
                      <van-button
                        @click="editClick(item)"
                        v-if="JSON.parse(menuObj.修改)"
                        round
                        type="info"
                        size="small"
                        style="margin-left: 0.625rem"
                        >修改</van-button
                      >
                      <van-button
                        @click="detailClick(item)"
                        round
                        type="default"
                        size="small"
                        style="margin-left: 0.625rem"
                        >详情</van-button
                      >
                      <van-button
                        @click="remooveClick(item)"
                        v-if="JSON.parse(menuObj.删除)"
                        round
                        type="warning"
                        size="small"
                        style="margin-left: 0.625rem"
                        >删除</van-button
                      >
                    </div>
                  </div>
                </div>
              </template>

              <template v-else>
                <div class="noData">
                  <van-image :src="require('@/assets/data_empty.png')" />
                </div>
              </template>
            </div>
            <!-- </van-list> -->
          </van-pull-refresh>
        </div>
        <div class="title">
          <van-pagination
            v-model="pagin.当前页数"
            :total-items="pagin.total"
            :items-per-page="pagin.每页数量"
            :show-page-size="3"
            force-ellipses
            @change="paginchange"
          />
        </div>
      </div>
    </template>

    <template v-if="isAdd != ''">
      <AddUpDetail
        :addObj="addObj"
        @backclick="isAdd = ''"
        @saveclick="saveclick"
      />
    </template>
    <van-dialog
      v-model="deleteShow"
      title="删除"
      show-cancel-button
      @confirm="onDelConfirm"
      @cancel="onDelCancel"
    >
      是否要进行删除操作？
    </van-dialog>
    <van-popup position="top" v-model="searchshow">
      <div style="padding: 0.625rem">
        <van-form ref="vanform" label-width="6em">
          <van-field
            clickable
            v-model="模糊查询.单据编号"
            label="单据编号"
            placeholder="单据编号"
          >
          </van-field>
          <van-field
            clickable
            readonly
            v-model="查询条件.生产日期"
            label="生产日期"
            placeholder="生产日期"
            @click="onclickdatetime('生产日期', 'dateShow', 'yyyy-mm-dd')"
          >
          </van-field>
          <van-field
            clickable
            v-model="模糊查询.生产线名称"
            label="包装线"
            placeholder="包装线"
          >
          </van-field>
        </van-form>
        <div style="display: flex">
          <van-button
            type="default"
            @click="cancel"
            style="width: 50%; margin: 0 0.625rem"
            >重置</van-button
          >
          <van-button
            type="info"
            @click="searchclick"
            style="width: 50%; margin: 0 0.625rem"
            >搜索</van-button
          >
        </div>
      </div>
    </van-popup>
    <van-popup v-model="dateShow" position="bottom">
      <van-datetime-picker
        v-model="dateValue"
        type="date"
        title="请选择"
        @confirm="
          (value) => {
            onConfirmTime(value, 'dateShow', 'yyyy-mm-dd');
          }
        "
        @cancel="onCancelDate('dateShow')"
      >
      </van-datetime-picker>
    </van-popup>
  </div>
</template>

<script>
import AddUpDetail from "./AddUpDetail";
import { baseURL } from "@/config/net.js";
import request from "@/utils/request";
import {
  APPsettings_list,
  Tname_list,
  Packagingline_list,
  PackagingDaily_list,
  sort,
  PackagingDaily_del,
} from "@/api/list";
import { Notify } from "vant";
export default {
  components: {
    AddUpDetail,
  },
  data() {
    return {
      服务地址: "",
      menuObj: {
        新增: false,
        修改: false,
        删除: false,
      },
      tableList: [],
      loadingAdd: false,
      finished: false,
      refreshing: false,
      pagin: {
        total: 0,
        当前页数: 1,
        每页数量: 15,
      },

      loadingview: false,
      deleteShow: false,
      isLoading: true,

      模糊查询: {
        单据编号: "",
        生产线名称: "",
      },
      查询条件: {
        生产日期: "",
      },
      searchshow: false,
      searchStr: "",
      objData: {},
      isAdd: "",
      addObj: {},
      dateShow: false,
      lablelame: "",
      dateValue: "",
    };
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler() {
        console.log(this.$route.query);
        this.isAdd = "";
        this.addObj = {};
        this.服务地址 = baseURL;
        this.模糊查询 = { 单据编号: "", 生产线名称: "" };
        this.查询条件 = { 生产日期: "" };

        this.searchStr = "";

        if (JSON.stringify(this.$route.query) !== "{}") {
          this.menuObj = this.$route.query;
          this.getreturnstr();
          this.pagin.当前页数 = 1;
          this.pagin.每页数量 = 15;
          this.pagin.total = 0;
          this.tableList = [];
          this.finished = false;
          this.loadingAdd = false;
          this.refreshing = false;
          this.loadingview = true;
          this.isLoading = true;
          this.getList();
        }
      },
    },
  },

  mounted() {
    this.isAdd = "";
    this.addObj = {};
    this.服务地址 = baseURL;
  },
  methods: {
    // 日期
    onclickdatetime(text, showText, type, number) {
      console.log(number);
      this.lablelame = text;

      this.dateValue =
        this.查询条件.生产日期 == ""
          ? new Date()
          : new Date(this.查询条件.生产日期);

      this.$nextTick(() => {
        this[showText] = true;
      });
    },
    onConfirmTime(value, showText, type) {
      console.log(this.tableList);
      console.log(this.Index);
      console.log(this.lablelame);
      console.log(this.tableList[this.Index]);

      this.查询条件.生产日期 =
        type && type == "yyyy-mm-dd"
          ? this.formatDate(value, "yyyy-mm-dd")
          : this.formatDate(value);

      this.dateValue = value;

      this[showText] = false;
    },
    onCancelDate(showText) {
      this[showText] = false;
    },
    // 格式化时间
    formatDate(date, type) {
      date = new Date(date);
      var str = `${date.getFullYear()}-${this.format(
        date.getMonth() + 1
      )}-${this.format(date.getDate())}`;

      return type && type == "yyyy-mm-dd"
        ? str
        : `${str} ${this.format(date.getHours())}:${this.format(
            date.getMinutes()
          )}:00`;

      // ${this.format(date.getSeconds())}
    },

    format(n) {
      return n < 10 ? "0" + n : "" + n;
    },
    saveclick() {
      this.isAdd = "";
      this.pagin.当前页数 = 1;
      this.pagin.每页数量 = 15;
      this.pagin.total = 0;
      this.refreshing = false;
      this.finished = false;
      this.isLoading = true;
      setTimeout(() => {
        this.getList(2);
      }, 500);
    },

    addClick() {
      this.isAdd = "1";
      this.addObj = {
        addeditdetail: 1,
        menuID: this.menuObj.ID,
        munuName: this.menuObj.菜单名称,
      };
      // this.$router.push({
      //   path: "/app/baozhuang/gongxudingyi/AddUpDetail",
      //   query: {
      //     addeditdetail: 1,
      //     menuID: this.menuObj.ID,
      //     munuName: this.menuObj.菜单名称,
      //   },
      // });
    },
    editClick(item) {
      this.isAdd = "1";
      this.addObj = {
        addeditdetail: 2,
        menuID: this.menuObj.ID,
        munuName: this.menuObj.菜单名称,
        ID: item.ID,
      };
      this.objData = item;
      // this.$router.push({
      //   path: "/app/baozhuang/gongxudingyi/AddUpDetail",
      //   query: {
      //     addeditdetail: 2,
      //     menuID: this.menuObj.ID,
      //     munuName: this.menuObj.菜单名称,

      //     ID: item.ID,
      //   },
      // });
    },
    detailClick(item) {
      this.isAdd = "1";
      this.addObj = {
        addeditdetail: 3,
        menuID: this.menuObj.ID,
        munuName: this.menuObj.菜单名称,
        ID: item.ID,
      };
      this.objData = item;
      // this.$router.push({
      //   path: "/app/baozhuang/gongxudingyi/AddUpDetail",
      //   query: {
      //     addeditdetail: 3,
      //     menuID: this.menuObj.ID,
      //     munuName: this.menuObj.菜单名称,

      //     ID: item.ID,
      //   },
      // });
    },

    remooveClick(item) {
      this.objData = item;
      this.deleteShow = true;
    },
    onDelConfirm() {
      PackagingDaily_del({
        菜单: this.menuObj.ID,
        ID: this.objData.ID,
      }).then(() => {
        this.pagin.当前页数 = 1;
        this.pagin.每页数量 = 15;
        this.pagin.total = 0;
        this.refreshing = false;
        this.finished = false;
        this.isLoading = true;
        setTimeout(() => {
          this.getList(2);
        }, 500);
        Notify({ type: "success", message: "删除成功" });
        this.deleteShow = false;
      });
    },
    onDelCancel() {
      this.deleteShow = false;
    },
    getNextday() {
      // 获取当前时间的 Date 对象
      let currentDate = new Date();
      // 获取下一天的时间戳
      let nextDayTimeStamp = currentDate.getTime() + 24 * 60 * 60 * 1000;
      // 创建下一天的 Date 对象
      return new Date(nextDayTimeStamp);
    },
    // upClick(item, index) {
    //   const movedItem = this.tableList.splice(index, 1)[0];
    //   this.tableList.splice(index - 1, 0, movedItem);
    //   var IDS = _this.tableList.map((item) => {
    //     return item.ID;
    //   });
    //   sort({ data: IDS, 表名: "生产任务子表" }).then((res) => {
    //     console.log(res);
    //     this.isLoading = true;
    //     this.getList();
    //   });
    // },
    // downClick(item, index) {
    //   const movedItem = this.tableList.splice(index, 1)[0];
    //   this.tableList.splice(index + 1, 0, movedItem);
    //   var IDS = _this.tableList.map((item) => {
    //     return item.ID;
    //   });
    //   sort({ data: IDS, 表名: "生产任务子表" }).then((res) => {
    //     console.log(res);
    //     this.isLoading = true;
    //     this.getList();
    //   });
    // },

    getreturnstr() {
      this.searchStr = "";
      var obj = {
        单据编号: this.模糊查询.单据编号,
        生产日期: this.查询条件.生产日期,
        生产线名称: this.模糊查询.生产线名称,
      };
      var str = "";
      for (var key in obj) {
        if (obj[key] && obj != "") {
          str = str + obj[key] + ",";
        }
      }
      //   var str = "";
      //   for (var key in this.模糊查询) {
      //     if (this.模糊查询[key] && this.模糊查询[key] != "") {
      //       str = str + this.模糊查询[key] + ",";
      //     }
      //   }
      this.searchStr =
        str.substring(0, str.length - 1) == ""
          ? ""
          : str.substring(0, str.length - 1);
    },
    searchclick() {
      this.getreturnstr();
      this.isLoading = true;
      this.searchshow = false;
      this.pagin.当前页数 = 1;
      this.pagin.每页数量 = 15;
      this.pagin.total = 0;
      this.tableList = [];
      this.getList();
    },
    cancel() {
      this.模糊查询.生产线名称 = "";
      this.模糊查询.单据编号 = "";
      this.查询条件.生产日期 = "";
      this.getreturnstr();
      this.searchshow = false;
      this.pagin.当前页数 = 1;
      this.pagin.每页数量 = 15;
      this.pagin.total = 0;
      this.tableList = [];
      this.isLoading = true;
      this.getList();
    },
    showSearchPopup() {
      this.searchshow = true;
    },

    paginchange(val) {
      this.isLoading = true;
      console.log(val);
      this.$refs.scrollContainer.scrollTop = 0;
      this.pagin.当前页数 = val;
      this.getList();
    },

    onClickLeft() {
      this.$router.push("/app/home");
    },

    // 下拉刷新
    onRefresh() {
      this.pagin.当前页数 = 1;
      this.pagin.每页数量 = 15;
      this.pagin.total = 0;
      //   this.tableList = [];
      this.refreshing = true;
      this.finished = false;
      setTimeout(() => {
        // this.tableList = [];
        this.getList(2);
      }, 500);
    },
    // // 上拉加载

    getList(number) {
      if (number == 2) {
        this.tableList = [];
        number = 1;
      }
      var str = "";
      for (var key in this.模糊查询) {
        if (this.模糊查询[key] && this.模糊查询[key] != "") {
          str = str + key + "='" + this.模糊查询[key] + "',";
        }
      }
      var str2 = "";
      for (var key in this.查询条件) {
        if (this.查询条件[key] && this.查询条件[key] != "") {
          str2 = str2 + key + "='" + this.查询条件[key] + "',";
        }
      }
      PackagingDaily_list({
        当前页数: this.pagin.当前页数,
        每页数量: this.pagin.每页数量,
        菜单: this.menuObj.ID,
        模糊查询:
          str.substring(0, str.length - 1) == ""
            ? undefined
            : str.substring(0, str.length - 1),
        查询条件:
          str2.substring(0, str2.length - 1) == ""
            ? undefined
            : str2.substring(0, str2.length - 1),
      })
        .then((res) => {
          console.log(res);

          this.pagin.total = res.data.Totalrecords;
          this.refreshing = false;

          if (number == 2) {
            this.loadingAdd = false;
            this.finished = false;
            this.tableList = res.data.list;
          } else {
            this.tableList = res.data.list;
          }
          this.loadingview = false;
          this.isLoading = false;
        })
        .catch(() => {
          this.loadingAdd = false;
          this.loadingview = false;
          this.isLoading = false;
        });
    },
  },
};
</script>

<style  lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #f4f8fb;
  .title {
    width: 100%;
    flex-shrink: 0;
    font-size: 0.75rem;
  }
  .content {
    width: 100%;
    flex-grow: 1;
    overflow-y: hidden;
  }
  .van-pull-refresh {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .scrolldiv {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}

.item {
  padding: 10px;

  /* border-bottom: 1px solid #ccc; */
}

.item-warrper {
  padding: 10px;
  width: 100%;
  background-color: #fff;
  border-radius: 0.625rem;
  position: relative;
  overflow: hidden;
}

.absolute {
  position: absolute;
  top: 0;
  right: 0;
  width: 2.875rem;
  height: 1.625rem;
  font-size: 0.75rem;
  color: #fff;

  border-radius: 0 0 0 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  padding-bottom: 0.625rem;
  font-size: 0.75rem;
  color: #000;
  overflow: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.text2 {
  display: flex;
  //   flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  img {
    width: 6.25rem;
    // height: 3.75rem;
  }
}

.size {
  font-size: 0.875rem;
  color: #6d7278;
}

.border {
  border-top: 1px solid #6d7278;
}

.display {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.display div {
  font-size: 0.625rem;
  color: #6d7278;
  padding-top: 0.625rem;
}

.loading {
  text-align: center;
  padding: 0.625rem 0;
}
.btn-edit {
  display: flex;
  justify-content: flex-end;
}
</style>