<template>
  <div style="width: 100%; height: 100%">
    <div v-if="isLoading" class="myloading">
      <van-loading type="spinner" />
    </div>
    <div v-else class="box">
      <div class="title">
        <van-nav-bar
          :title="
            objData.munuName +
            (objData.addeditdetail == 1
              ? '新增'
              : objData.addeditdetail == 2
              ? '修改'
              : '详情')
          "
          left-text="返回"
          left-arrow
          @click-left="onClickLeft"
        >
          <template #right v-if="objData && objData.addeditdetail != 3">
            <van-button round @click="saveclick" type="info" size="small"
              >保存</van-button
            >
          </template>
        </van-nav-bar>
      </div>
      <div class="content">
        <div class="scrolldiv" ref="scrollContainer">
          <van-form ref="vanform" label-width="6em">
            <van-collapse v-model="activeNames">
              <!-- <van-collapse-item title="包装线" name="1">
                <van-field disabled label="编码" v-model="form.编码">
                </van-field>
                <van-field disabled label="图片"> </van-field>
                <div
                  v-if="form.图片"
                  style="display: flex; justify-content: center"
                >
                  <img :src="服务地址 + form.图片" alt="" />
                </div>
                <van-field disabled label="产品名称" v-model="form.名称">
                </van-field>
                <van-field disabled label="分类名称" v-model="form.分类名称">
                </van-field>
                <van-field disabled label="单位" v-model="form.单位">
                </van-field>
                <van-field disabled label="重量" v-model="form.重量">
                </van-field>
                <van-field disabled label="酒精度" v-model="form.酒精度">
                </van-field>
                <van-field disabled label="麦芽度" v-model="form.麦芽度">
                </van-field>
                <van-field disabled label="可用库存" v-model="form.可用库存">
                </van-field>
                <van-field
                  disabled
                  label="上次业务日期"
                  v-model="form.上次业务日期"
                >
                </van-field>
                <van-field disabled label="上次盘点" v-model="form.上次盘点">
                </van-field>
                <van-field disabled label="上期库存" v-model="form.上期库存">
                </van-field>
                <van-field disabled label="客户" v-model="form.公司名称">
                </van-field>
                <van-field disabled label="销售单价" v-model="form.基础售价">
                </van-field>

                <van-field disabled label="订货点" v-model="form.订货点">
                </van-field>
                <van-field disabled label="备注" v-model="form.备注">
                </van-field>
                <van-field disabled label="标签分类" v-model="form.标签分类">
                </van-field>
                <van-field disabled label="标签名称" v-model="form.标签名称">
                </van-field>
                <van-field disabled label="是否专用纸箱" v-model="form.is纸箱">
                </van-field>
                <van-field disabled label="纸箱分类" v-model="form.纸箱分类">
                </van-field>
                <van-field disabled label="纸箱名称" v-model="form.纸箱名称">
                </van-field>
                <van-field disabled label="酒液分类" v-model="form.酒液分类">
                </van-field>

                <van-field disabled label="酒液名称" v-model="form.酒液名称">
                </van-field>

                <van-field
                  disabled
                  label="容器分类"
                  v-model="form.容器分类名称"
                >
                </van-field>
                <van-field disabled label="容器名称" v-model="form.容器名称">
                </van-field>
              </van-collapse-item> -->
              <van-collapse-item title="产品" name="1">
                <van-field disabled label="编码" v-model="form.编码">
                </van-field>
                <van-field disabled label="图片"> </van-field>
                <div
                  v-if="form.图片"
                  style="display: flex; justify-content: center"
                >
                  <img :src="服务地址 + form.图片" alt="" />
                </div>
                <van-field disabled label="产品名称" v-model="form.名称">
                </van-field>
                <van-field disabled label="分类名称" v-model="form.分类名称">
                </van-field>
                <van-field disabled label="单位" v-model="form.单位">
                </van-field>
                <van-field disabled label="单价" v-model="form.单价">
                </van-field>
                <van-field disabled label="重量" v-model="form.重量">
                </van-field>
                <van-field disabled label="酒精度" v-model="form.酒精度">
                </van-field>
                <van-field disabled label="麦芽度" v-model="form.麦芽度">
                </van-field>
                <van-field disabled label="可用库存" v-model="form.可用库存">
                </van-field>
                <van-field
                  disabled
                  label="上次业务日期"
                  v-model="form.上次业务日期"
                >
                </van-field>
                <van-field disabled label="上次盘点" v-model="form.上次盘点">
                </van-field>
                <van-field disabled label="上期库存" v-model="form.上期库存">
                </van-field>
                <van-field disabled label="客户" v-model="form.公司名称">
                </van-field>
                <!-- <van-field disabled label="销售单价" v-model="form.基础售价">
                </van-field> -->

                <van-field disabled label="订货点" v-model="form.订货点">
                </van-field>
                <van-field disabled label="备注" v-model="form.备注">
                </van-field>
                <van-field disabled label="标签分类" v-model="form.标签分类">
                </van-field>
                <van-field disabled label="标签名称" v-model="form.标签名称">
                </van-field>
                <van-field disabled label="是否专用纸箱" v-model="form.is纸箱">
                </van-field>
                <template v-if="form.纸箱分类 != ''">
                  <van-field disabled label="纸箱分类" v-model="form.纸箱分类">
                  </van-field>
                  <van-field disabled label="纸箱名称" v-model="form.纸箱名称">
                  </van-field>
                </template>
                <van-field disabled label="是否专用瓶盖" v-model="form.is瓶盖">
                </van-field>
                <template v-if="form.瓶盖分类 != ''">
                  <van-field disabled label="瓶盖分类" v-model="form.瓶盖分类">
                  </van-field>
                  <van-field disabled label="瓶盖名称" v-model="form.瓶盖名称">
                  </van-field>
                </template>

                <van-field disabled label="酒液分类" v-model="form.酒液分类">
                </van-field>

                <van-field disabled label="酒液名称" v-model="form.酒液名称">
                </van-field>

                <van-field disabled label="容器分类" v-model="form.容器分类">
                </van-field>
                <van-field disabled label="容器名称" v-model="form.容器名称">
                </van-field>
              </van-collapse-item>
              <van-collapse-item title="产品组成" name="3">
                <template v-for="(item, index) in form.子表">
                  <div style="width: 100%" :key="index">
                    <van-form
                      ref="vanformSub"
                      style="background-color: #fff"
                      label-width="6em"
                    >
                      <van-field
                        disabled
                        v-model="item.物料分类名称"
                        label="物料分类"
                        placeholder="物料分类"
                      >
                      </van-field>
                      <van-field
                        disabled
                        v-model="item.物料名称"
                        label="物料"
                        placeholder="物料"
                      >
                      </van-field>
                      <van-field
                        disabled
                        v-model="item.数量"
                        label="数量"
                        placeholder="数量"
                      >
                      </van-field>
                      <van-field
                        disabled
                        v-model="item.单价"
                        label="单价"
                        placeholder="单价"
                      >
                      </van-field>
                      <van-field
                        disabled
                        v-model="item.采购类型"
                        label="采购类型"
                        placeholder="采购类型"
                      >
                      </van-field>
                    </van-form>
                  </div>
                </template>
              </van-collapse-item>
            </van-collapse>

            <!-- 子表 -->

            <van-popup v-model="dateShow" position="bottom">
              <van-datetime-picker
                v-model="sunForm[formvalueTextValue]"
                type="date"
                title="请选择"
                @confirm="
                  (value) => {
                    onConfirmTime(value, 'dateShow', 'yyyy-mm-dd');
                  }
                "
                @cancel="onCancelDate('dateShow')"
              >
              </van-datetime-picker>
            </van-popup>
            <van-popup v-model="datetimeShow" position="bottom">
              <van-datetime-picker
                v-model="sunForm[formvalueTextValue]"
                type="datetime"
                title="请选择"
                @confirm="
                  (value) => {
                    onConfirmTime(value, 'datetimeShow');
                  }
                "
                @cancel="onCancelDate('datetimeShow')"
              >
              </van-datetime-picker>
            </van-popup>
            <van-popup v-model="timeShow" position="bottom">
              <van-datetime-picker
                v-model="sunForm[formvalueTextValue]"
                type="time"
                title="请选择"
                @confirm="
                  (value) => {
                    onConfirmTime(value, 'timeShow', 'hh:ss');
                  }
                "
                @cancel="onCancelDate('timeShow')"
              >
              </van-datetime-picker>
            </van-popup>

            <MorePicker
              ref="selectMulPicker"
              v-model="selectType"
              placeholder="请选择"
              :columns="columns"
              label-width="100"
              @confirm="confirmMulSelect"
              :option="{ label: 'text', value: 'value' }"
            />
          </van-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { baseURL } from "@/config/net.js";
import request from "@/utils/request";
import {
  APPsettings_list,
  Classificationmanagement_list,
  posts,
  mstable,
  mstableup,
  Tname_list,
  Packagingline_list,
  Packagingprocessdefinition_list,
  Materialclassleft_list,
  user_list,
  Packagingline_add,
  Packagingline_up,
  Productsubtable_list,
  material_list,
  rbcx1_list,
} from "@/api/list";
import { Notify } from "vant";
export default {
  components: {},
  props: ["addObj"],
  data() {
    return {
      服务地址: "",

      objData: {},

      form: {
        子表: [],
      },
      sunForm: {
        图片imgList: [],
      },
      datetimeShow: false,
      dateShow: false,
      timeShow: false,
      formValueText: "",
      formvalueTextValue: "",
      showPicker: false,
      defaultIndex: "",
      columns: [],
      isLoading: true,
      depNameValue: "",
      接口obj: {
        负责人: [],
        包装类型: [],
      },
      selectType: [],
      activeNames: ["1"],
    };
  },
  watch: {
    // "$route.query": {
    //   immediate: true,
    //   handler() {
    //     this.服务地址 = baseURL;
    //     if (JSON.stringify(this.$route.query) !== "{}") {
    //       console.log(this.$route.query);
    //       this.objData = this.$route.query;
    //       this.isLoading = true;
    //       if (this.objData.addeditdetail == 1) {
    //         this.isLoading = false;
    //       } else {
    //         this.$set(this.form, "ID", this.objData.ID);
    //         this.getData();
    //       }
    //     }
    //   },
    // },
    // addObj: {
    //   handler(newVal, oldVal) {
    //     this.服务地址 = baseURL;
    //     if (JSON.stringify(newVal) !== "{}") {
    //       this.objData = JSON.parse(JSON.stringify(newVal));
    //       this.isLoading = true;
    //       if (this.objData.addeditdetail == 1) {
    //         this.isLoading = false;
    //       } else {
    //         this.$set(this.form, "ID", this.objData.ID);
    //         this.getData();
    //       }
    //     }
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    this.服务地址 = baseURL;
    if (JSON.stringify(this.addObj) !== "{}") {
      this.objData = JSON.parse(JSON.stringify(this.addObj));

      this.isLoading = true;
      if (this.objData.addeditdetail == 1) {
        this.isLoading = false;
      } else {
        this.$set(this.form, "ID", this.objData.ID);
        this.getData();
      }
    }
  },
  methods: {
    onClickLeft() {
      // this.$router.go(-1);
      this.$emit("backclick");
    },
    flattenTree(tree) {
      tree.forEach((node) => {
        this.接口obj.包装类型.push({
          ...node,
          text: node.name,
          value: node.ID,
        });
        if (node.children) {
          this.flattenTree(node.children);
        }
      });
    },
    getData() {
      Promise.all([
        material_list({
          菜单: this.objData.menuID,
          查询条件: `a.ID='${this.objData.产品ID}'`,
        }),
        Productsubtable_list({
          菜单: this.objData.menuID,
          查询条件: `a.主表ID='${this.objData.产品ID}'`,
        }),
        // rbcx1_list({
        //   菜单: this.objData.menuID,
        //   查询条件: `a.ID='${this.objData.ID}'`,
        // }),
      ])
        .then((res) => {
          var obj = res[0].data.list.length != 0 ? res[0].data.list[0] : {};
          obj.子表 = [];
          obj.子表 = res[1].data.list;
          obj.is纸箱 =
            obj.纸箱分类ID == "" || obj.纸箱分类ID == "0" ? "否" : "是";
          obj.is瓶盖 =
            obj.瓶盖分类ID == "" || obj.瓶盖分类ID == "0" ? "否" : "是";
          this.form = obj;
          console.log(this.form);
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    clear(text) {
      this.formValueText = text;
      if (this.formValueText == "负责人") {
        this.form.负责人 = "";
        this.form.负责人ID = [];
        this.form.负责人s = [];
      } else {
        this.form.包装类型 = [];
        this.form.包装类型名称 = "";
        this.form.包装类型s = [];
      }
    },
    inputclick(text) {
      this.formValueText = text;
      this.columns = this.接口obj[text];
      this.selectType =
        text == "负责人" ? this.form.负责人ID : this.form.包装类型;
      this.$refs.selectMulPicker && this.$refs.selectMulPicker.showPopu();
      this.$nextTick(() => {
        this.$refs.selectMulPicker.setDefaultValue(this.selectType); // 设置默认值
      });
    },
    confirmMulSelect(value, value2) {
      console.log(value, value2);
      if (this.formValueText == "负责人") {
        this.form.负责人s = value2;
        this.form.负责人 = value2
          .map((item) => {
            return item.text;
          })
          .join(",");
        this.form.负责人ID = value;
      } else if (this.formValueText == "包装类型") {
        this.form.包装类型s = value2;
        this.form.包装类型名称 = value2
          .map((item) => {
            return item.text;
          })
          .join(",");
        this.form.包装类型 = value;
      }
    },
    depInput() {
      console.log(this.depNameValue);

      this.columns = this.接口obj[this.formvalueTextValue].filter((item) => {
        return item.text.includes(this.depNameValue);
      });
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    asyncBeforeReadPic(file) {
      return new Promise((resolve, reject) => {
        if (
          file.type === "image/jpg" ||
          file.type === "image/png" ||
          file.type === "image/jpeg" ||
          file.type === "image/gif"
        ) {
          resolve();
        } else {
          reject();

          Toast.fail("请上传 jpg、png、jpeg、gif 格式图片");
        }
      });
    },
    afterRead(res, text) {
      this.getBase64(res.file).then((result) => {
        posts(
          {
            file: result,
            文件名: res.file.name,
            文件类型: res.file.name.substr(res.file.name.lastIndexOf(".") + 1),
            模块名称: this.objData.munuName,
            主表ID: "",
            节点ID: "0",
            项目ID: "",
            菜单: this.objData.menuID,
          },
          res.file.name.substr(res.file.name.lastIndexOf(".") + 1)
        ).then((response) => {
          if (response && response.file) {
            Notify({ type: "success", message: "上传成功" });
            this.form[text] = response.file;
            this.sunForm[text + "imgList"] = [
              { url: this.服务地址 + response.file },
            ];
          } else {
            Notify({ type: "danger", message: "上传失败" });
            this.form[text] = "";
            this.sunForm[text + "imgList"] = [];
          }
        });
      });
    },
    deleteCoverPic(res, text) {
      console.log(res, text);
      var index = this.sunForm[text + "imgList"].findIndex((item) => {
        return item.url == res.url;
      });
      if (index != -1) {
        this.sunForm[text + "imgList"].splice(index, 1);
      }
      Notify({ type: "danger", message: "删除成功" });
    },
    onOversize(file) {
      console.log(file);
      Notify({ type: "danger", message: "文件大小不能超过 10M" });
    },

    saveclick() {
      this.$emit("saveclick");
      return;
      this.$refs.vanform
        .validate()
        .then(() => {
          this.isLoading = true;
          var obj = {
            菜单: this.objData.menuID,
            生产线名称: this.form.生产线名称,
            投产日期: this.form.投产日期,
            负责人: this.form.负责人,
            负责人ID:
              this.form.负责人ID.length == 0
                ? ""
                : this.form.负责人ID.join(","),
            包装类型:
              this.form.包装类型.length == 0
                ? ""
                : this.form.包装类型.join(","),
            包装类型名称: this.form.包装类型名称,
            备注: this.form.备注,
          };
          if (this.objData.addeditdetail == 1) {
            Packagingline_add(obj)
              .then((res) => {
                this.$router.go(-1);
                Notify({ type: "success", message: "新增成功" });
                this.isLoading = false;
              })
              .catch(() => {
                this.isLoading = false;
              });
          } else {
            obj.ID = this.form.ID;
            Packagingline_up(obj)
              .then((res) => {
                this.$router.go(-1);
                Notify({ type: "success", message: "修改成功" });
                this.isLoading = false;
              })
              .catch(() => {
                this.isLoading = false;
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 日期
    onclickdatetime(text, showText, type) {
      if (this.objData && this.objData.addeditdetail == 3) return;
      console.log(this.sunForm);
      this.formValueText = text;
      this.formvalueTextValue = text + "code";
      if (type && type == "hh:ss") {
        this.sunForm[this.formvalueTextValue] =
          this.form[text] == "" ? "" : this.form[text];
      } else {
        // this.form[text] = this.form[text] == '' ?
        //     type == '' ? this.formatDate(new Date(), 'yyyy-mm-dd') : this.formatDate(new Date())
        //     : this.form[text]
        this.sunForm[this.formvalueTextValue] =
          this.form[text] == ""
            ? new Date()
            : // type && type == "yyyy-mm-dd"
              // ? new Date(this.formatDate(new Date(), "yyyy-mm-dd"))
              // : new Date(this.formatDate(new Date()))
              new Date(this.form[text]);
      }

      console.log(new Date());
      this.$nextTick(() => {
        this[showText] = true;
      });
    },
    onConfirmTime(value, showText, type) {
      if (type && type == "hh:ss") {
        this.form[this.formValueText] = value ? value : "";

        this.sunForm[this.formvalueTextValue] = value ? value : "";
      } else {
        this.form[this.formValueText] =
          type && type == "yyyy-mm-dd"
            ? this.formatDate(value, "yyyy-mm-dd")
            : this.formatDate(value);
        this.sunForm[this.formvalueTextValue] = value;
      }
      console.log(value);

      console.log(this.form, this.sunForm);
      this[showText] = false;
    },
    onCancelDate(showText) {
      this[showText] = false;
    },
    // 格式化时间
    formatDate(date, type) {
      date = new Date(date);
      var str = `${date.getFullYear()}-${this.format(
        date.getMonth() + 1
      )}-${this.format(date.getDate())}`;

      return type && type == "yyyy-mm-dd"
        ? str
        : `${str} ${this.format(date.getHours())}:${this.format(
            date.getMinutes()
          )}:00`;

      // ${this.format(date.getSeconds())}
    },

    format(n) {
      return n < 10 ? "0" + n : "" + n;
    },
  },
};
</script>

<style  lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #f4f8fb;
  .title {
    width: 100%;
    flex-shrink: 0;
    font-size: 0.75rem;
  }
  .content {
    width: 100%;
    flex-grow: 1;
    overflow-y: hidden;
  }

  .scrolldiv {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}
:deep() {
  .van-collapse-item__content {
    background-color: transparent !important;
    padding: 0 1rem 0.75rem !important;
  }
}
</style>