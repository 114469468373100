var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","margin":"0.625rem 0"}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"content"},[_c('div',{ref:"scrollContainer",staticClass:"scrolldiv"},[_vm._l((_vm.子表),function(item,index){return [_c('SubCard',{key:index,ref:"SubCard",refInFor:true,attrs:{"subobj":{
              index: index,
              配置: _vm.配置,
              objData: _vm.objData,
              接口: _vm.接口,
              接口obj: _vm.接口obj,
              sunForm: _vm.sunForm,
              item: item,
              辅助配置: _vm.辅助配置,
            }},on:{"addRow":(obj) => {
                _vm.addRow(obj, index);
              },"updateObj":(obj) => {
                _vm.updateObj(obj, index);
              },"delRow":(obj) => {
                _vm.delRow(obj, index);
              }}})]})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }