var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%","height":"100%"}},[(_vm.isLoading)?_c('div',{staticClass:"myloading"},[_c('van-loading',{attrs:{"type":"spinner"}})],1):_c('div',{staticClass:"box"},[_c('div',{staticClass:"title"},[_c('van-nav-bar',{attrs:{"title":_vm.objData.munuName +
          (_vm.objData.addeditdetail == 1
            ? '新增'
            : _vm.objData.addeditdetail == 2
            ? '修改'
            : '详情'),"left-text":"返回","left-arrow":""},on:{"click-left":_vm.onClickLeft},scopedSlots:_vm._u([(_vm.objData && _vm.objData.addeditdetail != 3)?{key:"right",fn:function(){return [_c('van-button',{attrs:{"round":"","type":"info","size":"small"},on:{"click":_vm.saveclick}},[_vm._v("保存")])]},proxy:true}:null],null,true)})],1),_c('div',{staticClass:"content"},[_c('div',{ref:"scrollContainer",staticClass:"scrolldiv"},[_c('van-form',{ref:"vanform",attrs:{"label-width":"6em"}},[_vm._l((_vm.配置),function(item){return [_c('div',{key:item.名称},[(
                  item.类型.includes('varchar') &&
                  item.引用类型 != '字典' &&
                  (item.名称.includes('图片') ||
                    item.名称.includes('照片') ||
                    item.名称.includes('图像') ||
                    item.名称.includes('头像'))
                )?_c('van-field',{attrs:{"name":item.名称,"disabled":_vm.objData && _vm.objData.addeditdetail == 3,"rules":item.必填字段 == 0
                    ? [
                        {
                          required: true,
                          message: '请输入' + item.名称,
                        },
                      ]
                    : [],"label":item.名称,"placeholder":_vm.objData && _vm.objData.addeditdetail == 3
                    ? ''
                    : '请上传' + item.名称},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-uploader',{attrs:{"accept":"image/*","disabled":_vm.objData && _vm.objData.addeditdetail == 3,"max-count":1,"max-size":10240 * 1024,"after-read":(file) => {
                        _vm.afterRead(file, item.名称);
                      },"before-read":_vm.asyncBeforeReadPic},on:{"oversize":_vm.onOversize,"delete":(file) => {
                        _vm.deleteCoverPic(file, item.名称);
                      }},model:{value:(_vm.sunForm[item.名称 + 'imgList']),callback:function ($$v) {_vm.$set(_vm.sunForm, item.名称 + 'imgList', $$v)},expression:"sunForm[item.名称 + 'imgList']"}})]},proxy:true}],null,true),model:{value:(_vm.form[item.名称]),callback:function ($$v) {_vm.$set(_vm.form, item.名称, $$v)},expression:"form[item.名称]"}}):_vm._e(),(
                  item.类型.includes('varchar') &&
                  item.引用类型 != '字典' &&
                  !(
                    item.名称.includes('图片') ||
                    item.名称.includes('照片') ||
                    item.名称.includes('图像') ||
                    item.名称.includes('头像')
                  )
                )?_c('van-field',{attrs:{"name":item.名称,"disabled":_vm.objData && _vm.objData.addeditdetail == 3,"rules":item.必填字段 == 0
                    ? [
                        {
                          required: true,
                          message: '', //message: '请输入' + item.名称
                        },
                      ]
                    : [],"label":item.名称,"placeholder":_vm.objData && _vm.objData.addeditdetail == 3
                    ? ''
                    : '请输入' + item.名称},on:{"input":(val) => {
                    _vm.inputclick(val, item.名称);
                  }},model:{value:(_vm.form[item.名称]),callback:function ($$v) {_vm.$set(_vm.form, item.名称, $$v)},expression:"form[item.名称]"}}):_vm._e(),(item.类型.includes('json'))?_c('van-field',{attrs:{"name":item.名称,"disabled":_vm.objData && _vm.objData.addeditdetail == 3,"rules":item.必填字段 == 0
                    ? [
                        {
                          required: true,
                          message: '', //message: '请输入' + item.名称
                        },
                      ]
                    : [],"label":item.名称,"placeholder":_vm.objData && _vm.objData.addeditdetail == 3
                    ? ''
                    : '请输入' + item.名称},model:{value:(_vm.form[item.名称]),callback:function ($$v) {_vm.$set(_vm.form, item.名称, $$v)},expression:"form[item.名称]"}}):_vm._e(),(item.类型.includes('text'))?_c('van-field',{attrs:{"name":item.名称,"disabled":_vm.objData && _vm.objData.addeditdetail == 3,"rows":"1","autosize":"","rules":item.必填字段 == 0
                    ? [
                        {
                          required: true,
                          message: '', //message: '请输入' + item.名称
                        },
                      ]
                    : [],"label":item.名称,"placeholder":_vm.objData && _vm.objData.addeditdetail == 3
                    ? ''
                    : '请输入' + item.名称,"type":"textarea"},model:{value:(_vm.form[item.名称]),callback:function ($$v) {_vm.$set(_vm.form, item.名称, $$v)},expression:"form[item.名称]"}}):_vm._e(),(item.类型.includes('tinyint'))?_c('van-field',{attrs:{"name":item.名称,"disabled":_vm.objData && _vm.objData.addeditdetail == 3,"rules":item.必填字段 == 0
                    ? [
                        {
                          required: true,
                          message: '', //message: '请输入' + item.名称
                        },
                      ]
                    : [],"label":item.名称,"placeholder":_vm.objData && _vm.objData.addeditdetail == 3
                    ? ''
                    : '请选择' + item.名称},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-switch',{attrs:{"disabled":_vm.objData && _vm.objData.addeditdetail == 3,"inactive-value":"1","active-value":"0","size":"20"},model:{value:(_vm.form[item.名称]),callback:function ($$v) {_vm.$set(_vm.form, item.名称, $$v)},expression:"form[item.名称]"}})]},proxy:true}],null,true)}):_vm._e(),(
                  item.类型.includes('int') &&
                  !item.类型.includes('tinyint') &&
                  !item.名称.includes('ID') &&
                  item.引用类型 == ''
                )?_c('van-field',{attrs:{"name":item.名称,"disabled":_vm.objData && _vm.objData.addeditdetail == 3,"placeholder":_vm.objData && _vm.objData.addeditdetail == 3
                    ? ''
                    : '请输入' + item.名称,"type":"digit","rules":item.必填字段 == 0
                    ? [
                        {
                          required: true,
                          message: '', //message: '请输入' + item.名称
                        },
                      ]
                    : [],"label":item.名称},model:{value:(_vm.form[item.名称]),callback:function ($$v) {_vm.$set(_vm.form, item.名称, $$v)},expression:"form[item.名称]"}}):_vm._e(),(item.类型.includes('decimal'))?_c('van-field',{attrs:{"name":item.名称,"disabled":_vm.objData && _vm.objData.addeditdetail == 3,"placeholder":_vm.objData && _vm.objData.addeditdetail == 3
                    ? ''
                    : '请输入' + item.名称,"type":"number","rules":item.必填字段 == 0
                    ? [
                        {
                          required: true,
                          message: '', //message: '请输入' + item.名称
                        },
                      ]
                    : [],"label":item.名称},model:{value:(_vm.form[item.名称]),callback:function ($$v) {_vm.$set(_vm.form, item.名称, $$v)},expression:"form[item.名称]"}}):_vm._e(),(item.类型 == 'date')?[_c('van-field',{attrs:{"name":item.名称,"disabled":_vm.objData && _vm.objData.addeditdetail == 3,"readonly":"","clickable":"","label":item.名称,"rules":item.必填字段 == 0
                      ? [
                          {
                            required: true,
                            message: '', //message: '请选择' + item.名称
                          },
                        ]
                      : [],"placeholder":_vm.objData && _vm.objData.addeditdetail == 3
                      ? ''
                      : '点击选择' + item.名称},on:{"click":function($event){return _vm.onclickdatetime(item.名称, 'dateShow', 'yyyy-mm-dd')}},model:{value:(_vm.form[item.名称]),callback:function ($$v) {_vm.$set(_vm.form, item.名称, $$v)},expression:"form[item.名称]"}})]:_vm._e(),(item.类型 == 'datetime')?[_c('van-field',{attrs:{"name":item.名称,"disabled":_vm.objData && _vm.objData.addeditdetail == 3,"readonly":"","clickable":"","label":item.名称,"placeholder":_vm.objData && _vm.objData.addeditdetail == 3
                      ? ''
                      : '点击选择' + item.名称,"rules":item.必填字段 == 0
                      ? [
                          {
                            required: true,
                            message: '', //message: '请选择' + item.名称
                          },
                        ]
                      : []},on:{"click":function($event){return _vm.onclickdatetime(item.名称, 'datetimeShow')}},model:{value:(_vm.form[item.名称]),callback:function ($$v) {_vm.$set(_vm.form, item.名称, $$v)},expression:"form[item.名称]"}})]:_vm._e(),(item.类型 == 'time')?[_c('van-field',{attrs:{"name":item.名称,"disabled":_vm.objData && _vm.objData.addeditdetail == 3,"readonly":"","clickable":"","label":item.名称,"placeholder":_vm.objData && _vm.objData.addeditdetail == 3
                      ? ''
                      : '点击选择' + item.名称,"rules":item.必填字段 == 0
                      ? [
                          {
                            required: true,
                            message: '', //message: '请选择' + item.名称
                          },
                        ]
                      : []},on:{"click":function($event){return _vm.onclickdatetime(item.名称, 'timeShow', 'hh:ss')}},model:{value:(_vm.form[item.名称]),callback:function ($$v) {_vm.$set(_vm.form, item.名称, $$v)},expression:"form[item.名称]"}})]:_vm._e(),(
                  item.类型.includes('int') &&
                  item.名称.includes('ID') &&
                  !item.类型.includes('tinyint')
                )?[_c('van-field',{attrs:{"name":item.名称,"disabled":_vm.objData && _vm.objData.addeditdetail == 3,"label":item.名称.replace('ID', '名称'),"readonly":"","clickable":"","placeholder":_vm.objData && _vm.objData.addeditdetail == 3
                      ? ''
                      : '点击选择' + item.名称.replace('ID', '名称'),"rules":item.必填字段 == 0
                      ? [
                          {
                            required: true,
                            message: '', //message: '请选择' + item.名称
                          },
                        ]
                      : []},on:{"click":function($event){_vm.onClick(item.名称.replace('ID', '名称'), item.名称)}},model:{value:(_vm.sunForm[item.名称.replace('ID', '名称')]),callback:function ($$v) {_vm.$set(_vm.sunForm, item.名称.replace('ID', '名称'), $$v)},expression:"sunForm[item.名称.replace('ID', '名称')]"}})]:_vm._e(),(
                  item.类型.includes('int') &&
                  !item.类型.includes('tinyint') &&
                  !item.名称.includes('ID') &&
                  item.引用类型 == '引用表' &&
                  item.字典表选项 != ''
                )?[_c('van-field',{attrs:{"name":item.名称,"disabled":_vm.objData && _vm.objData.addeditdetail == 3,"label":item.名称.replace('ID', '名称'),"readonly":"","clickable":"","placeholder":_vm.objData && _vm.objData.addeditdetail == 3
                      ? ''
                      : '点击选择' + item.名称.replace('ID', '名称'),"rules":item.必填字段 == 0
                      ? [
                          {
                            required: true,
                            message: '', //message: '请选择' + item.名称
                          },
                        ]
                      : []},on:{"click":function($event){_vm.onClick(item.名称.replace('ID', '名称'), item.名称)}},model:{value:(_vm.sunForm[item.名称.replace('ID', '名称')]),callback:function ($$v) {_vm.$set(_vm.sunForm, item.名称.replace('ID', '名称'), $$v)},expression:"sunForm[item.名称.replace('ID', '名称')]"}})]:_vm._e(),(_vm.辅助配置[item.名称] && _vm.辅助配置[item.名称].length != 0)?[_vm._l((_vm.辅助配置[item.名称]),function(it2,in2){return [_c('van-field',{key:item.名称 + in2,attrs:{"disabled":"","label":it2.辅助字段名称},model:{value:(it2.辅助字段值),callback:function ($$v) {_vm.$set(it2, "辅助字段值", $$v)},expression:"it2.辅助字段值"}})]})]:_vm._e()],2)]}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[_c('van-search',{attrs:{"placeholder":"请输入"},on:{"input":_vm.depInput},model:{value:(_vm.depNameValue),callback:function ($$v) {_vm.depNameValue=$$v},expression:"depNameValue"}}),_c('van-picker',{attrs:{"value-key":"text","default-index":_vm.defaultIndex,"show-toolbar":"","columns":_vm.columns},on:{"confirm":(value) => {
                  _vm.onConfirm(value);
                },"cancel":function($event){return _vm.onCancel('showPicker')}}})],1),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.dateShow),callback:function ($$v) {_vm.dateShow=$$v},expression:"dateShow"}},[_c('van-datetime-picker',{attrs:{"type":"date","title":"请选择"},on:{"confirm":(value) => {
                  _vm.onConfirmTime(value, 'dateShow', 'yyyy-mm-dd');
                },"cancel":function($event){return _vm.onCancelDate('dateShow')}},model:{value:(_vm.sunForm[_vm.formvalueTextValue]),callback:function ($$v) {_vm.$set(_vm.sunForm, _vm.formvalueTextValue, $$v)},expression:"sunForm[formvalueTextValue]"}})],1),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.datetimeShow),callback:function ($$v) {_vm.datetimeShow=$$v},expression:"datetimeShow"}},[_c('van-datetime-picker',{attrs:{"type":"datetime","title":"请选择"},on:{"confirm":(value) => {
                  _vm.onConfirmTime(value, 'datetimeShow');
                },"cancel":function($event){return _vm.onCancelDate('datetimeShow')}},model:{value:(_vm.sunForm[_vm.formvalueTextValue]),callback:function ($$v) {_vm.$set(_vm.sunForm, _vm.formvalueTextValue, $$v)},expression:"sunForm[formvalueTextValue]"}})],1),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.timeShow),callback:function ($$v) {_vm.timeShow=$$v},expression:"timeShow"}},[_c('van-datetime-picker',{attrs:{"type":"time","title":"请选择"},on:{"confirm":(value) => {
                  _vm.onConfirmTime(value, 'timeShow', 'hh:ss');
                },"cancel":function($event){return _vm.onCancelDate('timeShow')}},model:{value:(_vm.sunForm[_vm.formvalueTextValue]),callback:function ($$v) {_vm.$set(_vm.sunForm, _vm.formvalueTextValue, $$v)},expression:"sunForm[formvalueTextValue]"}})],1)],2),(_vm.objData && _vm.objData.munuSubName != '')?_c('Subaddeditdetail',{ref:"sunForm"}):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }