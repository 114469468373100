<template>
  <div style="width: 100%; margin: 0.625rem 0">
    <div class="box">
      <div class="content">
        <div class="scrolldiv" ref="scrollContainer">
          <template v-for="(item, index) in 子表">
            <SubCard
              ref="SubCard"
              @addRow="
                (obj) => {
                  addRow(obj, index);
                }
              "
              @updateObj="
                (obj) => {
                  updateObj(obj, index);
                }
              "
              @delRow="
                (obj) => {
                  delRow(obj, index);
                }
              "
              :subobj="{
                index: index,
                配置: 配置,
                objData: objData,
                接口: 接口,
                接口obj: 接口obj,
                sunForm: sunForm,
                item: item,
                辅助配置: 辅助配置,
              }"
              :key="index"
            />
            <!-- form: form, -->
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { baseURL } from "@/config/net.js";
import SubCard from "./subCard";
import request from "@/utils/request";
import {
  APPsettings_list,
  Classificationmanagement_list,
  posts,
} from "@/api/list";
import { Notify } from "vant";
export default {
  name: "Subaddeditdetail",
  components: { SubCard },
  data() {
    return {
      服务地址: "",
      form: {},
      子表: [],
      配置: [],
      接口: [],
      接口obj: {},
      form: {},
      sunForm: {},
      isLoading: true,
      dels: [],
      辅助配置: {},
    };
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler() {
        this.服务地址 = baseURL;
        if (JSON.stringify(this.$route.query) !== "{}") {
          console.log(this.$route.query);
          this.objData = this.$route.query;
          this.isLoading = true;
          this.dels = [];
          if (this.objData.addeditdetail == 1) {
            this.getKey();
          } else {
            // this.$set(this.form, "ID", this.objData.ID);
            request({
              url: this.objData.munuSubName + "_list",
              method: "post",
              data: {
                菜单: this.objData.menuID,
                查询条件: "主表ID='" + this.objData.ID + "'",
              },
            }).then((res) => {
              this.子表 = res.data ? res.data.list : [];
              this.getKey();
            });
          }
        }
      },
    },
  },
  mounted() {
    this.服务地址 = baseURL;
  },
  methods: {
    getKey(objEdit) {
      APPsettings_list({
        菜单: this.objData.menuID,
        查询条件: `模板ID='${this.objData.menusubID}'`,
      }).then((res) => {
        if (res.data.list.length != 0) {
          this.配置 =
            res.data.list[0].配置 == ""
              ? []
              : JSON.parse(res.data.list[0].配置).filter((item) => {
                  return (
                    item.名称 != "单据编号" &&
                    item.名称 != "录入用户" &&
                    item.名称 != "录入时间" &&
                    item.名称 != "ID" &&
                    item.名称 != "审批状态"
                  );
                });
          this.配置.forEach((item) => {
            if (item.辅助字段 != "" && item.列表显示 == 0) {
              var arrlist = item.辅助字段.split(",").map((ii) => {
                return {
                  辅助字段名称: ii,
                  辅助字段值: "",
                };
              });
              this.$set(this.辅助配置, [item.名称], arrlist);
            }
            // if (objEdit && JSON.stringify(objEdit) !== "{}") {
            //   this.$set(this.form, item.名称, objEdit[item.名称]);
            // } else {
            this.$set(
              this.form,
              item.名称,
              item.类型.includes("tinyint") ? "0" : ""
            );
            // }
            if (item.类型 == "date") {
              this.$set(this.sunForm, item.名称 + "code", new Date());
              if (item.默认自定义显示 && item.默认自定义显示 != "") {
                var date = new Date();
                this.$set(this.sunForm, item.名称 + "code", date);
                this.$set(
                  this.form,
                  item.名称,
                  this.formatDate(date, "yyyy-mm-dd")
                );
              }
            } else if (item.类型 == "datetime") {
              this.$set(this.sunForm, item.名称 + "code", new Date());
              if (item.默认自定义显示 && item.默认自定义显示 != "") {
                var date = new Date();
                this.$set(this.sunForm, item.名称 + "code", date);
                this.$set(
                  this.form,
                  item.名称,
                  this.formatDate(date, "yyyy-mm-dd")
                );
              }
            } else if (item.类型.includes("init") && item.名称.includes("ID")) {
              this.$set(this.sunForm, item.名称.replace("ID", "名称"), "");
            } else if (
              item.名称.includes("图片") ||
              item.名称.includes("照片") ||
              item.名称.includes("图像") ||
              item.名称.includes("头像")
            ) {
              // if (objEdit && JSON.stringify(objEdit) !== "{}") {
              //   this.$set(this.sunForm, item.名称 + "imgList", [
              //     item.名称 == ""
              //       ? undefined
              //       : { url: this.服务地址 + objEdit[item.名称] },
              //   ]);
              // } else {
              this.$set(this.sunForm, item.名称 + "imgList", []);
              // }
            }
          });
          var 字典接口 = this.配置
            .filter((item) => {
              return item.引用类型 == "字典" && item.字典表选项 != "";
            })
            .map((item) => {
              this.接口obj[item.名称] = [];
              return {
                名称: item.名称,
                引用类型: item.引用类型,
                字典表选项: item.字典表选项,
                数据源显示字段: item.数据源显示字段,
                默认自定义显示: item.默认自定义显示,
                接口: Classificationmanagement_list({
                  菜单: this.objData.menuID,
                  查询条件: `类别='${item.字典表选项}'`,
                }),
              };
            });
          var 引用表接口 = this.配置
            .filter((item) => {
              return item.引用类型 == "引用表" && item.字典表选项 != "";
            })
            .map((item) => {
              this.接口obj[item.名称] = [];
              return {
                名称: item.名称,
                引用类型: item.引用类型,
                字典表选项: item.字典表选项,
                数据源显示字段: item.数据源显示字段,
                默认自定义显示: item.默认自定义显示,
                接口: request({
                  url: item.字典表选项,
                  method: "post",
                  data: {
                    菜单: this.objData.menuID,
                    查询条件:
                      item.字典表选项 == "/material_list"
                        ? item.名称.includes("产品")
                          ? "a.产品='1'"
                          : "a.产品='0'"
                        : undefined,
                  },
                }),
              };
            });
          this.接口 = 字典接口.concat(引用表接口);
          var allList = this.接口.map((item) => {
            return item.接口;
          });
          Promise.all(allList)
            .then((res) => {
              console.log(res);
              res.forEach((item, index) => {
                this.接口obj[this.接口[index].名称] = item.data.list.map(
                  (item) => {
                    return {
                      ...item,
                      text: item[this.接口[index].数据源显示字段],
                      value: item.ID,
                    };
                  }
                );
                // if (this.接口[index].字典表选项 == "/user_list") {
                //   this.接口obj[this.接口[index].名称] = item.data.list.map(
                //     (ii) => {
                //       return {
                //         ...ii,
                //         text: ii.用户名,
                //         value: ii.ID,
                //       };
                //     }
                //   );
                // } else if (this.接口[index].字典表选项 == "/department_list") {
                //   this.接口obj[this.接口[index].名称] = item.data.list.map(
                //     (ii) => {
                //       return {
                //         ...ii,
                //         text: ii.部门名称,
                //         value: ii.ID,
                //       };
                //     }
                //   );
                // } else if (this.接口[index].字典表选项 == "/material_list") {
                //   this.接口obj[this.接口[index].名称] = item.data.list.map(
                //     (ii) => {
                //       return {
                //         ...ii,
                //         text: ii.名称,
                //         value: ii.ID,
                //       };
                //     }
                //   );
                // } else if (this.接口[index].字典表选项 == "/Customer_list") {
                //   this.接口obj[this.接口[index].名称] = item.data.list.map(
                //     (item) => {
                //       return {
                //         ...item,
                //         text: item.公司名称,
                //         value: item.ID,
                //       };
                //     }
                //   );
                //   // if (objEdit && JSON.stringify(objEdit) !== "{}") {
                //   //   var obj = this.接口obj[this.接口[index].名称].find(
                //   //     (item) => {
                //   //       return item.value == this.form[this.接口[index].名称];
                //   //     }
                //   //   );
                //   //   this.$set(
                //   //     this.sunForm,
                //   //     this.接口[index].名称.replace("ID", "名称"),
                //   //     obj ? obj.text : ""
                //   //   );
                //   // }
                // } else if (this.接口[index].引用类型 == "字典") {
                //   this.接口obj[this.接口[index].名称] = item.data.list.map(
                //     (item) => {
                //       return {
                //         ...item,
                //         text: item.字典名称,
                //         value: item.ID,
                //       };
                //     }
                //   );
                //   // if (objEdit && JSON.stringify(objEdit) !== "{}") {
                //   //   var obj = this.接口obj[this.接口[index].名称].find(
                //   //     (item) => {
                //   //       return item.value == this.form[this.接口[index].名称];
                //   //     }
                //   //   );
                //   //   this.$set(
                //   //     this.sunForm,
                //   //     this.接口[index].名称 + "code",
                //   //     obj ? obj.text : ""
                //   //   );
                //   // }
                // } else {
                //   this.接口obj[this.接口[index].名称] = item.data.list;
                // }
              });
              this.子表 =
                this.子表.length == 0
                  ? (this.子表 = [Object.assign({}, this.form)])
                  : this.子表;
              this.isLoading = false;
            })
            .catch((err) => {
              this.isLoading = false;
            });
        } else {
          Notify({ type: "danger", message: "未配置相关数据" });
          this.$router.go(-1);
        }
      });
    },
    addRow(obj, index) {
      this.子表.splice(index, 1, obj);
      this.子表.push(Object.assign({}, this.form));
    },
    updateObj(obj, index) {
      this.子表.splice(index, 1, obj);
    },
    delRow(obj, index) {
      if (obj.ID) {
        this.dels.push(obj.ID);
      }
      this.子表.splice(index, 1);
      if (this.子表.length == 0) {
        this.子表 = [Object.assign({}, this.form)];
      }
    },
  },
};
</script>

<style  lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #f4f8fb;
  .title {
    width: 100%;
    flex-shrink: 0;
    font-size: 0.75rem;
  }
  .content {
    width: 100%;
    flex-grow: 1;
    overflow-y: hidden;
  }

  .scrolldiv {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}
</style>