<template>
  <div style="width: 100%; height: 100%">
    <div v-if="isLoading" class="myloading">
      <van-loading type="spinner" />
    </div>
    <div v-else class="box">
      <div class="title">
        <van-nav-bar
          :title="
            objData.munuName +
            (objData.addeditdetail == 1
              ? '新增'
              : objData.addeditdetail == 2
              ? '修改'
              : '详情')
          "
          left-text="返回"
          left-arrow
          @click-left="onClickLeft"
        >
          <template #right v-if="objData && objData.addeditdetail != 3">
            <van-button round @click="saveclick" type="info" size="small"
              >保存</van-button
            >
          </template>
        </van-nav-bar>
      </div>
      <div class="content">
        <div class="scrolldiv" ref="scrollContainer">
          <van-form ref="vanform" label-width="6em">
            <template v-for="item in 配置">
              <div :key="item.名称">
                <!-- 上传图片 -->
                <van-field
                  :name="item.名称"
                  :disabled="objData && objData.addeditdetail == 3"
                  v-if="
                    item.类型.includes('varchar') &&
                    item.引用类型 != '字典' &&
                    (item.名称.includes('图片') ||
                      item.名称.includes('照片') ||
                      item.名称.includes('图像') ||
                      item.名称.includes('头像'))
                  "
                  v-model="form[item.名称]"
                  :rules="
                    item.必填字段 == 0
                      ? [
                          {
                            required: true,
                            message: '请输入' + item.名称,
                          },
                        ]
                      : []
                  "
                  :label="item.名称"
                  :placeholder="
                    objData && objData.addeditdetail == 3
                      ? ''
                      : '请上传' + item.名称
                  "
                >
                  <template #input>
                    <van-uploader
                      accept="image/*"
                      :disabled="objData && objData.addeditdetail == 3"
                      :max-count="1"
                      :max-size="10240 * 1024"
                      @oversize="onOversize"
                      v-model="sunForm[item.名称 + 'imgList']"
                      :after-read="
                        (file) => {
                          afterRead(file, item.名称);
                        }
                      "
                      :before-read="asyncBeforeReadPic"
                      @delete="
                        (file) => {
                          deleteCoverPic(file, item.名称);
                        }
                      "
                    >
                    </van-uploader>
                  </template>
                </van-field>

                <!-- 单文本 -->

                <van-field
                  :name="item.名称"
                  :disabled="objData && objData.addeditdetail == 3"
                  v-if="
                    item.类型.includes('varchar') &&
                    item.引用类型 != '字典' &&
                    !(
                      item.名称.includes('图片') ||
                      item.名称.includes('照片') ||
                      item.名称.includes('图像') ||
                      item.名称.includes('头像')
                    )
                  "
                  @input="
                    (val) => {
                      inputclick(val, item.名称);
                    }
                  "
                  v-model="form[item.名称]"
                  :rules="
                    item.必填字段 == 0
                      ? [
                          {
                            required: true,
                            message: '', //message: '请输入' + item.名称
                          },
                        ]
                      : []
                  "
                  :label="item.名称"
                  :placeholder="
                    objData && objData.addeditdetail == 3
                      ? ''
                      : '请输入' + item.名称
                  "
                ></van-field>
                <van-field
                  :name="item.名称"
                  :disabled="objData && objData.addeditdetail == 3"
                  v-if="item.类型.includes('json')"
                  v-model="form[item.名称]"
                  :rules="
                    item.必填字段 == 0
                      ? [
                          {
                            required: true,
                            message: '', //message: '请输入' + item.名称
                          },
                        ]
                      : []
                  "
                  :label="item.名称"
                  :placeholder="
                    objData && objData.addeditdetail == 3
                      ? ''
                      : '请输入' + item.名称
                  "
                ></van-field>
                <!-- 多文本 -->
                <van-field
                  :name="item.名称"
                  :disabled="objData && objData.addeditdetail == 3"
                  v-if="item.类型.includes('text')"
                  rows="1"
                  autosize
                  v-model="form[item.名称]"
                  :rules="
                    item.必填字段 == 0
                      ? [
                          {
                            required: true,
                            message: '', //message: '请输入' + item.名称
                          },
                        ]
                      : []
                  "
                  :label="item.名称"
                  :placeholder="
                    objData && objData.addeditdetail == 3
                      ? ''
                      : '请输入' + item.名称
                  "
                  type="textarea"
                />
                <!-- 开关 0 1 -->
                <van-field
                  :name="item.名称"
                  :disabled="objData && objData.addeditdetail == 3"
                  v-if="item.类型.includes('tinyint')"
                  :rules="
                    item.必填字段 == 0
                      ? [
                          {
                            required: true,
                            message: '', //message: '请输入' + item.名称
                          },
                        ]
                      : []
                  "
                  :label="item.名称"
                  :placeholder="
                    objData && objData.addeditdetail == 3
                      ? ''
                      : '请选择' + item.名称
                  "
                >
                  <template #input>
                    <van-switch
                      :disabled="objData && objData.addeditdetail == 3"
                      inactive-value="1"
                      active-value="0"
                      v-model="form[item.名称]"
                      size="20"
                    />
                  </template>
                </van-field>
                <!-- 整数 -->
                <van-field
                  :name="item.名称"
                  :disabled="objData && objData.addeditdetail == 3"
                  :placeholder="
                    objData && objData.addeditdetail == 3
                      ? ''
                      : '请输入' + item.名称
                  "
                  v-model="form[item.名称]"
                  type="digit"
                  v-if="
                    item.类型.includes('int') &&
                    !item.类型.includes('tinyint') &&
                    !item.名称.includes('ID') &&
                    item.引用类型 == ''
                  "
                  :rules="
                    item.必填字段 == 0
                      ? [
                          {
                            required: true,
                            message: '', //message: '请输入' + item.名称
                          },
                        ]
                      : []
                  "
                  :label="item.名称"
                />
                <!-- 小数 -->
                <van-field
                  :name="item.名称"
                  :disabled="objData && objData.addeditdetail == 3"
                  :placeholder="
                    objData && objData.addeditdetail == 3
                      ? ''
                      : '请输入' + item.名称
                  "
                  v-model="form[item.名称]"
                  type="number"
                  v-if="item.类型.includes('decimal')"
                  :rules="
                    item.必填字段 == 0
                      ? [
                          {
                            required: true,
                            message: '', //message: '请输入' + item.名称
                          },
                        ]
                      : []
                  "
                  :label="item.名称"
                />
                <!-- 日期年月日 -->
                <template v-if="item.类型 == 'date'">
                  <van-field
                    :name="item.名称"
                    :disabled="objData && objData.addeditdetail == 3"
                    readonly
                    clickable
                    v-model="form[item.名称]"
                    :label="item.名称"
                    :rules="
                      item.必填字段 == 0
                        ? [
                            {
                              required: true,
                              message: '', //message: '请选择' + item.名称
                            },
                          ]
                        : []
                    "
                    :placeholder="
                      objData && objData.addeditdetail == 3
                        ? ''
                        : '点击选择' + item.名称
                    "
                    @click="
                      onclickdatetime(item.名称, 'dateShow', 'yyyy-mm-dd')
                    "
                  >
                  </van-field>
                </template>
                <template v-if="item.类型 == 'datetime'">
                  <!-- 日期年月日时分 -->
                  <van-field
                    :name="item.名称"
                    :disabled="objData && objData.addeditdetail == 3"
                    readonly
                    clickable
                    v-model="form[item.名称]"
                    :label="item.名称"
                    :placeholder="
                      objData && objData.addeditdetail == 3
                        ? ''
                        : '点击选择' + item.名称
                    "
                    :rules="
                      item.必填字段 == 0
                        ? [
                            {
                              required: true,
                              message: '', //message: '请选择' + item.名称
                            },
                          ]
                        : []
                    "
                    @click="onclickdatetime(item.名称, 'datetimeShow')"
                  >
                  </van-field>
                </template>
                <template v-if="item.类型 == 'time'">
                  <!-- 日期年月日时分 -->
                  <van-field
                    :name="item.名称"
                    :disabled="objData && objData.addeditdetail == 3"
                    readonly
                    clickable
                    v-model="form[item.名称]"
                    :label="item.名称"
                    :placeholder="
                      objData && objData.addeditdetail == 3
                        ? ''
                        : '点击选择' + item.名称
                    "
                    :rules="
                      item.必填字段 == 0
                        ? [
                            {
                              required: true,
                              message: '', //message: '请选择' + item.名称
                            },
                          ]
                        : []
                    "
                    @click="onclickdatetime(item.名称, 'timeShow', 'hh:ss')"
                  >
                  </van-field>
                </template>

                <!-- 下拉单选 -->
                <template
                  v-if="
                    item.类型.includes('int') &&
                    item.名称.includes('ID') &&
                    !item.类型.includes('tinyint')
                  "
                >
                  <van-field
                    :name="item.名称"
                    :disabled="objData && objData.addeditdetail == 3"
                    :label="item.名称.replace('ID', '名称')"
                    readonly
                    clickable
                    v-model="sunForm[item.名称.replace('ID', '名称')]"
                    :placeholder="
                      objData && objData.addeditdetail == 3
                        ? ''
                        : '点击选择' + item.名称.replace('ID', '名称')
                    "
                    :rules="
                      item.必填字段 == 0
                        ? [
                            {
                              required: true,
                              message: '', //message: '请选择' + item.名称
                            },
                          ]
                        : []
                    "
                    @click="onClick(item.名称.replace('ID', '名称'), item.名称)"
                  >
                  </van-field>
                </template>
                <template
                  v-if="
                    item.类型.includes('int') &&
                    !item.类型.includes('tinyint') &&
                    !item.名称.includes('ID') &&
                    item.引用类型 == '引用表' &&
                    item.字典表选项 != ''
                  "
                >
                  <van-field
                    :name="item.名称"
                    :disabled="objData && objData.addeditdetail == 3"
                    :label="item.名称.replace('ID', '名称')"
                    readonly
                    clickable
                    v-model="sunForm[item.名称.replace('ID', '名称')]"
                    :placeholder="
                      objData && objData.addeditdetail == 3
                        ? ''
                        : '点击选择' + item.名称.replace('ID', '名称')
                    "
                    :rules="
                      item.必填字段 == 0
                        ? [
                            {
                              required: true,
                              message: '', //message: '请选择' + item.名称
                            },
                          ]
                        : []
                    "
                    @click="onClick(item.名称.replace('ID', '名称'), item.名称)"
                  >
                  </van-field>
                </template>
                <!-- <template
                  v-if="item.引用类型 == '字典' && item.字典表选项 != ''"
                >
                  <van-field
                    :name="item.名称"
                    :disabled="objData && objData.addeditdetail == 3"
                    :label="item.名称"
                    readonly
                    clickable
                    v-model="sunForm[item.名称.replace('ID', '名称')]"
                    :placeholder="
                      objData && objData.addeditdetail == 3
                        ? ''
                        : '点击选择' + item.名称
                    "
                    :rules="
                      item.必填字段 == 0
                        ? [
                            {
                              required: true,
                              message: '', //message: '请选择' + item.名称
                            },
                          ]
                        : []
                    "
                    @click="onClick(item.名称.replace('ID', '名称'), item.名称)"
                  >
                  </van-field>
                </template> -->

                <template
                  v-if="辅助配置[item.名称] && 辅助配置[item.名称].length != 0"
                >
                  <template v-for="(it2, in2) in 辅助配置[item.名称]">
                    <van-field
                      :key="item.名称 + in2"
                      disabled
                      v-model="it2.辅助字段值"
                      :label="it2.辅助字段名称"
                    ></van-field>
                  </template>
                </template>
              </div>
            </template>
            <van-popup v-model="showPicker" position="bottom">
              <van-search
                placeholder="请输入"
                @input="depInput"
                v-model="depNameValue"
              />
              <van-picker
                value-key="text"
                :default-index="defaultIndex"
                show-toolbar
                :columns="columns"
                @confirm="
                  (value) => {
                    onConfirm(value);
                  }
                "
                @cancel="onCancel('showPicker')"
              />
            </van-popup>
            <van-popup v-model="dateShow" position="bottom">
              <van-datetime-picker
                v-model="sunForm[formvalueTextValue]"
                type="date"
                title="请选择"
                @confirm="
                  (value) => {
                    onConfirmTime(value, 'dateShow', 'yyyy-mm-dd');
                  }
                "
                @cancel="onCancelDate('dateShow')"
              >
              </van-datetime-picker>
            </van-popup>
            <van-popup v-model="datetimeShow" position="bottom">
              <van-datetime-picker
                v-model="sunForm[formvalueTextValue]"
                type="datetime"
                title="请选择"
                @confirm="
                  (value) => {
                    onConfirmTime(value, 'datetimeShow');
                  }
                "
                @cancel="onCancelDate('datetimeShow')"
              >
              </van-datetime-picker>
            </van-popup>
            <van-popup v-model="timeShow" position="bottom">
              <van-datetime-picker
                v-model="sunForm[formvalueTextValue]"
                type="time"
                title="请选择"
                @confirm="
                  (value) => {
                    onConfirmTime(value, 'timeShow', 'hh:ss');
                  }
                "
                @cancel="onCancelDate('timeShow')"
              >
              </van-datetime-picker>
            </van-popup>
          </van-form>
          <Subaddeditdetail
            v-if="objData && objData.munuSubName != ''"
            ref="sunForm"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { baseURL } from "@/config/net.js";
import Subaddeditdetail from "./Subaddeditdetail";
import request from "@/utils/request";
import {
  APPsettings_list,
  Classificationmanagement_list,
  posts,
  mstable,
  mstableup,
  Tname_list,
} from "@/api/list";
import { Notify } from "vant";
export default {
  components: { Subaddeditdetail },
  data() {
    return {
      服务地址: "",
      配置: [],
      列表Key: [],
      objData: {},
      接口: [],
      接口obj: {},
      form: {},
      sunForm: {},
      datetimeShow: false,
      dateShow: false,
      timeShow: false,
      formValueText: "",
      formvalueTextValue: "",
      showPicker: false,
      defaultIndex: "",
      columns: [],
      isLoading: true,
      主表表名: "",
      附表表名: "",

      辅助配置: {},
      depNameValue: "",
    };
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler() {
        this.服务地址 = baseURL;
        if (JSON.stringify(this.$route.query) !== "{}") {
          console.log(this.$route.query);
          this.objData = this.$route.query;
          Tname_list({ 查询条件: "a.ID='" + this.objData.menuID + "'" }).then(
            (res) => {
              if (res.data.list.length != 0) {
                var obj = res.data.list[0];
                this.主表表名 = obj.主表表名;
                this.附表表名 = obj.附表表名;
              } else {
                this.主表表名 = "";
                this.附表表名 = "";
              }
            }
          );
          this.isLoading = true;
          if (this.objData.addeditdetail == 1) {
            this.getKey();
          } else {
            this.$set(this.form, "ID", this.objData.ID);
            // this.getKey();
            request({
              url: this.objData.munu接口名 + "_list",
              method: "post",
              data: {
                菜单: this.objData.menuID,
                查询条件: "a.ID='" + this.objData.ID + "'",
              },
            }).then((res) => {
              var obj = res.data
                ? res.data.list.length == 0
                  ? {}
                  : res.data.list[0]
                : {};
              this.getKey(obj);
            });
          }
        }
      },
    },
  },
  mounted() {
    this.服务地址 = baseURL;
    // this.$router.go(-1);
    // 名称: item.名称,
    // 类型: item.类型,
    // 允许空: item.允许空,
    // 必填字段: item.必填字段,
    // 列表显示: item.列表显示,
    // 引用类型: item.引用类型,
    // 字典Classificationmanagement_list查询条件: "`类别`='8'"
    // 引用表
    // 选项
    // 字典表选项: item.字典表选项,
  },
  methods: {
    depInput() {
      console.log(this.depNameValue);

      this.columns = this.接口obj[this.formvalueTextValue].filter((item) => {
        return item.text.includes(this.depNameValue);
      });
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    asyncBeforeReadPic(file) {
      return new Promise((resolve, reject) => {
        if (
          file.type === "image/jpg" ||
          file.type === "image/png" ||
          file.type === "image/jpeg" ||
          file.type === "image/gif"
        ) {
          resolve();
        } else {
          reject();

          Toast.fail("请上传 jpg、png、jpeg、gif 格式图片");
        }
      });
    },
    afterRead(res, text) {
      this.getBase64(res.file).then((result) => {
        posts(
          {
            file: result,
            文件名: res.file.name,
            文件类型: res.file.name.substr(res.file.name.lastIndexOf(".") + 1),
            模块名称: this.objData.munuName,
            主表ID: "",
            节点ID: "0",
            项目ID: "",
            菜单: this.objData.menuID,
          },
          res.file.name.substr(res.file.name.lastIndexOf(".") + 1)
        ).then((response) => {
          if (response && response.file) {
            Notify({ type: "success", message: "上传成功" });
            this.form[text] = response.file;
            this.sunForm[text + "imgList"] = [
              { url: this.服务地址 + response.file },
            ];
          } else {
            Notify({ type: "danger", message: "上传失败" });
            this.form[text] = "";
            this.sunForm[text + "imgList"] = [];
          }
        });
      });
    },
    deleteCoverPic(res, text) {
      console.log(res, text);
      var index = this.sunForm[text + "imgList"].findIndex((item) => {
        return item.url == res.url;
      });
      if (index != -1) {
        this.sunForm[text + "imgList"].splice(index, 1);
      }
      Notify({ type: "danger", message: "删除成功" });
    },
    onOversize(file) {
      console.log(file);
      Notify({ type: "danger", message: "文件大小不能超过 10M" });
    },
    inputclick(val, text) {
      // this.$set(this.form,text,val)
    },
    saveclick() {
      if (this.objData && this.objData.munuSubName != "") {
        var list = this.$refs.sunForm.$refs.SubCard.map((item, index) => {
          return new Promise((resolve, reject) => {
            this.$refs.sunForm.$refs.SubCard[index].$refs.vanformSub
              .validate()
              .then(() => {
                resolve();
              })
              .catch(() => {
                reject();
              });
          });
        });
        list.push(
          new Promise((resolve, reject) => {
            this.$refs.vanform
              .validate()
              .then(() => {
                resolve();
              })
              .catch(() => {
                reject();
              });
          })
        );
        Promise.all(list)
          .then((res) => {
            this.getFun(2);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$refs.vanform
          .validate()
          .then(() => {
            this.getFun(1);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getFun(number) {
      if (number == 1) {
        this.isLoading = true;
        var obj = { 菜单: this.objData.menuID };
        this.配置.forEach((item) => {
          obj = {
            ...obj,
            [item.名称]: this.form[item.名称],
          };
        });
        if (this.objData.addeditdetail == 1) {
          request({
            url: this.objData.munu接口名 + "_add",
            method: "post",
            data: obj,
          })
            .then((res) => {
              this.$router.go(-1);
              Notify({ type: "success", message: "新增成功" });
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
            });
        } else {
          obj.ID = this.form.ID;
          request({
            url: this.objData.munu接口名 + "_up",
            method: "post",
            data: obj,
          })
            .then((res) => {
              this.$router.go(-1);
              Notify({ type: "success", message: "修改成功" });
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
            });
        }
      } else {
        this.isLoading = true;
        var obj = { 菜单: this.objData.menuID };
        this.配置.forEach((item) => {
          obj = {
            ...obj,
            [item.名称]: this.form[item.名称],
            table1: this.主表表名,
            table2: this.附表表名,
            data: [],
          };
        });
        var list = [];
        this.$refs.sunForm.子表.forEach((item) => {
          var objFinish = { ID: item.ID ? item.ID : undefined };
          this.$refs.sunForm.配置.forEach((ii) => {
            objFinish = {
              ...objFinish,
              [ii.名称]: item[ii.名称],
            };
          });
          list.push(objFinish);
        });
        console.log(list);
        obj.data = list;
        console.log(this.$refs.sunForm.子表);
        console.log(this.$refs.sunForm.dels);
        if (this.objData.addeditdetail == 1) {
          // request({
          //   url: this.objData.munu接口名 + "_add",
          //   method: "post",
          //   data: obj,
          // })
          mstable(obj)
            .then((res) => {
              this.$router.go(-1);
              Notify({ type: "success", message: "新增成功" });
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
            });
        } else {
          obj.del = this.$refs.sunForm.dels;
          obj.ID = this.form.ID;
          mstableup(obj)
            // request({
            //   url: this.objData.munu接口名 + "_up",
            //   method: "post",
            //   data: obj,
            // })
            .then((res) => {
              this.$router.go(-1);
              Notify({ type: "success", message: "修改成功" });
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
            });
        }
      }
    },
    // 下拉选
    onClick(text, valueText) {
      this.depNameValue = "";
      if (this.objData && this.objData.addeditdetail == 3) return;
      this.columns = this.接口obj[valueText];
      this.formValueText = text;
      this.formvalueTextValue = valueText;
      var index = this.columns.findIndex((item) => {
        return item.value == this.form[valueText];
      });
      console.log(index);
      this.defaultIndex = index != -1 ? index : "";
      this.showPicker = true;
    },
    onConfirm(e) {
      if (!e) return;

      var value = e.value;
      this.form[this.formvalueTextValue] = value;
      var obj = this.columns.find((item) => {
        return item.value == value;
      });
      this.sunForm[this.formValueText] = obj ? obj.text : "";
      if (
        this.辅助配置[this.formvalueTextValue] &&
        this.辅助配置[this.formvalueTextValue].length != 0
      ) {
        this.辅助配置[this.formvalueTextValue].forEach((item) => {
          item.辅助字段值 = obj ? obj[item.辅助字段名称] : "";
        });
      }
      this.showPicker = false;
    },
    onCancel(showText) {
      this[showText] = false;
    },
    // 日期
    onclickdatetime(text, showText, type) {
      if (this.objData && this.objData.addeditdetail == 3) return;
      console.log(this.sunForm);
      this.formValueText = text;
      this.formvalueTextValue = text + "code";
      if (type && type == "hh:ss") {
        this.sunForm[this.formvalueTextValue] =
          this.form[text] == "" ? "" : this.form[text];
      } else {
        // this.form[text] = this.form[text] == '' ?
        //     type == '' ? this.formatDate(new Date(), 'yyyy-mm-dd') : this.formatDate(new Date())
        //     : this.form[text]
        this.sunForm[this.formvalueTextValue] =
          this.form[text] == ""
            ? new Date()
            : // type && type == "yyyy-mm-dd"
              // ? new Date(this.formatDate(new Date(), "yyyy-mm-dd"))
              // : new Date(this.formatDate(new Date()))
              new Date(this.form[text]);
      }

      console.log(new Date());
      this.$nextTick(() => {
        this[showText] = true;
      });
    },
    onConfirmTime(value, showText, type) {
      if (type && type == "hh:ss") {
        this.form[this.formValueText] = value ? value : "";

        this.sunForm[this.formvalueTextValue] = value ? value : "";
      } else {
        this.form[this.formValueText] =
          type && type == "yyyy-mm-dd"
            ? this.formatDate(value, "yyyy-mm-dd")
            : this.formatDate(value);
        this.sunForm[this.formvalueTextValue] = value;
      }
      console.log(value);

      console.log(this.form, this.sunForm);
      this[showText] = false;
    },
    onCancelDate(showText) {
      this[showText] = false;
    },
    // 格式化时间
    formatDate(date, type) {
      date = new Date(date);
      var str = `${date.getFullYear()}-${this.format(
        date.getMonth() + 1
      )}-${this.format(date.getDate())}`;

      return type && type == "yyyy-mm-dd"
        ? str
        : `${str} ${this.format(date.getHours())}:${this.format(
            date.getMinutes()
          )}:00`;

          // ${this.format(date.getSeconds())}
    },

    format(n) {
      return n < 10 ? "0" + n : "" + n;
    },
    getKey(objEdit) {
      APPsettings_list({
        菜单: this.objData.menuID,
        查询条件: `模板ID='${this.objData.menuID}'`,
      }).then((res) => {
        if (res.data.list.length != 0) {
          this.配置 =
            res.data.list[0].配置 == ""
              ? []
              : JSON.parse(res.data.list[0].配置).filter((item) => {
                  return (
                    item.名称 != "单据编号" &&
                    item.名称 != "录入用户" &&
                    item.名称 != "录入时间" &&
                    item.名称 != "ID" &&
                    item.名称 != "审批状态"
                  );
                });
          this.配置.forEach((item) => {
            if (item.辅助字段 != "" && item.列表显示 == 0) {
              var arrlist = item.辅助字段.split(",").map((ii) => {
                return {
                  辅助字段名称: ii,
                  辅助字段值:
                    objEdit && JSON.stringify(objEdit) !== "{}"
                      ? objEdit[ii]
                      : "",
                };
              });
              this.$set(this.辅助配置, [item.名称], arrlist);
            }
            if (objEdit && JSON.stringify(objEdit) !== "{}") {
              this.$set(this.form, item.名称, objEdit[item.名称]);
            } else {
              this.$set(
                this.form,
                item.名称,
                item.类型.includes("tinyint") ? "0" : ""
              );
            }
            if (item.类型 == "date") {
              this.$set(this.sunForm, item.名称 + "code", new Date());
              if (!objEdit) {
                if (item.默认自定义显示 && item.默认自定义显示 != "") {
                  var date = new Date();

                  this.$set(this.sunForm, item.名称 + "code", date);
                  this.$set(
                    this.form,
                    item.名称,
                    this.formatDate(date, "yyyy-mm-dd")
                  );
                }
              }
            } else if (item.类型 == "datetime") {
              this.$set(this.sunForm, item.名称 + "code", new Date());
              if (!objEdit) {
                if (item.默认自定义显示 && item.默认自定义显示 != "") {
                  var date = new Date();

                  this.$set(this.sunForm, item.名称 + "code", date);
                  this.$set(this.form, item.名称, this.formatDate(date));
                }
              }
            } else if (item.类型.includes("init") && item.名称.includes("ID")) {
              this.$set(this.sunForm, item.名称.replace("ID", "名称"), "");
            } else if (
              item.名称.includes("图片") ||
              item.名称.includes("照片") ||
              item.名称.includes("图像") ||
              item.名称.includes("头像")
            ) {
              if (objEdit && JSON.stringify(objEdit) !== "{}") {
                this.$set(this.sunForm, item.名称 + "imgList", [
                  item.名称 == ""
                    ? undefined
                    : { url: this.服务地址 + objEdit[item.名称] },
                ]);
              } else {
                this.$set(this.sunForm, item.名称 + "imgList", []);
              }
            }
          });
          var 字典接口 = this.配置
            .filter((item) => {
              return item.引用类型 == "字典" && item.字典表选项 != "";
            })
            .map((item) => {
              this.接口obj[item.名称] = [];
              return {
                名称: item.名称,
                引用类型: item.引用类型,
                字典表选项: item.字典表选项,
                数据源显示字段: item.数据源显示字段,
                默认自定义显示: item.默认自定义显示,
                接口: Classificationmanagement_list({
                  菜单: this.objData.menuID,
                  查询条件: `类别='${item.字典表选项}'`,
                }),
              };
            });
          var 引用表接口 = this.配置
            .filter((item) => {
              return item.引用类型 == "引用表" && item.字典表选项 != "";
            })
            .map((item) => {
              this.接口obj[item.名称] = [];
              return {
                名称: item.名称,
                引用类型: item.引用类型,
                字典表选项: item.字典表选项,
                数据源显示字段: item.数据源显示字段,
                默认自定义显示: item.默认自定义显示,
                接口: request({
                  url: item.字典表选项,
                  method: "post",
                  data: {
                    菜单: this.objData.menuID,
                    查询条件:
                      item.字典表选项 == "/material_list"
                        ? item.名称.includes("产品")
                          ? "a.产品='1'"
                          : "a.产品='0'"
                        : undefined,
                  },
                }),
              };
            });
          this.接口 = 字典接口.concat(引用表接口);
          var allList = this.接口.map((item) => {
            return item.接口;
          });
          Promise.all(allList)
            .then((res) => {
              console.log(res);
              res.forEach((item, index) => {
                // if (this.接口[index].字典表选项 == "/user_list") {
                //   this.接口obj[this.接口[index].名称] = item.data.list.map(
                //     (ii) => {
                //       return {
                //         ...ii,
                //         text: ii.用户名,
                //         value: ii.ID,
                //       };
                //     }
                //   );
                //   if (objEdit && JSON.stringify(objEdit) !== "{}") {
                //     var obj = this.接口obj[this.接口[index].名称].find((ii) => {
                //       return ii.value == this.form[this.接口[index].名称];
                //     });
                //     this.$set(
                //       this.sunForm,
                //       this.接口[index].名称.replace("ID", "名称"),
                //       obj ? obj.text : ""
                //     );
                //   }
                // } else if (this.接口[index].字典表选项 == "/department_list") {
                //   this.接口obj[this.接口[index].名称] = item.data.list.map(
                //     (ii) => {
                //       return {
                //         ...ii,
                //         text: ii.部门名称,
                //         value: ii.ID,
                //       };
                //     }
                //   );
                //   if (objEdit && JSON.stringify(objEdit) !== "{}") {
                //     var obj = this.接口obj[this.接口[index].名称].find((ii) => {
                //       return ii.value == this.form[this.接口[index].名称];
                //     });
                //     this.$set(
                //       this.sunForm,
                //       this.接口[index].名称.replace("ID", "名称"),
                //       obj ? obj.text : ""
                //     );
                //   }
                // } else if (this.接口[index].字典表选项 == "/material_list") {
                //   this.接口obj[this.接口[index].名称] = item.data.list.map(
                //     (ii) => {
                //       return {
                //         ...ii,
                //         text: ii.名称,
                //         value: ii.ID,
                //       };
                //     }
                //   );
                //   if (objEdit && JSON.stringify(objEdit) !== "{}") {
                //     var obj = this.接口obj[this.接口[index].名称].find((ii) => {
                //       return ii.value == this.form[this.接口[index].名称];
                //     });
                //     this.$set(
                //       this.sunForm,
                //       this.接口[index].名称.replace("ID", "名称"),
                //       obj ? obj.text : ""
                //     );
                //   }
                // } else if (this.接口[index].字典表选项 == "/Customer_list") {
                //   this.接口obj[this.接口[index].名称] = item.data.list.map(
                //     (ii) => {
                //       return {
                //         ...ii,
                //         text: ii.公司名称,
                //         value: ii.ID,
                //       };
                //     }
                //   );
                //   if (objEdit && JSON.stringify(objEdit) !== "{}") {
                //     var obj = this.接口obj[this.接口[index].名称].find((ii) => {
                //       return ii.value == this.form[this.接口[index].名称];
                //     });
                //     this.$set(
                //       this.sunForm,
                //       this.接口[index].名称.replace("ID", "名称"),
                //       obj ? obj.text : ""
                //     );
                //   }
                // } else
                // if (this.接口[index].引用类型 == "字典") {
                //   this.接口obj[this.接口[index].名称] = item.data.list.map(
                //     (ii) => {
                //       return {
                //         ...ii,
                //         text: ii.字典名称,
                //         value: ii.ID,
                //       };
                //     }
                //   );
                //   if (objEdit && JSON.stringify(objEdit) !== "{}") {
                //     var obj = this.接口obj[this.接口[index].名称].find((ii) => {
                //       return ii.value == this.form[this.接口[index].名称];
                //     });

                //     this.$set(
                //       this.sunForm,
                //       this.接口[index].名称 + "code",
                //       obj ? obj.text : ""
                //     );
                //   }
                // } else {
                this.接口obj[this.接口[index].名称] = item.data.list.map(
                  (ii) => {
                    return {
                      ...ii,
                      text: ii[this.接口[index].数据源显示字段],
                      value: ii.ID,
                    };
                  }
                );
                if (objEdit && JSON.stringify(objEdit) !== "{}") {
                  var obj = this.接口obj[this.接口[index].名称].find((ii) => {
                    return ii.value == this.form[this.接口[index].名称];
                  });

                  this.$set(
                    this.sunForm,
                    this.接口[index].名称.replace("ID", "名称"),
                    obj ? obj.text : ""
                  );
                } else {
                  // this.辅助配置
                  if (
                    this.接口[index].默认自定义显示 &&
                    this.接口[index].默认自定义显示 != ""
                  ) {
                    var obj = this.接口obj[this.接口[index].名称].find((ii) => {
                      return (
                        ii.value ==
                        (this.接口[index].默认自定义显示 != "当前用户"
                          ? this.接口[index].默认自定义显示
                          : JSON.parse(localStorage.getItem("id")))
                      );
                    });
                    if (
                      this.辅助配置[this.接口[index].名称] &&
                      this.辅助配置[this.接口[index].名称].length != 0
                    ) {
                      this.辅助配置[this.接口[index].名称].forEach((item) => {
                        item.辅助字段值 = obj ? obj[item.辅助字段名称] : "";
                      });
                    }
                    this.$set(
                      this.form,
                      this.接口[index].名称,
                      this.接口[index].默认自定义显示 != "当前用户"
                        ? this.接口[index].默认自定义显示
                        : JSON.parse(localStorage.getItem("id"))
                    );
                    this.$set(
                      this.sunForm,
                      this.接口[index].名称.replace("ID", "名称"),
                      obj ? obj.text : ""
                    );
                  }
                }
                // this.接口obj[this.接口[index].名称] = item.data.list;

                // if (objEdit && JSON.stringify(objEdit) !== "{}") {
                //   var obj = this.接口obj[this.接口[index].名称].find(
                //     (item) => {
                //       return item.value == this.form[this.接口[index].名称];
                //     }
                //   );

                //   this.$set(
                //     this.sunForm,
                //     this.接口[index].名称 + "code",
                //     obj ? obj.text : ""
                //   );
                // }
                // }
              });
              this.isLoading = false;
              console.log(this.接口obj);
              console.log(this.配置);
            })
            .catch((err) => {
              this.isLoading = false;
              console.log(err);
            });
          // var list = this.配置.filter((item) => {
          //   return item.列表显示 == 0;
          // });
          // this.列表Key =
          //   list.length == 0
          //     ? this.配置.map((item) => {
          //         return item.名称;
          //       })
          //     : list.map((item) => {
          //         return item.名称;
          //       });

          console.log(this.配置);
          // this.getList();
        } else {
          // this.isLoading = false;
          Notify({ type: "danger", message: "未配置相关数据" });
          this.$router.go(-1);
        }
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },

    getList() {
      // request({
      //   url: this.menuObj.接口名 + "_list",
      //   method: "post",
      //   data: {
      //     当前页数: this.pagin.当前页数,
      //     每页数量: this.pagin.每页数量,
      //     菜单: this.menuObj.ID,
      //   },
      // }).then((res) => {
      // });
    },
  },
};
</script>

<style  lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #f4f8fb;
  .title {
    width: 100%;
    flex-shrink: 0;
    font-size: 0.75rem;
  }
  .content {
    width: 100%;
    flex-grow: 1;
    overflow-y: hidden;
  }

  .scrolldiv {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}
</style>