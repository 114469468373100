<template>
  <div style="width: 100%; height: 100%">
    <div v-if="isLoading" class="myloading">
      <van-loading type="spinner" />
    </div>
    <div v-else class="box">
      <div class="title">
        <van-nav-bar
          :title="
            objData.munuName +
            (objData.addeditdetail == 1
              ? '新增'
              : objData.addeditdetail == 2
              ? '修改'
              : '详情')
          "
          left-text="返回"
          left-arrow
          @click-left="onClickLeft"
        >
          <template #right v-if="objData && objData.addeditdetail != 3">
            <van-button round @click="saveclick" type="info" size="small"
              >保存</van-button
            >
          </template>
        </van-nav-bar>
      </div>
      <div class="title">
        <van-divider
          :style="{
            color: '#1989fa',
            borderColor: '#1989fa',
          }"
          >{{ 包装线 }}</van-divider
        >
      </div>
      <div class="content">
        <div class="scrolldiv" ref="scrollContainer">
          <van-form ref="vanform" label-width="6em">
            <van-field
              name="工序名称"
              :disabled="
                objData &&
                (objData.addeditdetail == 3 || objData.addeditdetail == 2)
              "
              label="工序名称"
              readonly
              clickable
              v-model="form.工序名称"
              :placeholder="
                objData && objData.addeditdetail == 3 ? '' : '点击选择工序名称'
              "
              :rules="[
                {
                  required: true,
                  message: '', //message: '请选择' + item.名称
                },
              ]"
              @click="onClick('工序名称', '字典ID')"
            >
            </van-field>

            <!-- 小数 -->
            <van-field
              name="工资系数"
              :disabled="objData && objData.addeditdetail == 3"
              :placeholder="
                objData && objData.addeditdetail == 3 ? '' : '请输入工资系数'
              "
              v-model="form.工资系数"
              type="number"
              :rules="[
                {
                  required: true,
                  message: '', //message: '请输入' + item.名称
                },
              ]"
              label="工资系数"
            />
            <!-- 整数 -->
            <van-field
              name="额定人数"
              :disabled="objData && objData.addeditdetail == 3"
              :placeholder="
                objData && objData.addeditdetail == 3 ? '' : '请输入额定人数'
              "
              v-model="form.额定人数"
              type="digit"
              :rules="[
                {
                  required: true,
                  message: '', //message: '请输入' + item.名称
                },
              ]"
              label="额定人数"
            />
            <van-field
              name="延迟上班"
              :disabled="objData && objData.addeditdetail == 3"
              :placeholder="
                objData && objData.addeditdetail == 3 ? '' : '请输入延迟上班'
              "
              v-model="form.延迟上班"
              type="number"
              :rules="[
                {
                  required: true,
                  message: '', //message: '请输入' + item.名称
                },
              ]"
              label="延迟上班"
            />
            <!-- <van-field
              name="生产线名称"
              :disabled="objData && objData.addeditdetail == 3"
              clickable
              v-model="form.生产线名称"
              label="包装线"
              placeholder="包装线"
              :rules="[
                {
                  required: true,
                  message: '', //message: '请选择' + item.名称
                },
              ]"
            >
            </van-field> -->

            <van-field
              readonly
              name="员工"
              :disabled="objData && objData.addeditdetail == 3"
              clickable
              v-model="form.员工"
              label="员工"
              placeholder="员工"
              rows="1"
              autosize
              type="textarea"
              @click="inputclick('员工')"
              @clear="clear('员工')"
              :rules="[
                {
                  required: true,
                  message: '', //message: '请选择' + item.名称
                },
              ]"
            >
            </van-field>

            <van-field
              name="备注"
              :disabled="objData && objData.addeditdetail == 3"
              clickable
              rows="1"
              autosize
              type="textarea"
              v-model="form.备注"
              label="备注"
              placeholder="备注"
            >
            </van-field>

            <van-popup v-model="dateShow" position="bottom">
              <van-datetime-picker
                v-model="sunForm[formvalueTextValue]"
                type="date"
                title="请选择"
                @confirm="
                  (value) => {
                    onConfirmTime(value, 'dateShow', 'yyyy-mm-dd');
                  }
                "
                @cancel="onCancelDate('dateShow')"
              >
              </van-datetime-picker>
            </van-popup>
            <van-popup v-model="datetimeShow" position="bottom">
              <van-datetime-picker
                v-model="sunForm[formvalueTextValue]"
                type="datetime"
                title="请选择"
                @confirm="
                  (value) => {
                    onConfirmTime(value, 'datetimeShow');
                  }
                "
                @cancel="onCancelDate('datetimeShow')"
              >
              </van-datetime-picker>
            </van-popup>
            <van-popup v-model="timeShow" position="bottom">
              <van-datetime-picker
                v-model="sunForm[formvalueTextValue]"
                type="time"
                title="请选择"
                @confirm="
                  (value) => {
                    onConfirmTime(value, 'timeShow', 'hh:ss');
                  }
                "
                @cancel="onCancelDate('timeShow')"
              >
              </van-datetime-picker>
            </van-popup>
            <van-popup v-model="showPicker" position="bottom">
              <van-search
                placeholder="请输入"
                @input="depInput"
                v-model="depNameValue"
              />
              <van-picker
                value-key="text"
                :default-index="defaultIndex"
                show-toolbar
                :columns="columns"
                @confirm="
                  (value) => {
                    onConfirm(value);
                  }
                "
                @cancel="onCancel('showPicker')"
              />
            </van-popup>
            <MorePicker
              ref="selectMulPicker"
              v-model="selectType"
              placeholder="请选择"
              :columns="columns"
              label-width="100"
              @confirm="confirmMulSelect"
              :option="{ label: 'text', value: 'value' }"
            />
          </van-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { baseURL } from "@/config/net.js";
import request from "@/utils/request";
import {
  APPsettings_list,
  Classificationmanagement_list,
  posts,
  mstable,
  mstableup,
  Tname_list,
  Packagingline_list,
  Packagingprocessdefinition_list,
  Materialclassleft_list,
  user_list,
  Packagingline_add,
  Packagingline_up,
  Packagingprocessdefinition_add,
  Packagingprocessdefinition_up,
  dictfiltering,
} from "@/api/list";
import { Notify } from "vant";
export default {
  components: {},
  props: ["subAddobj"],
  data() {
    return {
      服务地址: "",

      objData: {},

      form: {
        工序名称: "",
        工资系数: "",
        额定人数: "",
        延迟上班: "",
        备注: "",
        字典ID: "",
        员工: "",
        员工ID: [],
        员工s: [],
      },
      sunForm: {
        投产日期code: "",
      },
      datetimeShow: false,
      dateShow: false,
      timeShow: false,
      formValueText: "",
      formvalueTextValue: "",
      showPicker: false,
      defaultIndex: "",
      columns: [],
      isLoading: true,
      depNameValue: "",
      接口obj: {
        员工: [],
        工序名称: [],
      },
      selectType: [],
      包装线: "",
      total: 0,
    };
  },
  watch: {
    // "$route.query": {
    //   immediate: true,
    //   handler() {
    //     this.total = 0;
    //     this.服务地址 = baseURL;
    //     if (JSON.stringify(this.$route.query) !== "{}") {
    //       console.log(this.$route.query);
    //       this.objData = this.$route.query;
    //       Packagingline_list({
    //         菜单: this.objData.menuID,
    //         查询条件: `ID='${this.objData.主表ID}'`,
    //       }).then((res) => {
    //         if (res.data.list.length != 0) {
    //           this.包装线 = res.data.list[0].生产线名称;
    //         } else {
    //           this.包装线 = "";
    //         }
    //       });
    //       dictfiltering({
    //         菜单: this.objData.menuID,
    //         主表ID: this.objData.主表ID,
    //         类别: "29",
    //       }).then((res) => {
    //         this.接口obj.工序名称 = res.data.list.map((item) => {
    //           return {
    //             ...item,
    //             text: item.字典名称,
    //             value: item.ID,
    //           };
    //         });
    //       });
    //       user_list({
    //         菜单: this.objData.menuID,
    //       }).then((res) => {
    //         this.接口obj.员工 = res.data.list.map((item) => {
    //           return {
    //             ...item,
    //             text: item.用户名,
    //             value: item.ID,
    //           };
    //         });
    //       });
    //       this.isLoading = true;
    //       if (this.objData.addeditdetail == 1) {
    //         Packagingprocessdefinition_list({
    //           菜单: this.objData.menuID,
    //           查询条件: `主表ID='${this.objData.主表ID}'`,
    //         }).then((res) => {
    //           this.total = Number(res.data.total);
    //         });
    //         this.isLoading = false;
    //       } else {
    //         this.$set(this.form, "ID", this.objData.ID);
    //         this.getData();
    //       }
    //     }
    //   },
    // },
  },
  mounted() {
    this.total = 0;
    this.服务地址 = baseURL;
    if (JSON.stringify(this.subAddobj) !== "{}") {
      this.objData = JSON.parse(JSON.stringify(this.subAddobj));
      Packagingline_list({
        菜单: this.objData.menuID,
        查询条件: `ID='${this.objData.主表ID}'`,
      }).then((res) => {
        if (res.data.list.length != 0) {
          this.包装线 = res.data.list[0].生产线名称;
        } else {
          this.包装线 = "";
        }
      });
      dictfiltering({
        菜单: this.objData.menuID,
        主表ID: this.objData.主表ID,
        类别: "29",
      }).then((res) => {
        this.接口obj.工序名称 = res.data.list.map((item) => {
          return {
            ...item,
            text: item.字典名称,
            value: item.ID,
          };
        });
      });
      user_list({
        菜单: this.objData.menuID,
      }).then((res) => {
        this.接口obj.员工 = res.data.list.map((item) => {
          return {
            ...item,
            text: item.用户名,
            value: item.ID,
          };
        });
      });
      this.isLoading = true;
      if (this.objData.addeditdetail == 1) {
        Packagingprocessdefinition_list({
          菜单: this.objData.menuID,
          查询条件: `主表ID='${this.objData.主表ID}'`,
        }).then((res) => {
          this.total = Number(res.data.total);
        });
        this.isLoading = false;
      } else {
        this.$set(this.form, "ID", this.objData.ID);
        this.getData();
      }
    }
  },
  methods: {
    depInput() {
      console.log([this.formValueText]);

      this.columns = this.接口obj[this.formValueText].filter((item) => {
        return item.text.includes(this.depNameValue);
      });
    },
    // 下拉选
    onClick(text, valueText) {
      this.depNameValue = "";
      if (this.objData && this.objData.addeditdetail == 3) return;
      this.columns = this.接口obj[text];
      this.formValueText = text;
      this.formvalueTextValue = valueText;
      var index = this.columns.findIndex((item) => {
        return item.value == this.form[valueText];
      });
      console.log(index);
      this.defaultIndex = index != -1 ? index : "";
      this.showPicker = true;
    },
    onConfirm(e) {
      if (!e) return;

      var value = e.value;
      this.form[this.formvalueTextValue] = value;
      var obj = this.columns.find((item) => {
        return item.value == value;
      });
      this.form[this.formValueText] = obj ? obj.text : "";

      this.showPicker = false;
    },
    onCancel(showText) {
      this[showText] = false;
    },
    onClickLeft() {
      // this.$router.go(-1);
      this.$emit('backclick')
    },
    flattenTree(tree) {
      tree.forEach((node) => {
        this.接口obj.包装类型.push({
          ...node,
          text: node.name,
          value: node.ID,
        });
        if (node.children) {
          this.flattenTree(node.children);
        }
      });
    },
    getData() {
      Packagingprocessdefinition_list({
        菜单: this.objData.menuID,
        查询条件: `ID='${this.form.ID}'`,
      }).then((res) => {
        var obj = res.data.list.length != 0 ? res.data.list[0] : {};
        this.$set(this.form, "工序名称", obj.工序名称);
        this.$set(this.form, "工资系数", obj.工资系数);
        this.$set(this.form, "额定人数", obj.额定人数);
        this.$set(this.form, "延迟上班", obj.延迟上班);
        this.$set(this.form, "备注", obj.备注);
        this.$set(this.form, "字典ID", obj.字典ID);
        this.$set(this.form, "员工", obj.员工);
        this.$set(
          this.form,
          "员工ID",
          obj.员工ID == "" ? [] : obj.员工ID.split(",")
        );

        var 员工 = obj.员工 == "" ? [] : obj.员工.split(",");
        this.$set(
          this.form,
          "员工s",
          this.form.员工ID == ""
            ? []
            : this.form.员工ID.map((item, index) => {
                return {
                  text:
                    员工.length != 0 ? (员工[index] ? 员工[index] : "") : "",
                  value: item,
                };
              })
        );

        this.isLoading = false;
      });
    },
    clear(text) {
      this.formValueText = text;
      if (this.formValueText == "员工") {
        this.form.员工 = "";
        this.form.员工ID = [];
        this.form.员工s = [];
      }
    },
    inputclick(text) {
      this.formValueText = text;
      this.columns = this.接口obj[text];
      this.selectType = text == "员工" ? this.form.员工ID : "";
      this.$refs.selectMulPicker && this.$refs.selectMulPicker.showPopu();
      this.$nextTick(() => {
        this.$refs.selectMulPicker.setDefaultValue(this.selectType); // 设置默认值
      });
    },
    confirmMulSelect(value, value2) {
      console.log(value, value2);
      if (this.formValueText == "员工") {
        this.form.员工s = value2;
        this.form.员工 = value2
          .map((item) => {
            return item.text;
          })
          .join(",");
        this.form.员工ID = value;
      }
    },

    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    asyncBeforeReadPic(file) {
      return new Promise((resolve, reject) => {
        if (
          file.type === "image/jpg" ||
          file.type === "image/png" ||
          file.type === "image/jpeg" ||
          file.type === "image/gif"
        ) {
          resolve();
        } else {
          reject();

          Toast.fail("请上传 jpg、png、jpeg、gif 格式图片");
        }
      });
    },
    afterRead(res, text) {
      this.getBase64(res.file).then((result) => {
        posts(
          {
            file: result,
            文件名: res.file.name,
            文件类型: res.file.name.substr(res.file.name.lastIndexOf(".") + 1),
            模块名称: this.objData.munuName,
            主表ID: "",
            节点ID: "0",
            项目ID: "",
            菜单: this.objData.menuID,
          },
          res.file.name.substr(res.file.name.lastIndexOf(".") + 1)
        ).then((response) => {
          if (response && response.file) {
            Notify({ type: "success", message: "上传成功" });
            this.form[text] = response.file;
            this.sunForm[text + "imgList"] = [
              { url: this.服务地址 + response.file },
            ];
          } else {
            Notify({ type: "danger", message: "上传失败" });
            this.form[text] = "";
            this.sunForm[text + "imgList"] = [];
          }
        });
      });
    },
    deleteCoverPic(res, text) {
      console.log(res, text);
      var index = this.sunForm[text + "imgList"].findIndex((item) => {
        return item.url == res.url;
      });
      if (index != -1) {
        this.sunForm[text + "imgList"].splice(index, 1);
      }
      Notify({ type: "danger", message: "删除成功" });
    },
    onOversize(file) {
      console.log(file);
      Notify({ type: "danger", message: "文件大小不能超过 10M" });
    },

    saveclick() {
      this.$refs.vanform
        .validate()
        .then(() => {
          this.isLoading = true;
          var obj = {
            菜单: this.objData.menuID,
            工序名称: this.form.工序名称,
            工资系数: this.form.工资系数,
            额定人数: this.form.额定人数,
            主表ID: this.objData.主表ID,
            延迟上班: this.form.延迟上班,
            备注: this.form.备注,
            字典ID: this.form.字典ID,

            员工ID:
              this.form.员工ID.length == 0 ? "" : this.form.员工ID.join(","),

            员工: this.form.员工,
          };
          if (this.objData.addeditdetail == 1) {
            obj.序号 = this.total + 1;
            Packagingprocessdefinition_add(obj)
              .then((res) => {
                // this.$router.go(-1);
                Notify({ type: "success", message: "新增成功" });
                this.isLoading = false;
                this.$emit('saveclick')
              })
              .catch(() => {
                this.isLoading = false;
              });
          } else {
            obj.ID = this.form.ID;
            Packagingprocessdefinition_up(obj)
              .then((res) => {
                // this.$router.go(-1);
                Notify({ type: "success", message: "修改成功" });
                this.isLoading = false;
                this.$emit('saveclick')
              })
              .catch(() => {
                this.isLoading = false;
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 日期
    onclickdatetime(text, showText, type) {
      if (this.objData && this.objData.addeditdetail == 3) return;
      console.log(this.sunForm);
      this.formValueText = text;
      this.formvalueTextValue = text + "code";
      if (type && type == "hh:ss") {
        this.sunForm[this.formvalueTextValue] =
          this.form[text] == "" ? "" : this.form[text];
      } else {
        // this.form[text] = this.form[text] == '' ?
        //     type == '' ? this.formatDate(new Date(), 'yyyy-mm-dd') : this.formatDate(new Date())
        //     : this.form[text]
        this.sunForm[this.formvalueTextValue] =
          this.form[text] == ""
            ? new Date()
            : // type && type == "yyyy-mm-dd"
              // ? new Date(this.formatDate(new Date(), "yyyy-mm-dd"))
              // : new Date(this.formatDate(new Date()))
              new Date(this.form[text]);
      }

      console.log(new Date());
      this.$nextTick(() => {
        this[showText] = true;
      });
    },
    onConfirmTime(value, showText, type) {
      if (type && type == "hh:ss") {
        this.form[this.formValueText] = value ? value : "";

        this.sunForm[this.formvalueTextValue] = value ? value : "";
      } else {
        this.form[this.formValueText] =
          type && type == "yyyy-mm-dd"
            ? this.formatDate(value, "yyyy-mm-dd")
            : this.formatDate(value);
        this.sunForm[this.formvalueTextValue] = value;
      }
      console.log(value);

      console.log(this.form, this.sunForm);
      this[showText] = false;
    },
    onCancelDate(showText) {
      this[showText] = false;
    },
    // 格式化时间
    formatDate(date, type) {
      date = new Date(date);
      var str = `${date.getFullYear()}-${this.format(
        date.getMonth() + 1
      )}-${this.format(date.getDate())}`;

      return type && type == "yyyy-mm-dd"
        ? str
        : `${str} ${this.format(date.getHours())}:${this.format(
            date.getMinutes()
         )}:00`;

          // ${this.format(date.getSeconds())}
    },

    format(n) {
      return n < 10 ? "0" + n : "" + n;
    },
  },
};
</script>

<style  lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #f4f8fb;
  .title {
    width: 100%;
    flex-shrink: 0;
    font-size: 0.75rem;
  }
  .content {
    width: 100%;
    flex-grow: 1;
    overflow-y: hidden;
  }

  .scrolldiv {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}
</style>