<template>
  <div style="width: 100%; padding: 0.625rem">
    <van-form ref="vanformSub" style="background-color: #fff" label-width="6em">
      <template v-for="item in 配置">
        <div :key="item.名称">
          <!-- 上传图片 -->
          <van-field
            :name="item.名称"
            :disabled="objData && objData.addeditdetail == 3"
            v-if="
              item.类型.includes('varchar') &&
              item.引用类型 != '字典' &&
              (item.名称.includes('图片') ||
                item.名称.includes('照片') ||
                item.名称.includes('图像') ||
                item.名称.includes('头像'))
            "
            v-model="form[item.名称]"
            :rules="
              item.必填字段 == 0
                ? [
                    {
                      required: true,
                      message: '请输入' + item.名称,
                    },
                  ]
                : []
            "
            :label="item.名称"
            :placeholder="
              objData && objData.addeditdetail == 3 ? '' : '请上传' + item.名称
            "
          >
            <template #input>
              <van-uploader
                accept="image/*"
                :disabled="objData && objData.addeditdetail == 3"
                :max-count="1"
                :max-size="10240 * 1024"
                @oversize="onOversize"
                v-model="sunForm[item.名称 + 'imgList']"
                :after-read="
                  (file) => {
                    afterRead(file, item.名称);
                  }
                "
                :before-read="asyncBeforeReadPic"
                @delete="
                  (file) => {
                    deleteCoverPic(file, item.名称);
                  }
                "
              >
              </van-uploader>
            </template>
          </van-field>

          <!-- 单文本 -->

          <van-field
            :name="item.名称"
            :disabled="objData && objData.addeditdetail == 3"
            v-if="
              item.类型.includes('varchar') &&
              item.引用类型 != '字典' &&
              !(
                item.名称.includes('图片') ||
                item.名称.includes('照片') ||
                item.名称.includes('图像') ||
                item.名称.includes('头像')
              )
            "
            @input="updateObj"
            v-model="form[item.名称]"
            :rules="
              item.必填字段 == 0
                ? [
                    {
                      required: true,
                      message: '', //message: '请输入' + item.名称
                    },
                  ]
                : []
            "
            :label="item.名称"
            :placeholder="
              objData && objData.addeditdetail == 3 ? '' : '请输入' + item.名称
            "
          ></van-field>
          <!-- 多文本 -->
          <van-field
            :name="item.名称"
            :disabled="objData && objData.addeditdetail == 3"
            v-if="item.类型.includes('text')"
            rows="1"
            autosize
            v-model="form[item.名称]"
            :rules="
              item.必填字段 == 0
                ? [
                    {
                      required: true,
                      message: '', //message: '请输入' + item.名称
                    },
                  ]
                : []
            "
            @input="updateObj"
            :label="item.名称"
            :placeholder="
              objData && objData.addeditdetail == 3 ? '' : '请输入' + item.名称
            "
            type="textarea"
          />
          <!-- 开关 0 1 -->
          <van-field
            @change="updateObj"
            :name="item.名称"
            :disabled="objData && objData.addeditdetail == 3"
            v-if="item.类型.includes('tinyint')"
            :rules="
              item.必填字段 == 0
                ? [
                    {
                      required: true,
                      message: '', //message: '请输入' + item.名称
                    },
                  ]
                : []
            "
            :label="item.名称"
            :placeholder="
              objData && objData.addeditdetail == 3 ? '' : '请选择' + item.名称
            "
          >
            <template #input>
              <van-switch
                :disabled="objData && objData.addeditdetail == 3"
                inactive-value="1"
                active-value="0"
                v-model="form[item.名称]"
                size="20"
              />
            </template>
          </van-field>
          <!-- 整数 -->
          <van-field
            @input="updateObj"
            :name="item.名称"
            :disabled="objData && objData.addeditdetail == 3"
            :placeholder="
              objData && objData.addeditdetail == 3 ? '' : '请输入' + item.名称
            "
            v-model="form[item.名称]"
            type="digit"
            v-if="
              item.类型.includes('int') &&
              !item.类型.includes('tinyint') &&
              !item.名称.includes('ID') &&
              item.引用类型 == ''
            "
            :rules="
              item.必填字段 == 0
                ? [
                    {
                      required: true,
                      message: '', //message: '请输入' + item.名称
                    },
                  ]
                : []
            "
            :label="item.名称"
          />
          <!-- 小数 -->
          <van-field
            @input="updateObj"
            :name="item.名称"
            :disabled="objData && objData.addeditdetail == 3"
            :placeholder="
              objData && objData.addeditdetail == 3 ? '' : '请输入' + item.名称
            "
            v-model="form[item.名称]"
            type="number"
            v-if="item.类型.includes('decimal')"
            :rules="
              item.必填字段 == 0
                ? [
                    {
                      required: true,
                      message: '', //message: '请输入' + item.名称
                    },
                  ]
                : []
            "
            :label="item.名称"
          />
          <!-- 日期年月日 -->
          <template v-if="item.类型 == 'date'">
            <van-field
              :name="item.名称"
              :disabled="objData && objData.addeditdetail == 3"
              readonly
              clickable
              v-model="form[item.名称]"
              :label="item.名称"
              :rules="
                item.必填字段 == 0
                  ? [
                      {
                        required: true,
                        message: '', //message: '请选择' + item.名称
                      },
                    ]
                  : []
              "
              :placeholder="
                objData && objData.addeditdetail == 3
                  ? ''
                  : '点击选择' + item.名称
              "
              @click="onclickdatetime(item.名称, 'dateShow', 'yyyy-mm-dd')"
            >
            </van-field>
          </template>
          <template v-if="item.类型 == 'datetime'">
            <!-- 日期年月日时分 -->
            <van-field
              :name="item.名称"
              :disabled="objData && objData.addeditdetail == 3"
              readonly
              clickable
              v-model="form[item.名称]"
              :label="item.名称"
              :placeholder="
                objData && objData.addeditdetail == 3
                  ? ''
                  : '点击选择' + item.名称
              "
              :rules="
                item.必填字段 == 0
                  ? [
                      {
                        required: true,
                        message: '', //message: '请选择' + item.名称
                      },
                    ]
                  : []
              "
              @click="onclickdatetime(item.名称, 'datetimeShow')"
            >
            </van-field>
          </template>
          <template v-if="item.类型 == 'time'">
            <!-- 日期年月日时分 -->
            <van-field
              :name="item.名称"
              :disabled="objData && objData.addeditdetail == 3"
              readonly
              clickable
              v-model="form[item.名称]"
              :label="item.名称"
              :placeholder="
                objData && objData.addeditdetail == 3
                  ? ''
                  : '点击选择' + item.名称
              "
              :rules="
                item.必填字段 == 0
                  ? [
                      {
                        required: true,
                        message: '', //message: '请选择' + item.名称
                      },
                    ]
                  : []
              "
              @click="onclickdatetime(item.名称, 'timeShow', 'hh:ss')"
            >
            </van-field>
          </template>

          <!-- 下拉单选 -->
          <template
            v-if="
              item.类型.includes('int') &&
              item.名称.includes('ID') &&
              !item.类型.includes('tinyint')
            "
          >
            <van-field
              :name="item.名称"
              :disabled="objData && objData.addeditdetail == 3"
              :label="item.名称.replace('ID', '名称')"
              readonly
              clickable
              v-model="sunForm[item.名称.replace('ID', '名称')]"
              :placeholder="
                objData && objData.addeditdetail == 3
                  ? ''
                  : '点击选择' + item.名称.replace('ID', '名称')
              "
              :rules="
                item.必填字段 == 0
                  ? [
                      {
                        required: true,
                        message: '', //message: '请选择' + item.名称
                      },
                    ]
                  : []
              "
              @click="onClick(item.名称.replace('ID', '名称'), item.名称)"
            >
            </van-field>
          </template>
          <template
            v-if="
              item.类型.includes('int') &&
              !item.类型.includes('tinyint') &&
              !item.名称.includes('ID') &&
              item.引用类型 == '引用表' &&
              item.字典表选项 != ''
            "
          >
            <van-field
              :name="item.名称"
              :disabled="objData && objData.addeditdetail == 3"
              :label="item.名称.replace('ID', '名称')"
              readonly
              clickable
              v-model="sunForm[item.名称.replace('ID', '名称')]"
              :placeholder="
                objData && objData.addeditdetail == 3
                  ? ''
                  : '点击选择' + item.名称.replace('ID', '名称')
              "
              :rules="
                item.必填字段 == 0
                  ? [
                      {
                        required: true,
                        message: '', //message: '请选择' + item.名称
                      },
                    ]
                  : []
              "
              @click="onClick(item.名称.replace('ID', '名称'), item.名称)"
            >
            </van-field>
          </template>
          <!-- <template v-if="item.引用类型 == '字典' && item.字典表选项 != ''">
            <van-field
              :name="item.名称"
              :disabled="objData && objData.addeditdetail == 3"
              :label="item.名称"
              readonly
              clickable
              v-model="sunForm[item.名称 + 'code']"
              :placeholder="
                objData && objData.addeditdetail == 3
                  ? ''
                  : '点击选择' + item.名称
              "
              :rules="
                item.必填字段 == 0
                  ? [
                      {
                        required: true,
                        message: '', //message: '请选择' + item.名称
                      },
                    ]
                  : []
              "
              @click="onClick(item.名称 + 'code', item.名称)"
            >
            </van-field>
          </template> -->
          <template
            v-if="辅助配置[item.名称] && 辅助配置[item.名称].length != 0"
          >
            <template v-for="(it2, in2) in 辅助配置[item.名称]">
              <van-field
                :key="item.名称 + in2"
                disabled
                v-model="it2.辅助字段值"
                :label="it2.辅助字段名称"
              ></van-field>
            </template>
          </template>
        </div>
      </template>
      <div class="btn-edit" v-if="objData && objData.addeditdetail != 3">
        <van-button @click="addRow" round type="info" size="small"
          >新增</van-button
        >

        <van-button
          @click="delRow"
          round
          type="warning"
          size="small"
          style="margin-left: 0.625rem"
          >删除</van-button
        >
      </div>
    </van-form>
    <van-popup v-model="showPicker" position="bottom">
      <van-search
        placeholder="请输入"
        @input="depInput"
        v-model="depNameValue"
      />
      <van-picker
        value-key="text"
        :default-index="defaultIndex"
        show-toolbar
        :columns="columns"
        @confirm="
          (value) => {
            onConfirm(value);
          }
        "
        @cancel="onCancel('showPicker')"
      />
    </van-popup>
    <van-popup v-model="dateShow" position="bottom">
      <van-datetime-picker
        v-model="sunForm[formvalueTextValue]"
        type="date"
        title="请选择"
        @confirm="
          (value) => {
            onConfirmTime(value, 'dateShow', 'yyyy-mm-dd');
          }
        "
        @cancel="onCancelDate('dateShow')"
      >
      </van-datetime-picker>
    </van-popup>
    <van-popup v-model="datetimeShow" position="bottom">
      <van-datetime-picker
        v-model="sunForm[formvalueTextValue]"
        type="datetime"
        title="请选择"
        @confirm="
          (value) => {
            onConfirmTime(value, 'datetimeShow');
          }
        "
        @cancel="onCancelDate('datetimeShow')"
      >
      </van-datetime-picker>
    </van-popup>
    <van-popup v-model="timeShow" position="bottom">
      <van-datetime-picker
        v-model="sunForm[formvalueTextValue]"
        type="time"
        title="请选择"
        @confirm="
          (value) => {
            onConfirmTime(value, 'timeShow', 'hh:ss');
          }
        "
        @cancel="onCancelDate('timeShow')"
      >
      </van-datetime-picker>
    </van-popup>
  </div>
</template>

<script>
import { baseURL } from "@/config/net.js";
import request from "@/utils/request";
import {
  APPsettings_list,
  Classificationmanagement_list,
  posts,
} from "@/api/list";
import { Notify } from "vant";
export default {
  name: "Subaddeditdetail",
  props: ["subobj"],
  data() {
    return {
      服务地址: "",
      配置: [],
      列表Key: [],
      objData: {},
      接口: [],
      接口obj: {},
      form: {},
      sunForm: {},
      datetimeShow: false,
      dateShow: false,
      formValueText: "",
      formvalueTextValue: "",
      showPicker: false,
      defaultIndex: "",
      columns: [],
      isLoading: true,
      辅助配置: {},
      timeShow: false,
      depNameValue: false,
    };
  },
  watch: {
    subobj: {
      immediate: true,
      handler() {
        this.服务地址 = baseURL;
        if (JSON.stringify(this.subobj) !== "{}") {
          this.objData = Object.assign({}, this.subobj.objData);
          this.form = Object.assign({}, this.subobj.item);
          this.sunForm = Object.assign({}, this.subobj.sunForm);
          this.接口obj = Object.assign({}, this.subobj.接口obj);
          this.接口 = Object.assign([], this.subobj.接口);
          this.辅助配置 = Object.assign([], this.subobj.辅助配置);
          this.isLoading = true;
          if (this.objData.addeditdetail == 1) {
            this.getKey(this.form);
          } else {
            this.$set(this.form, "ID", this.objData.ID);
            this.getKey(this.form);
          }
        }
      },
    },
  },
  mounted() {
    this.服务地址 = baseURL;
    // this.$router.go(-1);
    // 名称: item.名称,
    // 类型: item.类型,
    // 允许空: item.允许空,
    // 必填字段: item.必填字段,
    // 列表显示: item.列表显示,
    // 引用类型: item.引用类型,
    // 字典Classificationmanagement_list查询条件: "`类别`='8'"
    // 引用表
    // 选项
    // 字典表选项: item.字典表选项,
  },
  methods: {
    updateObj() {
      var obj = {};
      this.配置.forEach((item) => {
        obj = {
          ...obj,
          [item.名称]: this.form[item.名称],
        };
      });
      if (this.form.ID) obj.ID = this.form.ID;
      this.$emit("updateObj", obj);
    },
    addRow() {
      this.$refs.vanformSub
        .validate()
        .then(() => {
          var obj = {};
          this.配置.forEach((item) => {
            obj = {
              ...obj,
              [item.名称]: this.form[item.名称],
            };
          });
          if (this.form.ID) obj.ID = this.form.ID;
          this.$emit("addRow", obj);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    delRow() {
      this.$emit("delRow", this.form);
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    asyncBeforeReadPic(file) {
      return new Promise((resolve, reject) => {
        if (
          file.type === "image/jpg" ||
          file.type === "image/png" ||
          file.type === "image/jpeg" ||
          file.type === "image/gif"
        ) {
          resolve();
        } else {
          reject();

          Toast.fail("请上传 jpg、png、jpeg、gif 格式图片");
        }
      });
    },
    afterRead(res, text) {
      this.getBase64(res.file).then((result) => {
        posts(
          {
            file: result,
            文件名: res.file.name,
            文件类型: res.file.name.substr(res.file.name.lastIndexOf(".") + 1),
            模块名称: this.objData.munuName,
            主表ID: "",
            节点ID: "0",
            项目ID: "",
            菜单: this.objData.menuID,
          },
          res.file.name.substr(res.file.name.lastIndexOf(".") + 1)
        ).then((response) => {
          if (response && response.file) {
            Notify({ type: "success", message: "上传成功" });
            this.form[text] = response.file;
            this.sunForm[text + "imgList"] = [
              { url: this.服务地址 + response.file },
            ];
          } else {
            Notify({ type: "danger", message: "上传失败" });
            this.form[text] = "";
            this.sunForm[text + "imgList"] = [];
          }
          this.updateObj();
        });
      });
    },
    deleteCoverPic(res, text) {
      console.log(res, text);
      var index = this.sunForm[text + "imgList"].findIndex((item) => {
        return item.url == res.url;
      });
      if (index != -1) {
        this.sunForm[text + "imgList"].splice(index, 1);
      }
      Notify({ type: "danger", message: "删除成功" });
    },
    onOversize(file) {
      console.log(file);
      Notify({ type: "danger", message: "文件大小不能超过 10M" });
    },
    inputclick(val, text) {
      // this.$set(this.form,text,val)
      this.updateObj();
    },
    saveclick() {
      this.$refs.vanform
        .validate()
        .then(() => {
          this.isLoading = true;
          var obj = {};
          this.配置.forEach((item) => {
            obj = {
              ...obj,
              [item.名称]: this.form[item.名称],
            };
          });
          if (this.objData.addeditdetail == 1) {
            request({
              url: this.objData.munu接口名 + "_add",
              method: "post",
              data: obj,
            })
              .then((res) => {
                this.$router.go(-1);
                Notify({ type: "success", message: "新增成功" });
                this.isLoading = false;
              })
              .catch(() => {
                this.isLoading = false;
              });
          } else {
            obj.ID = this.form.ID;
            request({
              url: this.objData.munu接口名 + "_up",
              method: "post",
              data: obj,
            })
              .then((res) => {
                this.$router.go(-1);
                Notify({ type: "success", message: "修改成功" });
                this.isLoading = false;
              })
              .catch(() => {
                this.isLoading = false;
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 下拉选
    depInput() {
      this.columns = this.接口obj[this.formvalueTextValue].filter((item) => {
        return item.text.includes(this.depNameValue);
      });
    },
    onClick(text, valueText) {
      this.depNameValue = "";
      if (this.objData && this.objData.addeditdetail == 3) return;
      this.columns = this.接口obj[valueText];
      this.formValueText = text;
      this.formvalueTextValue = valueText;
      var index = this.columns.findIndex((item) => {
        return item.value == this.form[valueText];
      });
      this.defaultIndex = index != -1 ? index : "";
      this.showPicker = true;
    },
    onConfirm(e) {
      if (!e) return;
      var value = e.value;
      this.form[this.formvalueTextValue] = value;
      var obj = this.columns.find((item) => {
        return item.value == value;
      });
      this.sunForm[this.formValueText] = obj ? obj.text : "";
      if (
        this.辅助配置[this.formvalueTextValue] &&
        this.辅助配置[this.formvalueTextValue].length != 0
      ) {
        this.辅助配置[this.formvalueTextValue].forEach((item) => {
          item.辅助字段值 = obj ? obj[item.辅助字段名称] : "";
        });
      }
      this.showPicker = false;
      this.updateObj();
    },
    onCancel(showText) {
      this[showText] = false;
    },
    // 日期
    onclickdatetime(text, showText, type) {
      if (this.objData && this.objData.addeditdetail == 3) return;
      console.log(this.sunForm);
      this.formValueText = text;
      this.formvalueTextValue = text + "code";
      if (type && type == "hh:ss") {
        this.sunForm[this.formvalueTextValue] =
          this.form[text] == "" ? "" : this.form[text];
      } else {
        // this.form[text] = this.form[text] == '' ?
        //     type == '' ? this.formatDate(new Date(), 'yyyy-mm-dd') : this.formatDate(new Date())
        //     : this.form[text]
        this.sunForm[this.formvalueTextValue] =
          this.form[text] == ""
            ? new Date()
            : // type && type == "yyyy-mm-dd"
              // ? new Date(this.formatDate(new Date(), "yyyy-mm-dd"))
              // : new Date(this.formatDate(new Date()))
              new Date(this.form[text]);
      }
      console.log(new Date());
      this.$nextTick(() => {
        this[showText] = true;
      });
    },
    onConfirmTime(value, showText, type) {
      if (type && type == "hh:ss") {
        this.form[this.formValueText] = value ? value : "";

        this.sunForm[this.formvalueTextValue] = value ? value : "";
      } else {
        this.form[this.formValueText] =
          type && type == "yyyy-mm-dd"
            ? this.formatDate(value, "yyyy-mm-dd")
            : this.formatDate(value);
        this.sunForm[this.formvalueTextValue] = value;
        console.log(this.form, this.sunForm);
      }
      this[showText] = false;
      this.updateObj();
    },
    onCancelDate(showText) {
      this[showText] = false;
    },
    // 格式化时间
    formatDate(date, type) {
      date = new Date(date);
      var str = `${date.getFullYear()}-${this.format(
        date.getMonth() + 1
      )}-${this.format(date.getDate())}`;

      return type && type == "yyyy-mm-dd"
        ? str
        : `${str} ${this.format(date.getHours())}:${this.format(
            date.getMinutes()
          )}:00`;

          // ${this.format(date.getSeconds())}
    },

    format(n) {
      return n < 10 ? "0" + n : "" + n;
    },
    getKey(objEdit) {
      let that = this;
      console.log(this.form);
      that.配置 = Object.assign([], that.subobj.配置);

      if (objEdit && JSON.stringify(objEdit) !== "{}") {
        that.配置.forEach((item) => {
          if (
            item.名称.includes("图片") ||
            item.名称.includes("照片") ||
            item.名称.includes("图像") ||
            item.名称.includes("头像")
          ) {
            that.$set(that.sunForm, item.名称 + "imgList", [
              item.名称 == ""
                ? undefined
                : { url: that.服务地址 + objEdit[item.名称] },
            ]);
          }
        });

        that.接口.forEach((item, index) => {
          var obj = that.接口obj[item.名称].find((ii) => {
            return ii.value == objEdit[item.名称];
          });

          if (
            that.辅助配置[item.名称] &&
            that.辅助配置[item.名称].length != 0
          ) {
            that.辅助配置[item.名称].forEach((ii) => {
              ii.辅助字段值 =
                that.objData.addeditdetail == 1
                  ? obj
                    ? obj[ii.辅助字段名称]
                    : ""
                  : objEdit[ii.辅助字段名称]
                  ? objEdit[ii.辅助字段名称]
                  : "";
            });
          }
          that.$set(
            that.sunForm,
            item.名称.replace("ID", "名称"),
            obj ? obj.text : ""
          );
          if (that.objData.addeditdetail == 1) {
            if (item.默认自定义显示 && item.默认自定义显示 != "") {
              var obj1 = that.接口obj[item.名称].find((ii) => {
                return (
                  ii.value ==
                  (item.默认自定义显示 != "当前用户"
                    ? item.默认自定义显示
                    : JSON.parse(localStorage.getItem("id")))
                );
              });
              if (
                that.辅助配置[item.名称] &&
                that.辅助配置[item.名称].length != 0
              ) {
                that.辅助配置[item.名称].forEach((item) => {
                  item.辅助字段值 = obj1 ? obj1[item.辅助字段名称] : "";
                });
              }
              that.$set(
                that.form,
                item.名称,
                item.默认自定义显示 != "当前用户"
                  ? item.默认自定义显示
                  : JSON.parse(localStorage.getItem("id"))
              );
              that.$set(
                that.sunForm,
                item.名称.replace("ID", "名称"),
                obj1 ? obj1.text : ""
              );
            }
          }
          //   if (item.字典表选项 == "/material_list") {
          //     var obj = that.接口obj[item.名称].find((ii) => {
          //       return ii.value == objEdit[item.名称];
          //     });
          //     that.$set(
          //       that.sunForm,
          //       item.名称.replace("ID", "名称"),
          //       obj ? obj.text : ""
          //     );
          //   } else if (item.名称 == "客户ID") {
          //     var obj = that.接口obj[item.名称].find((ii) => {
          //       return ii.value == objEdit[item.名称];
          //     });
          //     that.$set(
          //       that.sunForm,
          //       item.名称.replace("ID", "名称"),
          //       obj ? obj.text : ""
          //     );
          //   } else if (item.引用类型 == "字典") {
          //     var obj = that.接口obj[item.名称].find((ii) => {
          //       return ii.value == objEdit[item.名称];
          //     });
          //     that.$set(that.sunForm, item.名称 + "code", obj ? obj.text : "");
          //   }
          //   else {
          //     this.接口obj[item.名称] = item.data.list;
          //   }
        });
      }
      console.log(222, this.form, this.sunForm);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style  lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #f4f8fb;
  .title {
    width: 100%;
    flex-shrink: 0;
    font-size: 0.75rem;
  }
  .content {
    width: 100%;
    flex-grow: 1;
    overflow-y: hidden;
  }

  .scrolldiv {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}
.btn-edit {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #eee;
  padding: 0.625rem;
}
</style>