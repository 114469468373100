<template>
  <div style="width: 100%; height: 100%">
    <div v-if="isLoading" class="myloading">
      <van-loading type="spinner" />
    </div>
    <div v-else class="box">
      <div class="title">
        <van-nav-bar
          :title="menuObj.菜单名称"
          left-text="返回"
          :right-text="JSON.parse(menuObj.新增) ? '新增' : ''"
          left-arrow
          @click-left="onClickLeft"
        >
          <!-- @click-right="onClickRight" -->
          <template #right v-if="JSON.parse(menuObj.新增)">
            <van-button @click="addClick" round type="info" size="small"
              >新增</van-button
            >
          </template>
        </van-nav-bar>
      </div>
      <div class="title">
        <van-cell is-link @click="showSearchPopup">
          搜索 {{ searchStr }}
        </van-cell>
      </div>
      <div class="content">
        <van-pull-refresh
          v-model="refreshing"
          success-text="刷新成功"
          @refresh="onRefresh"
        >
          <!-- <van-list
            :offset="100"
            v-model="loadingAdd"
            :finished="finished"
            loading-text="加载中..."
            finished-text="没有更多了"
            @load="onLoad"
            :immediate-check="false"
          > -->
          <div class="scrolldiv" ref="scrollContainer">
            <template v-if="tableList.length != 0">
              <div class="item" v-for="item in tableList" :key="item.ID">
                <div class="item-warrper">
                  <template v-for="ii in 列表Key">
                    <div :key="item.ID + ii">
                      <!-- <template v-else-if="ii == '调整客户' && menuObj.ID == 570">
                      <div class="text" :key="ii">
                        {{ ii }}：
                        <template v-for="tt in item[ii]">
                          <span :key="tt.ID">{{ tt.公司名称 }},</span>
                        </template>
                      </div>
                    </template> -->
                      <template
                        v-if="
                          ii.includes('图片') ||
                          ii.includes('照片') ||
                          ii.includes('图像') ||
                          ii.includes('头像')
                        "
                      >
                        <div class="text text2">
                          {{ ii }}：

                          <img
                            :src="item[ii] != '' ? 服务地址 + item[ii] : ''"
                            alt=""
                            srcset=""
                          />
                          <!-- //require('@/assets/yes.png') -->
                        </div>
                      </template>
                      <template v-else>
                        <div class="text" :key="ii">
                          {{
                            ii.includes("ID") ? ii.replace("ID", "名称") : ii
                          }}：{{
                            item[
                              ii.includes("ID") ? ii.replace("ID", "名称") : ii
                            ]
                          }}
                          <!-- ii.includes("ID")
                              ? ii.replace("ID", "名称") == "客户名称"
                                ? '公司名称'
                                : ii.replace("ID", "名称")
                              : ii -->
                        </div>
                      </template>
                      <template v-if="辅助配置[ii] && 辅助配置[ii].length != 0">
                        <template v-for="(it2, in2) in 辅助配置[ii]">
                          <div class="text" :key="ii + '辅助配置' + in2">
                            {{ it2.辅助字段名称 }}：{{ item[it2.辅助字段名称] }}
                          </div>
                        </template>
                      </template>
                    </div>
                  </template>
                  <div
                    v-if="item.审批状态"
                    class="absolute"
                    :style="{
                      'background-color':
                        item.审批状态 == '已通过'
                          ? '#44d7b6'
                          : item.审批状态 == '未开始'
                          ? '#e4e7ed'
                          : item.审批状态 == '进行中'
                          ? '#e6a23c'
                          : item.审批状态 == '已驳回'
                          ? '#f56c6c'
                          : '',
                    }"
                  >
                    {{ item.审批状态 }}
                  </div>
                  <div class="text display border">
                    <div>录入用户：{{ item.录入用户 }}</div>
                    <div>录入时间：{{ item.录入时间 }}</div>
                  </div>
                  <div class="btn-edit">
                    <van-button
                      @click="editClick(item)"
                      v-if="JSON.parse(menuObj.修改)"
                      round
                      type="info"
                      size="small"
                      >修改</van-button
                    >
                    <van-button
                      @click="detailClick(item)"
                      round
                      type="default"
                      size="small"
                      style="margin-left: 0.625rem"
                      >详情</van-button
                    >
                    <van-button
                      @click="remooveClick(item)"
                      v-if="JSON.parse(menuObj.删除)"
                      round
                      type="warning"
                      size="small"
                      style="margin-left: 0.625rem"
                      >删除</van-button
                    >
                  </div>
                  <!-- 
                    <div class="text size">YYYYYY</div>
                    <div class="text">生产线名称：1</div>
                    <div class="text">开始时间：2024-05-05 13:21</div>
                    <div class="text border">结束时间：2024-05-05 13:21</div>
                    <div class="text display">
                        <div>录入用户：张三</div>
                        <div>录入时间：2024-05-05 13:21</div>
                    </div>
                    状态四选一
                    <div class="absolute" style="background-color: #e4e7ed">
                        未开始
                    </div>
                    <div class="absolute" style="background-color: #e6a23c">
                        进行中
                    </div>
                    <div class="absolute" style="background-color: #f56c6c">
                        已驳回
                    </div>
                    <div class="absolute" style="background-color: #44d7b6">
                        已通过
                    </div>
                  -->
                </div>
              </div>
            </template>
            <template v-else>
              <div class="noData">
                <van-image :src="require('@/assets/data_empty.png')" />
              </div>
            </template>
          </div>
          <!-- </van-list> -->
        </van-pull-refresh>
      </div>
      <div class="title">
        <van-pagination
          v-model="pagin.当前页数"
          :total-items="pagin.total"
          :items-per-page="pagin.每页数量"
          :show-page-size="3"
          force-ellipses
          @change="paginchange"
        />
      </div>
    </div>
    <van-dialog
      v-model="deleteShow"
      title="删除"
      show-cancel-button
      @confirm="onDelConfirm"
      @cancel="onDelCancel"
    >
      是否要进行删除操作？
    </van-dialog>
    <van-popup position="top" v-model="searchshow">
      <div style="padding: 0.625rem">
        <van-form ref="vanform" label-width="6em">
          <template v-for="(item, index) in 模糊查询">
            <van-field
              v-if="item.my类型 === 'date'"
              clearable
              :key="index"
              v-model="item[item.my名称]"
              :label="item.my名称"
              :placeholder="'请选择' + item.my名称"
              readonly
              @click="onclickdatetime(item.my名称, 'dateShow', 'yyyy-mm-dd')"
            ></van-field>
            <van-field
              v-else-if="item.my类型 === 'datetime'"
              clearable
              :key="index"
              v-model="item[item.my名称]"
              :label="item.my名称"
              :placeholder="'请选择' + item.my名称"
              readonly
              @click="onclickdatetime(item.my名称, 'dateShow')"
            ></van-field>
            <van-field
              v-else
              clearable
              :key="index"
              v-model="item[item.my名称]"
              :label="item.my名称"
              :placeholder="'请输入' + item.my名称"
            ></van-field>
          </template>
        </van-form>
        <div style="display: flex">
          <van-button
            type="default"
            @click="cancel"
            style="width: 50%; margin: 0 0.625rem"
            >重置</van-button
          >
          <van-button
            type="info"
            @click="searchclick"
            style="width: 50%; margin: 0 0.625rem"
            >搜索</van-button
          >
        </div>
      </div>
    </van-popup>
    <van-popup v-model="dateShow" position="bottom">
      <van-datetime-picker
        v-model="dateValue"
        :type="type"
        title="请选择"
        @confirm="
          (value) => {
            onConfirmTime(value, 'dateShow');
          }
        "
        @cancel="onCancelDate('dateShow')"
      >
      </van-datetime-picker>
    </van-popup>
  </div>
</template>

<script>
import { baseURL } from "@/config/net.js";
import request from "@/utils/request";
import { APPsettings_list, Tname_list } from "@/api/list";
import { Notify } from "vant";
export default {
  data() {
    return {
      服务地址: "",
      menuObj: {
        新增: false,
        修改: false,
        删除: false,
      },
      tableList: [],
      loadingAdd: false,
      finished: false,
      refreshing: false,
      pagin: {
        total: 0,
        当前页数: 1,
        每页数量: 15,
      },
      type: "date",
      dateValue: "",
      dateShow: false,
      配置: [],
      列表Key: [],
      loadingview: false,
      deleteShow: false,
      objData: {},
      isLoading: true,
      附表ID: "",
      附表接口: "",

      辅助配置: {},

      模糊查询: [],
      searchshow: false,
      searchStr: "",
    };
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler() {
        console.log(this.$route.query);
        this.服务地址 = baseURL;
        this.模糊查询 = [];
        this.searchStr = "";
        if (JSON.stringify(this.$route.query) !== "{}") {
          console.log(this.$route.query);
          this.menuObj = this.$route.query;
          console.log(this.menuObj);
          Tname_list({ 查询条件: "a.ID='" + this.menuObj.ID + "'" }).then(
            (res) => {
              if (res.data.list.length != 0) {
                var obj = res.data.list[0];
                this.附表ID = obj.附表ID;
                this.附表接口 = obj.附表接口;
              } else {
                this.附表ID = "";
                this.附表接口 = "";
              }
            }
          );
          this.辅助配置 = {};
          this.pagin.当前页数 = 1;
          this.pagin.每页数量 = 15;
          this.pagin.total = 0;
          this.tableList = [];
          this.finished = false;
          this.loadingAdd = false;
          this.refreshing = false;
          this.loadingview = true;
          this.isLoading = true;
          this.getKey();
        }
      },
    },
  },
  mounted() {
    this.服务地址 = baseURL;
  },
  methods: {
    // 日期
    onclickdatetime(text, showText, type) {
      this.lablelame = text;
      var obj = this.模糊查询.find((item) => {
        return item.my名称 == text;
      });
      this.type = type && type == "yyyy-mm-dd" ? "date" : "datetime";
      this.dateValue = obj
        ? obj[text] == ""
          ? new Date()
          : new Date(obj[text])
        : new Date();

      this.$nextTick(() => {
        this[showText] = true;
      });
    },
    onConfirmTime(value, showText, type) {
      var index = this.模糊查询.findIndex((item) => {
        return item.my名称 == this.lablelame;
      });
      if (index != -1) {
        var obj = this.模糊查询[index];
        this.模糊查询[index][obj.my名称] =
          this.type == "date"
            ? this.formatDate(value, "yyyy-mm-dd")
            : this.formatDate(value);
      }

      this.dateValue = value;

      this[showText] = false;
    },
    onCancelDate(showText) {
      this[showText] = false;
    },
    // 格式化时间
    formatDate(date, type) {
      date = new Date(date);
      var str = `${date.getFullYear()}-${this.format(
        date.getMonth() + 1
      )}-${this.format(date.getDate())}`;

      return type && type == "yyyy-mm-dd"
        ? str
        : `${str} ${this.format(date.getHours())}:${this.format(
            date.getMinutes()
          )}:00`;

      // ${this.format(date.getSeconds())}
    },

    format(n) {
      return n < 10 ? "0" + n : "" + n;
    },
    returnStr() {
      var obj = {};
      this.模糊查询.forEach((item) => {
        obj[item.my名称] = item[item.my名称];
      });
      console.log(obj)
      this.searchStr = "";
      var str = "",str2='';
      for (var key in obj) {
        if (obj[key] && obj[key] != "") {
          str = str + obj[key] + ",";
          str2 = str2 + key + "='" + obj[key] + "',";
        }
      }
      this.searchStr =
        str.substring(0, str.length - 1) == ""
          ? ""
          : str.substring(0, str.length - 1);
      return str2;
    },
    searchclick() {
      this.returnStr();
      // this.searchStr = "";
      // var str = "";
      // for (var key in this.模糊查询) {
      //   if (this.模糊查询[key] && this.模糊查询[key] != "") {
      //     str = str + this.模糊查询[key] + ",";
      //   }
      // }
      // this.searchStr =
      //   str.substring(0, str.length - 1) == ""
      //     ? ""
      //     : str.substring(0, str.length - 1);
      this.searchshow = false;
      this.pagin.当前页数 = 1;
      this.pagin.每页数量 = 15;
      this.pagin.total = 0;
      this.tableList = [];
      this.getList();
    },
    cancel() {
      // for (var key in this.模糊查询) {
      //   this.$set(this.模糊查询, key, "");
      // }
      this.模糊查询.forEach(item =>{
        item[item.my名称] = ''
      })
      this.searchStr = "";
      this.searchshow = false;
      this.pagin.当前页数 = 1;
      this.pagin.每页数量 = 15;
      this.pagin.total = 0;
      this.tableList = [];
      this.getList();
    },
    showSearchPopup() {
      this.searchshow = true;
    },
    addClick() {
      this.$router.push({
        path: "/app/addeditdetail",
        query: {
          addeditdetail: 1,
          menuID: this.menuObj.ID,
          munuName: this.menuObj.菜单名称,
          munuSubName: this.menuObj.关联数据,
          munu接口名: this.menuObj.接口名,
          menusubID: this.附表ID == "" ? undefined : this.附表ID,
        },
      });
    },
    editClick(item) {
      this.objData = item;
      this.$router.push({
        path: "/app/addeditdetail",
        query: {
          addeditdetail: 2,
          menuID: this.menuObj.ID,
          munuName: this.menuObj.菜单名称,
          munuSubName: this.menuObj.关联数据,
          munu接口名: this.menuObj.接口名,
          menusubID: this.附表ID == "" ? undefined : this.附表ID,
          ID: item.ID,
        },
      });
    },
    detailClick(item) {
      this.objData = item;
      this.$router.push({
        path: "/app/addeditdetail",
        query: {
          addeditdetail: 3,
          menuID: this.menuObj.ID,
          munuName: this.menuObj.菜单名称,
          munuSubName: this.menuObj.关联数据,
          munu接口名: this.menuObj.接口名,
          menusubID: this.附表ID == "" ? undefined : this.附表ID,
          ID: item.ID,
        },
      });
    },

    remooveClick(item) {
      this.objData = item;
      this.deleteShow = true;
    },
    onDelConfirm() {
      request({
        url: this.menuObj.接口名 + "_del",
        method: "post",
        data: {
          菜单: this.menuObj.ID,
          ID: this.objData.ID,
        },
      }).then(() => {
        this.pagin.当前页数 = 1;
        this.pagin.每页数量 = 15;
        this.pagin.total = 0;
        this.refreshing = false;
        this.finished = false;
        this.isLoading = true;
        setTimeout(() => {
          this.getList(2);
        }, 500);
        Notify({ type: "success", message: "删除成功" });
        this.deleteShow = false;
      });
    },
    onDelCancel() {
      this.deleteShow = false;
    },
    paginchange(val) {
      this.isLoading = true;
      console.log(val);
      this.$refs.scrollContainer.scrollTop = 0;
      this.pagin.当前页数 = val;
      this.getList();
    },
    getKey() {
      APPsettings_list({
        菜单: this.menuObj.ID,
        查询条件: `模板ID='${this.menuObj.ID}'`,
      }).then((res) => {
        if (res.data.list.length != 0) {
          this.配置 =
            res.data.list[0].配置 == ""
              ? []
              : JSON.parse(res.data.list[0].配置).filter((item) => {
                  return (
                    item.名称 != "录入用户" &&
                    item.名称 != "录入时间" &&
                    item.名称 != "审批状态"
                  );
                });
          var list = this.配置.filter((item) => {
            return item.列表显示 == 0;
          });
          this.列表Key =
            list.length == 0
              ? this.配置.map((item) => {
                  return item.名称;
                })
              : list.map((item) => {
                  return item.名称;
                });
          console.log(this.列表Key, this.配置);
          this.列表Key.forEach((item) => {
            // this.$set(this.模糊查询, item.replace("ID", "名称"), "");
            var obj = this.配置.find((ii) => {
              return ii.名称 == item;
            });
            console.log(obj);
            this.模糊查询.push({
              my名称: item.replace("ID", "名称"),
              [item.replace("ID", "名称")]: "",
              my类型: obj ? obj.类型 : "text",
            });
          });
          console.log(this.模糊查询);

          this.配置.forEach((item) => {
            if (item.辅助字段 != "" && item.列表显示 == 0) {
              var arrlist = item.辅助字段.split(",").map((ii) => {
                return {
                  辅助字段名称: ii,
                  辅助字段值: "",
                };
              });
              this.$set(this.辅助配置, [item.名称], arrlist);
            }
          });
          console.log(this.配置);

          console.log(this.列表Key);
          //   this.getList(1);
          this.getList();
        } else {
          Notify({ type: "danger", message: "未配置相关数据" });
          //   this.isLoading = false;
          this.$router.go(-1);
        }
      });
    },
    onClickLeft() {
      this.$router.push("/app/home");
    },
    // onClickRight() {
    //   debugger;
    // },
    // 下拉刷新
    onRefresh() {
      this.pagin.当前页数 = 1;
      this.pagin.每页数量 = 15;
      this.pagin.total = 0;
      //   this.tableList = [];
      this.refreshing = true;
      this.finished = false;
      setTimeout(() => {
        // this.tableList = [];
        this.getList(2);
      }, 500);
    },
    // // 上拉加载
    // onLoad() {
    //   if (this.refreshing) return;
    //   setTimeout(() => {
    //     this.getList(1);
    //   }, 500);
    // },
    getList(number) {
      if (number == 2) {
        this.tableList = [];
        number = 1;
      }
      var str = this.returnStr();
      // var str = "";
      // for (var key in this.模糊查询) {
      //   if (this.模糊查询[key] && this.模糊查询[key] != "") {
      //     str = str + key + "='" + this.模糊查询[key] + "',";
      //   }
      // }
      request({
        url: this.menuObj.接口名 + "_list",
        method: "post",
        data: {
          当前页数: this.pagin.当前页数,
          每页数量: this.pagin.每页数量,
          菜单: this.menuObj.ID,
          模糊查询:
            str.substring(0, str.length - 1) == ""
              ? undefined
              : str.substring(0, str.length - 1),
        },
      })
        .then((res) => {
          console.log(res);

          this.pagin.total = res.data.Totalrecords;
          this.refreshing = false;
          res.data.list.forEach((item) => {
            if (
              item.调整客户 &&
              item.调整客户 != "" &&
              this.menuObj.ID == 570
            ) {
              item.调整客户 = JSON.parse(item.调整客户);
            }
          });
          // if (number == 1) {
          //   this.loadingAdd = false;
          //   this.tableList = this.tableList.concat(res.data.list);

          //   this.pagin.当前页数 += 1;
          //   if (this.tableList.length >= this.pagin.total) {
          //     this.finished = true;
          //   }
          // } else
          if (number == 2) {
            this.loadingAdd = false;
            this.finished = false;
            this.tableList = res.data.list;
          } else {
            this.tableList = res.data.list;
          }
          this.loadingview = false;
          this.isLoading = false;
        })
        .catch(() => {
          this.loadingAdd = false;
          this.loadingview = false;
          this.isLoading = false;
        });
    },
  },
};
</script>

<style  lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #f4f8fb;
  .title {
    width: 100%;
    flex-shrink: 0;
    font-size: 0.75rem;
  }
  .content {
    width: 100%;
    flex-grow: 1;
    overflow-y: hidden;
  }
  .van-pull-refresh {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .scrolldiv {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}

.item {
  padding: 10px;

  /* border-bottom: 1px solid #ccc; */
}

.item-warrper {
  padding: 10px;
  width: 100%;
  background-color: #fff;
  border-radius: 0.625rem;
  position: relative;
  overflow: hidden;
}

.absolute {
  position: absolute;
  top: 0;
  right: 0;
  width: 2.875rem;
  height: 1.625rem;
  font-size: 0.75rem;
  color: #fff;

  border-radius: 0 0 0 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  padding-bottom: 0.625rem;
  font-size: 0.75rem;
  color: #000;
  overflow: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.text2 {
  display: flex;
  //   flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  img {
    width: 6.25rem;
    // height: 3.75rem;
  }
}

.size {
  font-size: 0.875rem;
  color: #6d7278;
}

.border {
  border-top: 1px solid #6d7278;
}

.display {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.display div {
  font-size: 0.625rem;
  color: #6d7278;
  padding-top: 0.625rem;
}

.loading {
  text-align: center;
  padding: 0.625rem 0;
}
.btn-edit {
  display: flex;
  justify-content: flex-end;
}
</style>