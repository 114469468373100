<template>
  <div style="width: 100%; height: 100%">
    <template v-if="isAdd == ''">
      <div v-if="isLoading" class="myloading">
        <van-loading type="spinner" />
      </div>
      <div v-else class="box">
        <div class="title">
          <van-nav-bar
            :title="menuObj.菜单名称"
            left-text="返回"
            right-text=""
            left-arrow
            @click-left="onClickLeft"
          >
          </van-nav-bar>
        </div>
        <div class="title">
          <van-cell is-link @click="showSearchPopup">
            搜索 {{ searchStr }}
          </van-cell>
        </div>
        <div
          class="title"
          style="
            display: flex;
            justify-content: space-around;
            padding: 0.625rem 0;
          "
        >
          <van-button
            :disabled="tableList.length == 0 || 条件替换.查询产线ID == ''"
            plain
            hairline
            type="primary"
            size="small"
            >领料申请单</van-button
          >
          <van-button
            :disabled="tableList.length == 0"
            plain
            hairline
            type="info"
            size="small"
            @click="fasong"
            >发送通知</van-button
          >
        </div>
        <div class="content">
          <van-pull-refresh
            v-model="refreshing"
            success-text="刷新成功"
            @refresh="onRefresh"
          >
            <!-- <van-list
            :offset="100"
            v-model="loadingAdd"
            :finished="finished"
            loading-text="加载中..."
            finished-text="没有更多了"
            @load="onLoad"
            :immediate-check="false"
          > -->
            <div class="scrolldiv" ref="scrollContainer">
              <template v-if="tableList.length != 0">
                <div
                  class="item"
                  v-for="(item, index) in tableList"
                  :key="item.ID"
                >
                  <div class="item-warrper">
                    <!-- index + 1 -->
                    <!-- <div class="text">序号：{{ item.序号 }}</div> -->
                    <div class="text">生产日期：{{ item.生产日期 }}</div>
                    <div class="text">生产线名称：{{ item.生产线名称 }}</div>
                    <div class="text">产品名称：{{ item.名称 }}</div>
                    <div class="text">计划数量：{{ item.计划数量 }}</div>
                    <!-- <div
                  v-if="item.审批状态"
                  class="absolute"
                  :style="{
                    'background-color':
                      item.审批状态 == '已通过'
                        ? '#44d7b6'
                        : item.审批状态 == '未开始'
                        ? '#e4e7ed'
                        : item.审批状态 == '进行中'
                        ? '#e6a23c'
                        : item.审批状态 == '已驳回'
                        ? '#f56c6c'
                        : '',
                  }"
                >
                  {{ item.审批状态 }}
                </div>
                -->
                    <div class="text border">
                      <van-form ref="vanform" label-width="6em">
                        <van-field
                          readonly
                          clickable
                          clearable
                          v-model="item.计划开始"
                          label="计划开始"
                          placeholder="点击选择"
                          @click="
                            onclickdatetime(
                              '计划开始',
                              'datetimeShow',
                              undefined,
                              index
                            )
                          "
                        >
                        </van-field>
                        <van-field
                          readonly
                          clearable
                          clickable
                          v-model="item.计划结束"
                          label="计划结束"
                          placeholder="点击选择"
                          @click="
                            onclickdatetime(
                              '计划结束',
                              'datetimeShow',
                              undefined,
                              index
                            )
                          "
                        >
                        </van-field>
                      </van-form>
                      <div class="text">时长：{{ getReturnTime(item) }}</div>
                      <div style="display: flex; justify-content: flex-end">
                        <van-button
                          :disabled="item.计划结束 == '' && item.计划开始 == ''"
                          @click="saveclick(item, index)"
                          round
                          type="info"
                          size="small"
                          >保存</van-button
                        >
                        <van-button
                          style="margin-left: 0.625rem"
                          @click="detailclick(item, index)"
                          round
                          type="default"
                          size="small"
                          >详情</van-button
                        >
                      </div>
                    </div>
                    <!-- <div class="text display border">
                    <div>录入用户：{{ item.录入用户 }}</div>
                    <div>录入时间：{{ item.录入时间 }}</div>
                  </div> -->
                    <!-- <div class="btn-edit">
                    <van-button
                      v-if="index != 0"
                      @click="upClick(item, index)"
                      round
                      type="info"
                      size="small"
                      >上移</van-button
                    >
                    <van-button
                      v-if="index != tableList.length - 1"
                      @click="downClick(item, index)"
                      round
                      type="default"
                      size="small"
                      style="margin-left: 0.625rem"
                      >下移</van-button
                    >
                  </div> -->
                    <!-- 
                    <div class="text size">YYYYYY</div>
                    <div class="text">生产线名称：1</div>
                    <div class="text">开始时间：2024-05-05 13:21</div>
                    <div class="text border">结束时间：2024-05-05 13:21</div>
                    <div class="text display">
                        <div>录入用户：张三</div>
                        <div>录入时间：2024-05-05 13:21</div>
                    </div>
                    状态四选一
                    <div class="absolute" style="background-color: #e4e7ed">
                        未开始
                    </div>
                    <div class="absolute" style="background-color: #e6a23c">
                        进行中
                    </div>
                    <div class="absolute" style="background-color: #f56c6c">
                        已驳回
                    </div>
                    <div class="absolute" style="background-color: #44d7b6">
                        已通过
                    </div>
                  --></div>
                </div>
              </template>

              <template v-else>
                <div class="noData">
                  <van-image :src="require('@/assets/data_empty.png')" />
                </div>
              </template>
            </div>
            <!-- </van-list> -->
          </van-pull-refresh>
        </div>
        <!-- <div class="title">
        <van-pagination
          v-model="pagin.当前页数"
          :total-items="pagin.total"
          :items-per-page="pagin.每页数量"
          :show-page-size="3"
          force-ellipses
          @change="paginchange"
        />
      </div> -->
      </div>
    </template>
    <template v-if="isAdd != ''">
      <Detail :addObj="addObj" @backclick="isAdd = ''" />
    </template>

    <van-popup position="top" v-model="searchshow">
      <div style="padding: 0.625rem">
        <van-form ref="vanform" label-width="6em">
          <van-field
            readonly
            clickable
            v-model="条件替换[1].查询日期"
            label="生产日期"
            placeholder="点击选择"
            @click="onclickdatetime('查询日期', 'dateShow', 'yyyy-mm-dd')"
          >
          </van-field>
          <van-field
            readonly
            clickable
            v-model="包装线名称"
            label="包装线"
            placeholder="点击选择"
            @click="onClick('包装线名称', '查询产线ID')"
          >
          </van-field>
        </van-form>
        <div style="display: flex">
          <van-button
            type="default"
            @click="cancel"
            style="width: 50%; margin: 0 0.625rem"
            >重置</van-button
          >
          <van-button
            type="info"
            @click="searchclick"
            style="width: 50%; margin: 0 0.625rem"
            >搜索</van-button
          >
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showPicker" position="bottom">
      <van-search
        placeholder="请输入"
        @input="depInput"
        v-model="depNameValue"
      />
      <van-picker
        value-key="text"
        :default-index="包装线Index"
        show-toolbar
        :columns="columns"
        @confirm="
          (value) => {
            onConfirm(value);
          }
        "
        @cancel="onCancel('showPicker')"
      />
    </van-popup>
    <van-popup v-model="dateShow" position="bottom">
      <van-datetime-picker
        v-model="dateValue"
        type="date"
        title="请选择"
        @confirm="
          (value) => {
            onConfirmTime(value, 'dateShow', 'yyyy-mm-dd');
          }
        "
        @cancel="onCancelDate('dateShow')"
      >
      </van-datetime-picker>
    </van-popup>
    <van-popup v-model="datetimeShow" position="bottom">
      <van-datetime-picker
        v-model="dateValue"
        type="datetime"
        title="请选择"
        @confirm="
          (value) => {
            onConfirmTime(value, 'datetimeShow');
          }
        "
        @cancel="onCancelDate('datetimeShow')"
      >
      </van-datetime-picker>
    </van-popup>
    <van-dialog
      v-model="fasongshow"
      title="删除"
      show-cancel-button
      @confirm="onDelConfirm"
      @cancel="onDelCancel"
    >
      是否要发送通知？
    </van-dialog>
  </div>
</template>

<script>
import Detail from "./Detail";
import { baseURL } from "@/config/net.js";
import request from "@/utils/request";
import {
  APPsettings_list,
  Tname_list,
  Packagingline_list,
  sort,
  employeenot,
  rbcx1_list,
  ProductionTaskSubtable_up,
} from "@/api/list";
import { Notify } from "vant";
export default {
  components: { Detail },
  data() {
    return {
      服务地址: "",
      menuObj: {
        新增: false,
        修改: false,
        删除: false,
      },
      tableList: [],
      loadingAdd: false,
      finished: false,
      refreshing: false,
      pagin: {
        total: 0,
        当前页数: 1,
        每页数量: 15,
      },
      配置: [],
      列表Key: [],
      loadingview: false,
      deleteShow: false,
      objData: {},
      isLoading: true,
      附表ID: "",
      附表接口: "",

      辅助配置: {},

      模糊查询: {},
      searchshow: false,
      searchStr: "",
      条件替换: [{ 查询产线ID: "" }, { 查询日期: "" }],
      包装线: [],
      包装线名称: "",
      包装线Index: -1,
      showPicker: false,
      depNameValue: "",
      columns: [],
      dateShow: false,
      dateValue: new Date(),
      lablelame: "",
      formLabelName: "",
      fasongshow: false,
      Index: "",
      datetimeShow: false,
      isAdd: "",
      addObj: {},
    };
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler() {
        console.log(this.$route.query);
        this.服务地址 = baseURL;
        this.isAdd = "";
        this.addObj = {};
        this.模糊查询 = {};
        this.条件替换 = [{ 查询产线ID: "" }, { 查询日期: "" }];
        this.包装线名称 = "";
        this.searchStr = "";

        if (JSON.stringify(this.$route.query) !== "{}") {
          this.menuObj = this.$route.query;
          Packagingline_list({
            菜单: this.menuObj.ID,
            包含查询: `负责人ID=${JSON.parse(localStorage.getItem("id"))}`,
          }).then((res) => {
            this.包装线 = res.data.list.map((item) => {
              return {
                ...item,
                text: item.生产线名称,
                value: item.ID,
              };
            });

            this.条件替换[1].查询日期 = this.formatDate(
              this.getNextday(),
              "yyyy-mm-dd"
            );
            this.条件替换[0].查询产线ID =
              this.包装线.length != 0 ? this.包装线[0].ID : "";
            this.包装线名称 =
              this.包装线.length != 0 ? this.包装线[0].生产线名称 : "";
            this.getreturnstr();
            console.log(this.$route.query);

            this.pagin.当前页数 = 1;
            this.pagin.每页数量 = 15;
            this.pagin.total = 0;
            this.tableList = [];
            this.finished = false;
            this.loadingAdd = false;
            this.refreshing = false;
            this.loadingview = true;
            this.isLoading = true;

            this.getList();
          });
        }
      },
    },
  },
  computed: {
    getReturnTime() {
      return (item) => {
        if (item.计划开始 && item.计划结束) {
          var obj = this.$calculateTimeDifference(
            item.计划开始,
            item.计划结束
          )
          return obj
            ? `${this.format(obj.hours)}:${this.format(
                obj.minutes
              )}:${this.format(obj.seconds)}`
            : '0:0:0'
        } else {
          return '0:0:0'
        }
      }
    },
  },
  mounted() {
    this.isAdd = "";
    this.addObj = {};
    this.服务地址 = baseURL;
  },
  methods: {
    detailclick(item, index) {
      this.isAdd = 1;
      this.addObj = {
        addeditdetail: 3,
        menuID: this.menuObj.ID,
        munuName: this.menuObj.菜单名称,
        ID: item.ID,
        产品ID: item.产品ID,
      };
      // this.$router.push({
      //   path: "/app/Digitalpackaging/ProductionSchedulingAdjustmentDetail",
      //   query: {
      //     addeditdetail: 3,
      //     menuID: this.menuObj.ID,
      //     munuName: this.menuObj.菜单名称,
      //     ID: item.ID,
      //     产品ID: item.产品ID,
      //   },
      // });
    },
    saveclick(item, index) {
      console.log(item.计划开始);
      console.log(item.计划结束);
      ProductionTaskSubtable_up({
        计划开始: item.计划开始 ? item.计划开始 : "NULL",
        计划结束: item.计划结束 ? item.计划结束 : "NULL",
        ID: item.ID,
      });
    },
    onDelCancel() {
      this.fasongshow = false;
    },
    onDelConfirm() {
      employeenot({
        菜单: this.$route.meta.ID,
        生产线ID: this.条件替换[0].查询产线ID,
        生产日期: this.条件替换[0].查询日期,
      }).then(() => {
        Notify({ type: "success", message: "发送成功" });
        this.fasongshow = false;
      });
    },
    fasong() {
      this.fasongshow = true;
    },
    getNextday() {
      // 获取当前时间的 Date 对象
      let currentDate = new Date();

      // 获取下一天的时间戳
      let nextDayTimeStamp = currentDate.getTime() + 24 * 60 * 60 * 1000;

      // 创建下一天的 Date 对象
      return new Date(nextDayTimeStamp);
    },
    upClick(item, index) {
      const movedItem = this.tableList.splice(index, 1)[0];
      this.tableList.splice(index - 1, 0, movedItem);
      var IDS = this.tableList.map((item) => {
        return item.ID;
      });
      sort({ data: IDS, 表名: "生产任务子表" }).then((res) => {
        console.log(res);
        this.isLoading = true;
        this.getList();
      });
    },
    downClick(item, index) {
      const movedItem = this.tableList.splice(index, 1)[0];
      this.tableList.splice(index + 1, 0, movedItem);
      var IDS = this.tableList.map((item) => {
        return item.ID;
      });
      sort({ data: IDS, 表名: "生产任务子表" }).then((res) => {
        console.log(res);
        this.isLoading = true;
        this.getList();
      });
    },
    depInput() {
      console.log(this.depNameValue);

      this.columns = this.包装线.filter((item) => {
        return item.text.includes(this.depNameValue);
      });
    },
    // 下拉选
    onClick(text, valueText) {
      this.depNameValue = "";

      this.columns = this.包装线;
      this.lablelame = text;
      this.formLabelName = valueText;
      var index = this.columns.findIndex((item) => {
        return item.value == this.条件替换[0].查询产线ID;
      });
      console.log(index);
      this.包装线Index = index != -1 ? index : "";
      this.showPicker = true;
    },
    onConfirm(e) {
      if (!e) return;

      var value = e.value;
      this.条件替换[0].查询产线ID = value;
      var obj = this.columns.find((item) => {
        return item.value == value;
      });
      this.包装线名称 = obj ? obj.text : "";

      this.showPicker = false;
    },
    onCancel(showText) {
      this[showText] = false;
    },
    // 日期
    onclickdatetime(text, showText, type, number) {
      console.log(number);
      this.lablelame = text;
      if (number || number === 0) {
        this.Index = number;
        this.dateValue =
          this.tableList[number][text] == ""
            ? new Date()
            : new Date(this.tableList[number][text]);
      } else {
        this.dateValue =
          this.条件替换[1].查询日期 == ""
            ? new Date()
            : new Date(this.条件替换[1].查询日期);
      }

      this.$nextTick(() => {
        this[showText] = true;
      });
    },
    onConfirmTime(value, showText, type) {
      console.log(this.tableList);
      console.log(this.Index);
      console.log(this.lablelame);
      console.log(this.tableList[this.Index]);
      if (this.lablelame == "计划开始" || this.lablelame == "计划结束") {
        var obj = this.tableList[this.Index];
        console.log(obj);
        obj[this.lablelame] =
          type && type == "yyyy-mm-dd"
            ? this.formatDate(value, "yyyy-mm-dd")
            : this.formatDate(value);

        console.log(this.tableList);
      } else {
        this.条件替换[1].查询日期 =
          type && type == "yyyy-mm-dd"
            ? this.formatDate(value, "yyyy-mm-dd")
            : this.formatDate(value);
      }

      this.dateValue = value;

      this[showText] = false;
    },
    onCancelDate(showText) {
      this[showText] = false;
    },
    // 格式化时间
    formatDate(date, type) {
      date = new Date(date);
      var str = `${date.getFullYear()}-${this.format(
        date.getMonth() + 1
      )}-${this.format(date.getDate())}`;

      return type && type == "yyyy-mm-dd"
        ? str
        : `${str} ${this.format(date.getHours())}:${this.format(
            date.getMinutes()
          )}:00`;

      // ${this.format(date.getSeconds())}
    },

    format(n) {
      return n < 10 ? "0" + n : "" + n;
    },
    getreturnstr() {
      this.searchStr = "";
      var obj = {
        // 查询产线ID: this.条件替换[0].查询产线ID,
        查询日期: this.条件替换[1].查询日期,
        包装线名称: this.包装线名称,
      };
      var str = "";
      for (var key in obj) {
        if (obj[key] && obj != "") {
          str = str + obj[key] + ",";
        }
      }
      //   var str = "";
      //   for (var key in this.模糊查询) {
      //     if (this.模糊查询[key] && this.模糊查询[key] != "") {
      //       str = str + this.模糊查询[key] + ",";
      //     }
      //   }
      this.searchStr =
        str.substring(0, str.length - 1) == ""
          ? ""
          : str.substring(0, str.length - 1);
    },
    searchclick() {
      this.getreturnstr();
      this.isLoading = true;
      this.searchshow = false;
      this.pagin.当前页数 = 1;
      this.pagin.每页数量 = 15;
      this.pagin.total = 0;
      this.tableList = [];
      this.getList();
    },
    cancel() {
      this.条件替换[0].查询产线ID =
        this.包装线.length != 0 ? this.包装线[0].ID : "";
      this.条件替换[1].查询日期 = this.formatDate(
        this.getNextday(),
        "yyyy-mm-dd"
      );
      this.包装线名称 =
        this.包装线.length != 0 ? this.包装线[0].生产线名称 : "";
      //   for (var key in this.模糊查询) {
      //     this.$set(this.模糊查询, key, "");
      //   }
      this.getreturnstr();
      this.searchshow = false;
      this.pagin.当前页数 = 1;
      this.pagin.每页数量 = 15;
      this.pagin.total = 0;
      this.tableList = [];
      this.isLoading = true;
      this.getList();
    },
    showSearchPopup() {
      this.searchshow = true;
    },

    paginchange(val) {
      this.isLoading = true;
      console.log(val);
      this.$refs.scrollContainer.scrollTop = 0;
      this.pagin.当前页数 = val;
      this.getList();
    },

    onClickLeft() {
      this.$router.push("/app/home");
    },

    // 下拉刷新
    onRefresh() {
      this.pagin.当前页数 = 1;
      this.pagin.每页数量 = 15;
      this.pagin.total = 0;
      //   this.tableList = [];
      this.refreshing = true;
      this.finished = false;
      setTimeout(() => {
        // this.tableList = [];
        this.getList(2);
      }, 500);
    },
    // // 上拉加载

    getList(number) {
      if (number == 2) {
        this.tableList = [];
        number = 1;
      }
      //   var str = "";
      //   for (var key in this.模糊查询) {
      //     if (this.模糊查询[key] && this.模糊查询[key] != "") {
      //       str = str + key + "='" + this.模糊查询[key] + "',";
      //     }
      //   }

      rbcx1_list({
        //   当前页数: this.pagin.当前页数,
        //   每页数量: this.pagin.每页数量,
        菜单: this.menuObj.ID,
        //   模糊查询:
        //     str.substring(0, str.length - 1) == ""
        //       ? undefined
        //       : str.substring(0, str.length - 1),
        排序字段: "计划开始 ASC",
        条件替换: this.条件替换,
      })
        .then((res) => {
          console.log(res);

          this.pagin.total = res.data.Totalrecords;
          this.refreshing = false;

          if (number == 2) {
            this.loadingAdd = false;
            this.finished = false;
            this.tableList = res.data.list;
          } else {
            this.tableList = res.data.list;
          }
          this.loadingview = false;
          this.isLoading = false;
        })
        .catch(() => {
          this.loadingAdd = false;
          this.loadingview = false;
          this.isLoading = false;
        });
    },
  },
};
</script>

<style  lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #f4f8fb;
  .title {
    width: 100%;
    flex-shrink: 0;
    font-size: 0.75rem;
  }
  .content {
    width: 100%;
    flex-grow: 1;
    overflow-y: hidden;
  }
  .van-pull-refresh {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .scrolldiv {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}

.item {
  padding: 10px;

  /* border-bottom: 1px solid #ccc; */
}

.item-warrper {
  padding: 10px;
  width: 100%;
  background-color: #fff;
  border-radius: 0.625rem;
  position: relative;
  overflow: hidden;
}

.absolute {
  position: absolute;
  top: 0;
  right: 0;
  width: 2.875rem;
  height: 1.625rem;
  font-size: 0.75rem;
  color: #fff;

  border-radius: 0 0 0 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  padding-bottom: 0.625rem;
  font-size: 0.75rem;
  color: #000;
  overflow: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.text2 {
  display: flex;
  //   flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  img {
    width: 6.25rem;
    // height: 3.75rem;
  }
}

.size {
  font-size: 0.875rem;
  color: #6d7278;
}

.border {
  border-top: 1px solid #6d7278;
}

.display {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.display div {
  font-size: 0.625rem;
  color: #6d7278;
  padding-top: 0.625rem;
}

.loading {
  text-align: center;
  padding: 0.625rem 0;
}
.btn-edit {
  display: flex;
  justify-content: flex-end;
}
</style>