import request from '@/utils/request';

export const APPsettings_list = (data) => {
    return request({
        url: '/APPsettings_list',
        method: 'post',
        data: data
    })
}
export const Classificationmanagement_list = (data) => {
    return request({
        url: '/Classificationmanagement_list',
        method: 'post',
        data: data
    })
}
export const posts = (data) => {
    return request({
        url: '/posts',
        method: 'post',
        data: data
    })
}
export const mstable = (data) => {
    return request({
        url: '/mstable',
        method: 'post',
        data: data
    })
}
export const mstableup = (data) => {
    return request({
        url: '/mstableup',
        method: 'post',
        data: data
    })
}
export const Tname_list = (data) => {
    return request({
        url: '/Tname_list',
        method: 'post',
        data: data
    })
}
// 包装线list up add del
export const Packagingline_list = (data) => {
    return request({
        url: '/Packagingline_list',
        method: 'post',
        data: data
    })
}
export const Packagingline_add = (data) => {
    return request({
        url: '/Packagingline_add',
        method: 'post',
        data: data
    })
}
export const Packagingline_up = (data) => {
    return request({
        url: '/Packagingline_up',
        method: 'post',
        data: data
    })
}
export const Packagingline_del = (data) => {
    return request({
        url: '/Packagingline_del',
        method: 'post',
        data: data
    })
}
export const sort = (data) => {
    return request({
        url: '/sort',
        method: 'post',
        data: data
    })
}
// 包装工序
export const Packagingprocessdefinition_list = (data) => {
    return request({
        url: '/Packagingprocessdefinition_list',
        method: 'post',
        data: data
    })
}
export const Packagingprocessdefinition_add = (data) => {
    return request({
        url: '/Packagingprocessdefinition_add',
        method: 'post',
        data: data
    })
}
export const Packagingprocessdefinition_up = (data) => {
    return request({
        url: '/Packagingprocessdefinition_up',
        method: 'post',
        data: data
    })
}
export const Packagingprocessdefinition_del = (data) => {
    return request({
        url: '/Packagingprocessdefinition_del',
        method: 'post',
        data: data
    })
}
// 物料产品分类
export const Materialclassleft_list = (data) => {
    return request({
        url: '/Materialclassleft_list',
        method: 'post',
        data: data
    })
}
export const user_list = (data) => {
    return request({
        url: '/user_list',
        method: 'post',
        data: data
    })
}
export const dictfiltering = (data) => {
    return request({
        url: '/dictfiltering',
        method: 'post',
        data: data
    })
}
export const employeenot = (data) => {
    return request({
        url: '/employeenot',
        method: 'post',
        data: data
    })
}
export const Productsubtable_list = (data) => {
    return request({
        url: '/Productsubtable_list',
        method: 'post',
        data: data
    })
}
export const material_list = (data) => {
    return request({
        url: '/material_list',
        method: 'post',
        data: data
    })
}
export const rbcx1_list = (data) => {
    return request({
        url: '/rbcx1_list',
        method: 'post',
        data: data
    })
}
// 产量日报
export const PackagingDaily_list = (data) => {
    return request({
        url: '/PackagingDaily_list',
        method: 'post',
        data: data
    })
}
export const PackagingDaily_add = (data) => {
    return request({
        url: '/PackagingDaily_add',
        method: 'post',
        data: data
    })
}
export const PackagingDaily_up = (data) => {
    return request({
        url: '/PackagingDaily_up',
        method: 'post',
        data: data
    })
}
export const PackagingDaily_del = (data) => {
    return request({
        url: '/PackagingDaily_del',
        method: 'post',
        data: data
    })
}
// 
export const PackagingDailyProduction_list = (data) => {
    return request({
        url: '/PackagingDailyProduction_list',
        method: 'post',
        data: data
    })
}


export const PackagingDailyPersonnel_list = (data) => {
    return request({
        url: '/PackagingDailyPersonnel_list',
        method: 'post',
        data: data
    })
}
export const continuous = (data) => {
    return request({
        url: '/continuous',
        method: 'post',
        data: data
    })
}
export const PackagingDailyConsumables_list = (data) => {
    return request({
        url: '/PackagingDailyConsumables_list',
        method: 'post',
        data: data
    })
}
export const PackagingDailyConsumables_up = (data) => {
    return request({
        url: '/PackagingDailyConsumables_up',
        method: 'post',
        data: data
    })
}
export const ProductionTaskSubtable_up = (data) => {
    return request({
        url: '/ProductionTaskSubtable_up',
        method: 'post',
        data: data
    })
}
