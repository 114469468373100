<template>
  <div style="width: 100%; height: 100%">
    <div v-if="isLoading" class="myloading">
      <van-loading type="spinner" />
    </div>
    <div v-else class="box">
      <div class="title">
        <van-nav-bar
          :title="
            objData.munuName +
            (objData.addeditdetail == 1
              ? '新增'
              : objData.addeditdetail == 2
              ? '修改'
              : '详情')
          "
          left-text="返回"
          left-arrow
          @click-left="onClickLeft"
        >
          <template #right v-if="objData && objData.addeditdetail != 3">
            <van-button round @click="saveclick" type="info" size="small"
              >保存</van-button
            >
          </template>
        </van-nav-bar>
      </div>
      <div class="content">
        <div class="scrolldiv" ref="scrollContainer">
          <van-form ref="vanform" label-width="6em">
            <template
              v-if="
                $route.path == '/app/system/Digitalpackaging/PackagingDaily'
              "
            >
              <van-field
                v-if="objData && objData.addeditdetail != 1"
                readonly
                v-model="form.单据编号"
                label="单据编号"
                placeholder="单据编号"
              >
              </van-field>
              <van-field
                name="生产日期"
                :disabled="objData && objData.addeditdetail == 3"
                readonly
                clickable
                v-model="form.生产日期"
                label="生产日期"
                :rules="[
                  {
                    required: true,
                    message: '', //message: '请选择' + item.名称
                  },
                ]"
                :placeholder="
                  objData && objData.addeditdetail == 3
                    ? ''
                    : '点击选择生产日期'
                "
                @click="onclickdatetime('生产日期', 'dateShow', 'yyyy-mm-dd')"
              >
              </van-field>

              <van-field
                readonly
                name="生产线名称"
                :disabled="objData && objData.addeditdetail == 3"
                clickable
                v-model="form.生产线名称"
                label="包装线"
                placeholder="包装线"
                @click="onClick('生产线名称', '生产线ID')"
                :rules="[
                  {
                    required: true,
                    message: '', //message: '请选择' + item.名称
                  },
                ]"
              >
              </van-field>
              <!-- <van-field
                name="开始时间"
                :disabled="objData && objData.addeditdetail == 3"
                readonly
                clickable
                v-model="form.开始时间"
                label="开始时间"
                :rules="[
                  {
                    required: true,
                    message: '', //message: '请选择' + item.名称
                  },
                ]"
                :placeholder="
                  objData && objData.addeditdetail == 3
                    ? ''
                    : '点击选择开始时间'
                "
                @click="onclickdatetime('开始时间', 'datetimeShow')"
              >
              </van-field>
              <van-field
                name="结束时间"
                :disabled="objData && objData.addeditdetail == 3"
                readonly
                clickable
                v-model="form.结束时间"
                label="结束时间"
                :rules="[
                  {
                    required: true,
                    message: '', //message: '请选择' + item.名称
                  },
                ]"
                :placeholder="
                  objData && objData.addeditdetail == 3
                    ? ''
                    : '点击选择结束时间'
                "
                @click="onclickdatetime('结束时间', 'datetimeShow')"
              >
              </van-field>
              <van-field
                name="备注"
                :disabled="objData && objData.addeditdetail == 3"
                clickable
                rows="1"
                autosize
                type="textarea"
                v-model="form.备注"
                label="备注"
                placeholder="备注"
              >
              </van-field> -->
              <template
               v-if="
                $route.path ==
                  '/app/system/Digitalpackaging/PackagingDaily' 
              "
               
              >
              <!--  v-if="objData.addeditdetail == 3 && form.子表.length != 0" -->
                <template v-for="(item, index) in form.子表">
                  <div style="width: 100%; padding: 0.625rem" :key="index">
                    <van-form
                      ref="vanformSub"
                      style="background-color: #fff"
                      label-width="6em"
                    >
                      <van-field
                        readonly
                        v-model="item.名称"
                        label="产品名称"
                        placeholder="名称"
                      >
                      </van-field>
                      <van-field
                        :disabled="objData && objData.addeditdetail == 3"
                        placeholder="生产数量"
                        v-model="item.生产数量"
                        type="number"
                        label="生产数量"
                      />
                    </van-form>
                  </div>
                </template>
              </template>
            </template>

            <template
              v-if="
                $route.path ==
                  '/app/system/Digitalpackaging/PackagingDailyPersonnel' ||
                $route.path ==
                  '/app/system/Digitalpackaging/PackagingDailyConsumables'
              "
            >
              <van-field
                readonly
                v-model="form.单据编号"
                label="单据编号"
                placeholder="单据编号"
              >
              </van-field>
              <van-field
                readonly
                v-model="form.生产日期"
                label="生产日期"
                placeholder="生产日期"
              >
              </van-field>
              <van-field
                readonly
                v-model="form.生产线名称"
                label="包装线"
                placeholder="包装线"
              >
              </van-field>
              <van-field
                readonly
                v-model="form.开始时间"
                label="开始时间"
                placeholder="开始时间"
              >
              </van-field>
              <van-field
                readonly
                v-model="form.结束时间"
                label="结束时间"
                placeholder="结束时间"
              >
              </van-field>

              <!-- <van-field
                name="备注"
                readonly
                rows="1"
                autosize
                type="textarea"
                v-model="form.备注"
                label="备注"
                placeholder="备注"
              >
              </van-field> -->
            </template>

            <template
              v-if="
                $route.path ==
                '/app/system/Digitalpackaging/PackagingDailyPersonnel'
              "
            >
              <template v-if="form.子表.length != 0">
                <template v-for="(item, index) in form.子表">
                  <div style="width: 100%; padding: 0.625rem" :key="index">
                    <van-form
                      ref="vanformSub"
                      style="background-color: #fff"
                      label-width="6em"
                    >
                      <van-field
                        readonly
                        v-model="item.工序名称"
                        label="工序名称"
                        placeholder="工序名称"
                      >
                      </van-field>
                      <van-field
                        readonly
                        v-model="item.岗位人员"
                        label="岗位人员"
                        placeholder="岗位人员"
                      >
                      </van-field>
                      <van-field
                        :disabled="objData && objData.addeditdetail == 3"
                        placeholder="参与产量"
                        v-model="item.参与产量"
                        type="number"
                        label="参与产量"
                      />
                    </van-form>
                  </div>
                </template>
              </template>
            </template>
            <template
              v-if="
                $route.path ==
                '/app/system/Digitalpackaging/PackagingDailyConsumables'
              "
            >
              <van-form ref="vanform" label-width="6em">
                <van-field
                  :disabled="objData && objData.addeditdetail == 3"
                  v-model="form.火碱"
                  type="number"
                  label="火碱"
                  :placeholder="
                    objData && objData.addeditdetail == 3 ? '' : '请输入'
                  "
                />
                <van-field
                  :disabled="objData && objData.addeditdetail == 3"
                  :placeholder="
                    objData && objData.addeditdetail == 3 ? '' : '请输入'
                  "
                  v-model="form.清洗剂"
                  type="number"
                  label="清洗剂"
                />
                <van-field
                  :disabled="objData && objData.addeditdetail == 3"
                  :placeholder="
                    objData && objData.addeditdetail == 3 ? '' : '请输入'
                  "
                  v-model="form.胶带"
                  type="number"
                  label="胶带"
                />
                <van-field
                  :disabled="objData && objData.addeditdetail == 3"
                  :placeholder="
                    objData && objData.addeditdetail == 3 ? '' : '请输入'
                  "
                  v-model="form.盖"
                  type="number"
                  label="盖"
                />
                <van-field
                  :disabled="objData && objData.addeditdetail == 3"
                  :placeholder="
                    objData && objData.addeditdetail == 3 ? '' : '请输入'
                  "
                  v-model="form.商标胶"
                  type="number"
                  label="商标胶"
                />
                <van-field
                  :disabled="objData && objData.addeditdetail == 3"
                  :placeholder="
                    objData && objData.addeditdetail == 3 ? '' : '请输入'
                  "
                  v-model="form.热熔胶"
                  type="number"
                  label="热熔胶"
                />
                <van-field
                  :disabled="objData && objData.addeditdetail == 3"
                  :placeholder="
                    objData && objData.addeditdetail == 3 ? '' : '请输入'
                  "
                  v-model="form.电表数"
                  label="电表数"
                />

                <van-field
                  :disabled="objData && objData.addeditdetail == 3"
                  :placeholder="
                    objData && objData.addeditdetail == 3 ? '' : '请输入'
                  "
                  v-model="form.蒸汽数"
                  label="蒸汽数"
                />
                <van-field
                  :disabled="objData && objData.addeditdetail == 3"
                  :placeholder="
                    objData && objData.addeditdetail == 3 ? '' : '请输入'
                  "
                  v-model="form.CO2"
                  label="CO2"
                />
                <van-field
                  :disabled="objData && objData.addeditdetail == 3"
                  :placeholder="
                    objData && objData.addeditdetail == 3 ? '' : '请输入'
                  "
                  v-model="form.自来水"
                  label="自来水"
                />
                <van-field
                  :disabled="objData && objData.addeditdetail == 3"
                  :placeholder="
                    objData && objData.addeditdetail == 3 ? '' : '请输入'
                  "
                  v-model="form.酿造水"
                  label="酿造水"
                />
                <van-field
                  :disabled="objData && objData.addeditdetail == 3"
                  :placeholder="
                    objData && objData.addeditdetail == 3 ? '' : '请输入'
                  "
                  v-model="form.酒液消耗"
                  label="酒液消耗"
                />
                <van-field
                  :disabled="objData && objData.addeditdetail == 3"
                  name="备注"
                  rows="1"
                  autosize
                  type="textarea"
                  v-model="form.备注"
                  label="备注"
                  :placeholder="
                    objData && objData.addeditdetail == 3 ? '' : '请输入'
                  "
                >
                </van-field>
              </van-form>
            </template>

            <van-popup v-model="dateShow" position="bottom">
              <van-datetime-picker
                v-model="sunForm[formvalueTextValue]"
                type="date"
                title="请选择"
                @confirm="
                  (value) => {
                    onConfirmTime(value, 'dateShow', 'yyyy-mm-dd');
                  }
                "
                @cancel="onCancelDate('dateShow')"
              >
              </van-datetime-picker>
            </van-popup>
            <van-popup v-model="datetimeShow" position="bottom">
              <van-datetime-picker
                v-model="sunForm[formvalueTextValue]"
                type="datetime"
                title="请选择"
                @confirm="
                  (value) => {
                    onConfirmTime(value, 'datetimeShow');
                  }
                "
                @cancel="onCancelDate('datetimeShow')"
              >
              </van-datetime-picker>
            </van-popup>
            <van-popup v-model="timeShow" position="bottom">
              <van-datetime-picker
                v-model="sunForm[formvalueTextValue]"
                type="time"
                title="请选择"
                @confirm="
                  (value) => {
                    onConfirmTime(value, 'timeShow', 'hh:ss');
                  }
                "
                @cancel="onCancelDate('timeShow')"
              >
              </van-datetime-picker>
            </van-popup>
            <van-popup v-model="showPicker" position="bottom">
              <van-search
                placeholder="请输入"
                @input="depInput"
                v-model="depNameValue"
              />
              <van-picker
                value-key="text"
                :default-index="defaultIndex"
                show-toolbar
                :columns="columns"
                @confirm="
                  (value) => {
                    onConfirm(value);
                  }
                "
                @cancel="onCancel('showPicker')"
              />
            </van-popup>
          </van-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { baseURL } from "@/config/net.js";
import request from "@/utils/request";
import {
  APPsettings_list,
  Classificationmanagement_list,
  posts,
  mstable,
  mstableup,
  Tname_list,
  Packagingline_list,
  PackagingDaily_list,
  Packagingprocessdefinition_list,
  Materialclassleft_list,
  user_list,
  PackagingDaily_add,
  PackagingDaily_up,
  PackagingDailyProduction_list,
  rbcx1_list,
  PackagingDailyPersonnel_list,
  continuous,
  PackagingDailyConsumables_list,
  PackagingDailyConsumables_up,
} from "@/api/list";
import { Notify } from "vant";
export default {
  components: {},
  props: ["addObj"],
  data() {
    return {
      服务地址: "",

      objData: {},

      form: {
        单据编号: "",
        生产日期: this.formatDate(new Date(), "yyyy-mm-dd"),
        生产线ID: "",
        生产线名称: "",
        开始时间: "",
        结束时间: "",
        生产时长: "",
        备注: "",
        子表: [],
      },
      sunForm: {
        生产日期code: "",
        开始时间code: "",
        结束时间code: "",
      },
      datetimeShow: false,
      dateShow: false,
      timeShow: false,
      formValueText: "",
      formvalueTextValue: "",
      showPicker: false,
      defaultIndex: "",
      columns: [],
      isLoading: true,
      depNameValue: "",
      接口obj: {
        生产线名称: [],
      },
      selectType: [],

      activeNames: ["1"],
    };
  },
  watch: {
    // "$route.query": {
    //   immediate: true,
    //   handler() {
    //     this.服务地址 = baseURL;
    //     if (JSON.stringify(this.$route.query) !== "{}") {
    //       console.log(this.$route.query);
    //       this.objData = this.$route.query;
    //       Materialclassleft_list({
    //         菜单: this.objData.menuID,
    //         ID: 42,
    //         显示本级: 0,
    //       }).then((res) => {
    //         this.接口obj.包装类型 = [];
    //         this.flattenTree(res.data.permissions);
    //         console.log(this.接口obj.包装类型);
    //       });
    //       user_list({
    //         菜单: this.objData.menuID,
    //       }).then((res) => {
    //         this.接口obj.负责人 = res.data.list.map((item) => {
    //           return {
    //             ...item,
    //             text: item.用户名,
    //             value: item.ID,
    //           };
    //         });
    //       });
    //       this.isLoading = true;
    //       if (this.objData.addeditdetail == 1) {
    //         this.isLoading = false;
    //       } else {
    //         this.$set(this.form, "ID", this.objData.ID);
    //         this.getData();
    //       }
    //     }
    //   },
    // },
  },
  mounted() {
    this.服务地址 = baseURL;
    if (JSON.stringify(this.addObj) !== "{}") {
      this.objData = JSON.parse(JSON.stringify(this.addObj));
      Packagingline_list({
        菜单: this.objData.menuID,
        包含查询: `负责人ID=${JSON.parse(localStorage.getItem("id"))}`,
      }).then((res) => {
        this.接口obj.生产线名称 = res.data.list.map((item) => {
          return {
            ...item,
            text: item.生产线名称,
            value: item.ID,
          };
        });
      });

      this.isLoading = true;
      if (this.objData.addeditdetail == 1) {
        this.isLoading = false;
      } else {
        this.$set(this.form, "ID", this.objData.ID);
        this.getData();
      }
    }
  },
  methods: {
    depInput() {
      console.log([this.formValueText]);

      this.columns = this.接口obj[this.formValueText].filter((item) => {
        return item.text.includes(this.depNameValue);
      });
    },
    // 下拉选
    onClick(text, valueText) {
      this.depNameValue = "";
      if (this.objData && this.objData.addeditdetail == 3) return;
      this.columns = this.接口obj[text];
      this.formValueText = text;
      this.formvalueTextValue = valueText;
      var index = this.columns.findIndex((item) => {
        return item.value == this.form[valueText];
      });
      console.log(index);
      this.defaultIndex = index != -1 ? index : "";
      this.showPicker = true;
    },
    onConfirm(e) {
      if (!e) return;

      var value = e.value;
      this.form[this.formvalueTextValue] = value;
      var obj = this.columns.find((item) => {
        return item.value == value;
      });
      this.form[this.formValueText] = obj ? obj.text : "";

      this.showPicker = false;
      if (this.formvalueTextValue == "生产线ID") {
        this.getSubList();
      }
    },
    getSubList() {
      if (this.form.生产线ID != "" && this.form.生产日期 != "") {
        rbcx1_list({
          条件替换: [
            { 查询产线ID: this.form.生产线ID },
            { 查询日期: this.form.生产日期 },
          ],
        }).then((res) => {
          this.form.子表 = res.data.list.map((item) => {
            delete item.ID;
            return {
              ...item,
              产品名称: item.名称,
              生产数量: "",
            };
          });
        });
      }
    },
    onCancel(showText) {
      this[showText] = false;
    },
    onClickLeft() {
      this.$emit("backclick");
      // this.$router.go(-1);
    },
    flattenTree(tree) {
      tree.forEach((node) => {
        this.接口obj.包装类型.push({
          ...node,
          text: node.name,
          value: node.ID,
        });
        if (node.children) {
          this.flattenTree(node.children);
        }
      });
    },
    getData() {
      var result1 = PackagingDaily_list({
        菜单: this.objData.menuID,
        查询条件: `ID='${this.form.ID}'`,
      });
      if (this.$route.path == "/app/system/Digitalpackaging/PackagingDaily") {
        var result2 = PackagingDailyProduction_list({
          菜单: this.objData.menuID,
          查询条件: `主表ID='${this.form.ID}'`,
        });
      } else if (
        this.$route.path ==
        "/app/system/Digitalpackaging/PackagingDailyPersonnel"
      ) {
        var result2 = PackagingDailyPersonnel_list({
          菜单: this.objData.menuID,
          查询条件: `主表ID='${this.form.ID}'`,
        });
      } else if (
        this.$route.path ==
        "/app/system/Digitalpackaging/PackagingDailyConsumables"
      ) {
        var result2 = PackagingDailyConsumables_list({
          菜单: this.objData.menuID,
          查询条件: `主表ID='${this.form.ID}'`,
        });
      }

      Promise.all([result1, result2])
        .then((res) => {
          var obj = res[0].data.list.length != 0 ? res[0].data.list[0] : {};

          this.$set(this.form, "单据编号", obj.单据编号);
          this.$set(this.form, "生产线ID", obj.生产线ID);
          this.$set(this.form, "生产线名称", obj.生产线名称);
          this.$set(this.form, "生产日期", obj.生产日期);
          this.$set(this.form, "开始时间", obj.开始时间);

          this.$set(this.form, "结束时间", obj.结束时间);
          this.$set(this.form, "生产时长", obj.生产时长);
          this.$set(this.form, "备注", obj.备注);

          this.$set(this.form, "子表", []);
          if (
            this.$route.path == "/app/system/Digitalpackaging/PackagingDaily"
          ) {
            if (res[1].data.list.length != 0) {
              this.$set(this.form, "子表", res[1].data.list);
            } else {
              this.$set(this.form, "子表", []);
            }
            this.isLoading = false;
          } else if (
            this.$route.path ==
            "/app/system/Digitalpackaging/PackagingDailyPersonnel"
          ) {
            if (this.objData.addeditdetail == 2) {
              if (res[1].data.list.length != 0) {
                this.$set(this.form, "子表", res[1].data.list);
                this.isLoading = false;
              } else {
                Promise.all([
                  Packagingprocessdefinition_list({
                    查询条件: `主表ID='${obj.生产线ID}'`,
                    排序字段: "序号 ASC",
                    菜单: this.objData.menuID,
                  }),
                  PackagingDailyProduction_list({
                    菜单: this.objData.menuID,
                    查询条件: `主表ID='${this.form.ID}'`,
                  }),
                ])
                  .then((res1) => {
                    var sum = 0;
                    sum = res1[1].data.list.reduce(
                      (accumulator, currentValue) => {
                        return accumulator + Number(currentValue.生产数量);
                      },
                      0
                    );
                    var arr = this.getYGList(
                      res1[0].data.list,
                      "员工",
                      "岗位人员"
                    ).map((item) => {
                      return {
                        ...item,
                        参与产量: sum.toFixed(2),
                      };
                    });
                    console.log(arr);
                    this.$set(this.form, "子表", arr);
                    this.isLoading = false;
                  })

                  .catch((err) => {
                    this.isLoading = false;
                    console.log(err);
                  });
              }
            } else if (this.objData.addeditdetail == 3) {
              this.$set(this.form, "子表", res[1].data.list);
              this.isLoading = false;
            }
          } else if (
            this.$route.path ==
            "/app/system/Digitalpackaging/PackagingDailyConsumables"
          ) {
            if (res[1].data.list.length != 0) {
              var obj1 = res[1].data.list[0];
              this.$set(this.form, "主表ID", this.form.ID);
              this.$set(this.form, "火碱", obj1.火碱);
              this.$set(this.form, "清洗剂", obj1.清洗剂);
              this.$set(this.form, "胶带", obj1.胶带);
              this.$set(this.form, "盖", obj1.盖);
              this.$set(this.form, "商标胶", obj1.商标胶);

              this.$set(this.form, "热熔胶", obj1.热熔胶);
              this.$set(this.form, "电表数", obj1.电表数);
              this.$set(this.form, "蒸汽数", obj1.蒸汽数);
              this.$set(this.form, "CO2", obj1.CO2);
              this.$set(this.form, "自来水", obj1.自来水);
              this.$set(this.form, "酿造水", obj1.酿造水);
              this.$set(this.form, "酒液消耗", obj1.酒液消耗);
              this.$set(this.form, "备注", obj1.备注);
            } else {
              this.$set(this.form, "主表ID", this.form.ID);
              this.$set(this.form, "火碱", "");
              this.$set(this.form, "清洗剂", "");
              this.$set(this.form, "胶带", "");
              this.$set(this.form, "盖", "");
              this.$set(this.form, "商标胶", "");

              this.$set(this.form, "热熔胶", "");
              this.$set(this.form, "电表数", "");
              this.$set(this.form, "蒸汽数", "");
              this.$set(this.form, "CO2", "");
              this.$set(this.form, "自来水", "");
              this.$set(this.form, "酿造水", "");
              this.$set(this.form, "酒液消耗", "");
              this.$set(this.form, "备注", "");
            }
            this.isLoading = false;
          }

          console.log(this.form);
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    getYGList(list, text, key) {
      return list.reduce(function (acc, curr) {
        var employees = curr[text].split(",");
        employees.forEach(function (employee) {
          delete curr.ID;
          acc.push({
            ...curr,
            [key]: employee,
          });
        });
        return acc;
      }, []);
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    asyncBeforeReadPic(file) {
      return new Promise((resolve, reject) => {
        if (
          file.type === "image/jpg" ||
          file.type === "image/png" ||
          file.type === "image/jpeg" ||
          file.type === "image/gif"
        ) {
          resolve();
        } else {
          reject();

          Toast.fail("请上传 jpg、png、jpeg、gif 格式图片");
        }
      });
    },
    afterRead(res, text) {
      this.getBase64(res.file).then((result) => {
        posts(
          {
            file: result,
            文件名: res.file.name,
            文件类型: res.file.name.substr(res.file.name.lastIndexOf(".") + 1),
            模块名称: this.objData.munuName,
            主表ID: "",
            节点ID: "0",
            项目ID: "",
            菜单: this.objData.menuID,
          },
          res.file.name.substr(res.file.name.lastIndexOf(".") + 1)
        ).then((response) => {
          if (response && response.file) {
            Notify({ type: "success", message: "上传成功" });
            this.form[text] = response.file;
            this.sunForm[text + "imgList"] = [
              { url: this.服务地址 + response.file },
            ];
          } else {
            Notify({ type: "danger", message: "上传失败" });
            this.form[text] = "";
            this.sunForm[text + "imgList"] = [];
          }
        });
      });
    },
    deleteCoverPic(res, text) {
      console.log(res, text);
      var index = this.sunForm[text + "imgList"].findIndex((item) => {
        return item.url == res.url;
      });
      if (index != -1) {
        this.sunForm[text + "imgList"].splice(index, 1);
      }
      Notify({ type: "danger", message: "删除成功" });
    },
    onOversize(file) {
      console.log(file);
      Notify({ type: "danger", message: "文件大小不能超过 10M" });
    },

    saveclick() {
      if (this.$route.path == "/app/system/Digitalpackaging/PackagingDaily") {
        this.$refs.vanform
          .validate()
          .then(() => {
            this.isLoading = true;

            var obj = {
              菜单: this.objData.menuID,
              生产日期: this.form.生产日期,
              生产线ID: this.form.生产线ID,
              生产线名称: this.form.生产线名称,
              开始时间: this.form.开始时间,
              结束时间: this.form.结束时间,
              备注: this.form.备注,
              table1: "包装日报",
              table2: "包装日报产量",
              data: this.form.子表.map((item) => {
                return {
                  ID: item.ID ? item.ID : undefined,
                  产品名称: item.产品名称,
                  产品ID: item.产品ID,
                  // 计划数量: item.计划数量,
                  生产数量: item.生产数量,
                };
              }),
            };
            if (this.objData.addeditdetail == 1) {
              mstable(obj)
                .then((res) => {
                  // this.$router.go(-1);
                  Notify({ type: "success", message: "新增成功" });
                  this.isLoading = false;
                  this.$emit("saveclick");
                })
                .catch(() => {
                  this.isLoading = false;
                });
            } else {
              obj.ID = this.form.ID;
              mstableup(obj)
                .then((res) => {
                  // this.$router.go(-1);
                  Notify({ type: "success", message: "修改成功" });
                  this.isLoading = false;
                  this.$emit("saveclick");
                })
                .catch(() => {
                  this.isLoading = false;
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (
        this.$route.path ==
        "/app/system/Digitalpackaging/PackagingDailyPersonnel"
      ) {
        this.isLoading = true;
        var list = this.form.子表.map((item) => {
          return {
            主表ID: this.form.ID,
            工序名称: item.工序名称,
            岗位人员: item.岗位人员,
            参与产量: item.参与产量,
          };
        });
        continuous({
          菜单: this.objData.menuID,
          表名: "包装日报人员",
          data: list.length == 0 ? "NULL" : list,
          主表ID: this.form.ID,
        })
          .then((res) => {
            Notify({ type: "success", message: "新增成功" });
            this.isLoading = false;
            this.$emit("saveclick");
          })
          .catch(() => {
            this.isLoading = false;
          });
      } else if (
        this.$route.path ==
        "/app/system/Digitalpackaging/PackagingDailyConsumables"
      ) {
        var obj = {
          菜单: this.$route.meta.ID,
          主表ID: this.form.主表ID,
          火碱: this.form.火碱,
          清洗剂: this.form.清洗剂,
          胶带: this.form.胶带,
          盖: this.form.盖,
          商标胶: this.form.商标胶,
          热熔胶: this.form.热熔胶,
          电表数: this.form.电表数,
          蒸汽数: this.form.蒸汽数,
          CO2: this.form.CO2,
          自来水: this.form.自来水,
          酿造水: this.form.酿造水,
          酒液消耗: this.form.酒液消耗,
          备注: this.form.备注,
          ID: this.form.ID,
        };
        PackagingDailyConsumables_up(obj)
          .then((res) => {
            // this.$router.go(-1);
            Notify({ type: "success", message: "修改成功" });
            this.isLoading = false;
            this.$emit("saveclick");
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },

    // 日期
    onclickdatetime(text, showText, type) {
      if (this.objData && this.objData.addeditdetail == 3) return;
      console.log(this.sunForm);
      this.formValueText = text;
      this.formvalueTextValue = text + "code";
      if (type == "hh:ss") {
        this.sunForm[this.formvalueTextValue] =
          this.form[text] == "" ? "" : this.form[text];
      } else {
        // this.form[text] = this.form[text] == '' ?
        //     type == '' ? this.formatDate(new Date(), 'yyyy-mm-dd') : this.formatDate(new Date())
        //     : this.form[text]
        this.sunForm[this.formvalueTextValue] =
          this.form[text] == ""
            ? new Date()
            : // type && type == "yyyy-mm-dd"
              // ? new Date(this.formatDate(new Date(), "yyyy-mm-dd"))
              // : new Date(this.formatDate(new Date()))
              new Date(this.form[text]);
      }

      console.log(new Date());
      this.$nextTick(() => {
        this[showText] = true;
      });
    },
    onConfirmTime(value, showText, type) {
      if (type && type == "hh:ss") {
        this.form[this.formValueText] = value ? value : "";

        this.sunForm[this.formvalueTextValue] = value ? value : "";
      } else {
        this.form[this.formValueText] =
          type && type == "yyyy-mm-dd"
            ? this.formatDate(value, "yyyy-mm-dd")
            : this.formatDate(value);
        this.sunForm[this.formvalueTextValue] = value;
      }

      console.log(this.form, this.sunForm);
      this[showText] = false;
      if (this.formValueText == "生产日期") {
        this.getSubList();
      }
    },
    onCancelDate(showText) {
      this[showText] = false;
    },
    // 格式化时间
    formatDate(date, type) {
      console.log(this.format(date.getSeconds()));
      date = new Date(date);
      var str = `${date.getFullYear()}-${this.format(
        date.getMonth() + 1
      )}-${this.format(date.getDate())}`;

      return type && type == "yyyy-mm-dd"
        ? str
        : `${str} ${this.format(date.getHours())}:${this.format(
            date.getMinutes()
          )}:00`;

      // ${this.format(date.getSeconds())}
    },

    format(n) {
      return n < 10 ? "0" + n : "" + n;
    },
  },
};
</script>

<style  lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #f4f8fb;
  .title {
    width: 100%;
    flex-shrink: 0;
    font-size: 0.75rem;
  }
  .content {
    width: 100%;
    flex-grow: 1;
    overflow-y: hidden;
  }

  .scrolldiv {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}
:deep() {
  .van-collapse-item__content {
    background-color: transparent !important;
    padding: 0 1rem 0.75rem !important;
  }
}
</style>